import axios from "../axios-interceptor";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { base_url, random_number,api_error_message, user_maintenance_type } from "../utilities";
import {toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
const ForgotPasswordReset = (props) => {
  var [errorFlag, setErrorFlag] = useState(false);
  const [passwordType, setPasswordType] = useState("text");
  const [passwordInput, setPasswordInput] = useState("");
  let [nextNavigation,setNextNavigation]=useState('AddressApp')
  let [invalidURL,setInvalidURL]=useState(false)
  let [user_email,setUser_email]=useState('')
  let [userInfo,setUserInfo]=useState(null)
  let [isSubmitted,setIsSubmitted]=useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all"});
  const history = useHistory();
  const onSubmit = (data) => {
    setIsSubmitted(true)
    axios.post(base_url+'isb/user/reset_password_email',{email:data.Email})
    .then(res=>{
        if(res.data.message=='Reset Password Email sent successfully')
        {
          toast.success(<>Reset Password Email sent!<br/>Please check your inbox.</>,{toastId:random_number});

        }
        else{
            setIsSubmitted(false)
            toast.warning(res.data.message,{toastId:random_number});
        }
    })
    .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
        
    })
  };

  return (
    <>
      <div>
        {
          <div style={{ width: "99%" }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-4">
                <div className="isblogin-wrap p-4 p-md-5">
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <span className="loginPageHeading">Forgot Your Password?</span>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="new-password"
                    className="login-form mt-2"
                  >
                    <div className="form-group mt-3 ">
                      <label htmlFor="Email">Email </label>
                      <input
                        {...register("Email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        type="text"
                        className="form-control rounded-left border-radius-none"
                        placeholder="Email"
                        id="Email"
                        data-testid="Email"
                        name="Email"
                        autoFocus
                        role="presentation"
                        autoComplete="off"
                      />
                      {errors.Email?.message && (
                        <span role="alert" style={{ color: "red" }}>
                          {errors.Email?.message}
                        </span>
                      )}
                    </div>
                         <div className="mt-1">
                         <button data-testid="submit-button" className="btn my_btn_cls my_btn_login w-100" type="submit" disabled={isSubmitted}>
                           Send
                         </button>
                         <button  className="btn my_outline_button_cls submit_button fw-bold mt-3 rounded-1 download_certificate_myprogms w-100" onClick={() => {localStorage.clear(); history.push("/");}} >Back to Login</button>
                       </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default withMaintenanceCheck(ForgotPasswordReset,user_maintenance_type) 
