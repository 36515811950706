import axios from '../../axios-interceptor';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import {random_number, base_url,admin_role, program_management_role,crypto_secret_key,api_error_message,enrollment_management_role, admin_maintenance_type} from '../../utilities';
import './AdminLogin.css';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';

function AdminLogin(props) {
    const { register, handleSubmit,setValue,getValues, reset,formState: { errors } } = useForm({"mode":"all"});
    const colCls = "col-4 column_4_cls";
    var [errorFlag, setErrorFlag] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [isSignup,setisSignup]=useState(false)
    const history=useHistory()
    const onSubmitLogin = data =>{

      let obj={
        Email:data.Email.trim(),
        Password:data.Password.trim()
      }
      let hashedpassword = CryptoJS.AES.encrypt(
        obj.Password,
        crypto_secret_key
      ).toString();

      axios.post(base_url + "admin/login", {
        "email":obj.Email,
        "password":hashedpassword
      })
      .then((res)=>{
        const {access_token,message,refresh_token,role}=res?.data
        if(access_token&&refresh_token)
        {
          localStorage.setItem('Admin_Acesstoken',access_token)
          localStorage.setItem('Admin_refreshtoken',refresh_token)
          localStorage.setItem('role',res.data.role)
          adminSessionCounter()
          localStorage.setItem('isLoggedIn','true')
          window.location.reload()
          // props.sessionProp.onLogin()
      let bytes = CryptoJS.AES.decrypt(res.data.role, crypto_secret_key);
      let decrypted_role = bytes.toString(CryptoJS.enc.Utf8);
      if(decrypted_role===admin_role){
        localStorage.setItem('Admin_role',admin_role)
       history.push('/AdminDashboard')
      }
      //below is program management role navigation
      else if(decrypted_role===program_management_role){
        localStorage.setItem('Admin_role',program_management_role)
        history.push('/programmanagementdashboard')
      }else if(decrypted_role == enrollment_management_role){
        localStorage.setItem('Admin_role',enrollment_management_role)
        history.push('/enrollmentmanagementdashboard')
      }
      else{
        history.push('/AdminDashboard')
      }
        }
        else{
          setErrorFlag(true);
        }
      })
      .catch((err)=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
    }
    const togglePassword = () => {
      if (passwordType === "password") {
        setPasswordType("text");
        return;
      }
      setPasswordType("password");
    };

      useEffect(() => {
      if((localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn') == 'true') ||localStorage.getItem('isb_user'))
      {
        history.push('/maindashboard')
      }else if(localStorage.getItem('Admin_role') == admin_role){
        history.push('/AdminDashboard')
      }else if(localStorage.getItem('Admin_role') == program_management_role){
        history.push('/programmanagementdashboard')
      }else if(localStorage.getItem('Admin_role') == enrollment_management_role){
        history.push('/enrollmentmanagementdashboard')
      }
      }, [])
      const adminSessionCounter=()=>{
        setInterval(() => {
          axios.post(base_url+'admin/refreshtoken',{ "refresh_token":localStorage.getItem('Admin_refreshtoken')})
          .then(res=>{

           localStorage.setItem("Admin_Acesstoken",res.data?.access_token)
          })
          .catch(err=>{
            console.log(err);
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          })
          
        }, 50*60*1000);
      }

    return ( <>
           {isSignup==false&& 
            <div style={{ width: "99%" }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-4">
                <div className="isblogin-wrap adminlogin_card">
                  <div className="my_form_heading mb-4">
                    <span className="heading-section">Admin Login</span>
                  </div>
                  <div className="login-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-user-o"></span>
                    </div>
                    </div>
                  {errorFlag && (
                    <div>
                      <span className="text-danger ">
                        Invalid Email or Password
                      </span>
                    </div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmitLogin)}
                    autoComplete="new-password"
                    data-testid="submit-button-admin"
                    className="login-form mt-2"
                  >
                    <div className="form-group mt-3 ">
                      <label className='Admin_label' htmlFor="Email">Email </label>
                      <input
                        {...register("Email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        type="text"
                        className="form-control rounded-left border-radius-none"
                        placeholder="Email"
                        id="Email"
                        data-testid="Email_admin_login"
                        name="Email"
                        autoFocus
                        role="presentation"
                        autoComplete="off"
                      />
                      {errors.Email?.message && (
                        <span style={{ color: "red" }}>
                          {errors.Email?.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <label className='Admin_label' htmlFor="Password">Password </label>
                      <input
                        {...register("Password", {
                          required: "Password is required",
                        })}
                        type={passwordType}
                        // onChange={handlePasswordChange}
                        // value={passwordInput}
                        id="Password"
                        name="Password"
                        data-testid="password_admin_login"
                        className="form-control rounded-left border-radius-none"
                        placeholder="Password"
                      />
                            {passwordType === "password" ? < span data-testid="toggle_password" style={errors.Password?.message ? { top:"34px"} : {}} className="password-icon" role="button" onClick={togglePassword}><i className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i> </span> : <span style={errors.Password?.message ? { top:"34px"} : {}}className="password-icon" role="button" onClick={togglePassword}><i className="fa-regular fa-eye mr-2 mt-2 ml-1" /></span>}
                      {errors.Password?.message && (
                        <span style={{ color: "red" }}>
                          {errors.Password?.message}
                        </span>
                      )}
                    </div>
                    <div className="my_form_heading mt-1" data-testid="login_button">
                      <button className="btn my_btn_cls my_submit_btn boder-radius-0 admin_login_submit">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
            
            
            
            }

            {isSignup==true&&<div style={{width: '99%'}}>
            {/* <div className="row justify-content-center">
                <div className="col-md-6 text-center mb-2">
                <h2 className="heading-section">Admin Signup</h2>
                </div>
            </div> */}
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5">
                <div className="login-wrap p-4 p-md-5">
                    <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-user-o"></span>
                    </div>
                    <h3 className="text-center mb-4">Admin Signup</h3>

                    <form 
                    // onSubmit={handleSubmit(onSubmitSignup)} 
                    autoComplete='false' className="login-form">

                    <div className="form-group">
                        <input {...register("Firstname",{
                          required: "First name is required"
                        })}
                        type="text"
                        className="form-control rounded-left"
                        placeholder="Firstname"
                        data-testid="admin_signup_firstname"
                        id='Firstname'
                        autoFocus
                        name='Firstname'
                        role="presentation" autoComplete="off"
                        />
                    {errors.Firstname?.message && <span style={{ color: "red" }}>{errors.Firstname?.message}</span>}
                    </div>
                    <div className="form-group">
                        <input {...register("Lastname",{
                          required: "Last name is required"
                        })}
                        type="text"
                        className="form-control rounded-left"
                        placeholder="Lastname"
                        id='Lastname'
                        data-testid="admin_signup_lastname"
                        name='Lastname'
                        role="presentation" autoComplete="off"
                        />
                    {errors.Lastname?.message && <span style={{ color: "red" }}>{errors.Lastname?.message}</span>}
                    </div>

                    <div className="form-group">
                        <input {...register("Email",{
                          required: "Email is required", pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                          },
                        })}
                        type="text"
                        className="form-control rounded-left"
                        placeholder="Email"
                        data-testid="admin_signup_email"
                        id='Email'
                        name='Email'
                        role="presentation" autoComplete="off"
                        />
                    {errors.Email?.message && <span style={{ color: "red" }}>{errors.Email?.message}</span>}
                    </div>
                    <div className="form-group">
                        <input {...register("Password",{required: "Password is required"})}
                        type="text"
                        id='Password'
                        data-testid="admin_signup_password"
                        name='Password'
                        className="form-control rounded-left"
                        placeholder="Password"
                        role="presentation" autoComplete="off"
                        />
                    {errors.Password?.message && <span style={{ color: "red" }}>{errors.Password?.message}</span>}
                    </div>
                    <div className="form-group">
                        <input {...register("ConfirmPassword",{
                           validate: (value) => {
                            const { Password } = getValues();
                            return Password === value || "Passwords should match!";
                          },
                        required: "Confirm Password is required",
                        }
                        )}
                        type="text"
                        id='ConfirmPassword'
                        data-testid="admin_signup_confirmpassword"
                        name='ConfirmPassword'
                        className="form-control rounded-left"
                        placeholder="Confirm Password"
                        role="presentation" autoComplete="off"
                        />
                    {errors.ConfirmPassword?.message && <span style={{ color: "red" }}>{errors.ConfirmPassword?.message}</span>}
                    </div>
                    <div className="form-group d-md-flex">
                        <div className="w-50" style={{visibility:'hidden'}}>
                        <label className="checkbox-wrap checkbox-primary" data-testid="admin_signup_checkbox">
                            Remember Me
                            <input type="checkbox" checked="" />
                            <span className="checkmark"></span>
                        </label>
                        </div>
                        <div className="w-50 text-md-right" >
                        <span onClick={()=>setisSignup(false)} style={{fontSize:"20px",cursor:'pointer',color:"#0d6efd"}}>Login</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <button
                        type="submit"
                        className="btn btn-primary rounded submit p-3 px-5" data-testid="admin_signup_submit"
                        >
                       Signup
                        </button>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>}
    </> );
}

export default withMaintenanceCheck(AdminLogin,admin_maintenance_type);