import React from "react";
import { VictoryChart, VictoryAxis, VictoryLabel, VictoryScatter, VictoryTooltip } from "victory";

export default function NewGraph({ learnerPercentile }) {
  const markerX = learnerPercentile; // Use learner's percentile for marker positioning on the X-axis

  return (
    <div className="parent-graph">
      <div className="graph-container">
        <VictoryChart
          width={600}
          height={300} // Smaller height since we only need the X-axis
          padding={{ top: 35, bottom: 40, left: 40, right: 50 }} // Adjust padding for better spacing
          domain={{ x: [0, 100], y: [0, 1] }} // Y-axis domain set to 1 for simplicity
        >

<VictoryLabel
            // text="Course Average"
            x={250} // Position at the center of the chart width
            y={30} // Place it at the top of the chart
           
            textAnchor="middle"
            style={{ fontSize: 18, fontWeight: "bold",fill: "#D3A01F" }} // Style for the text
          />
           <VictoryLabel
          // text="Number of People"
          x={-20} // Position it on the Y-axis
          y={150} // Align vertically in the middle
          angle={-90} // Rotate to be vertical
          textAnchor="middle"
          style={{ fontSize: 18,fill:"#6B6B6B" }}
        />
          {/* X-axis with static percentage labels */}
          <VictoryAxis
            style={{
              axis: { stroke: "green",strokeWidth: 2 },
              ticks: {  size: 5 },
              tickLabels: { fontSize: 12, padding: 5 }, // Adjust font size for axis labels
            }}
            
            tickValues={[0, 10, 30, 70, 90, 100]} // Static X-axis tick positions
            tickFormat={(t) => `${t}%`} // Format X-axis as percentages
          />

          {/* Label for X-axis */}
          <VictoryLabel
            x={300} // Center of the chart
            y={80} // Slightly below the X-axis
            textAnchor="middle"
            style={{ fontSize: 14, fill: "#6B6B6B" }}
          />

          {/* Marker for the learner's percentile */}
          <VictoryScatter
            data={[{ x: markerX, y: 0.0 }]} // Place the marker along the X-axis
            size={8} // Set the size for the outer circle
            style={{
              data: {
                fill: "white", // White inner circle
                stroke: "#FFCC00", // Yellow outer ring
                strokeWidth: 4 // Thickness of the yellow ring
              }
            }}
            labels={["You are here"]} // Tooltip text to show "You are here"
            labelComponent={<VictoryTooltip 
              flyoutStyle={{ fill: "#057092" }} // Tooltip background color
              style={{ fontSize: 15, fill: "white" }} // Font styling for the tooltip
            />}
          />
        </VictoryChart>
      </div>
    </div>
  );
}
