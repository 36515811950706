import axios from "../axios-interceptor";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  base_url,
  random_number,
  api_error_message,
  user_maintenance_type,
  crypto_secret_key,
  payment_paid,
} from "../utilities";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
import { useParams } from "react-router-dom";
const SetPassword = (props) => {
  var [errorFlag, setErrorFlag] = useState(false);
  const [passwordType, setPasswordType] = useState("Password");
  const [confirmPasswordType, setConfirmPasswordInput] = useState("Password");
  let [invalidURL, setInvalidURL] = useState(false);
  let [user_email, setUser_email] = useState("");
  let [error_msg, setErrorMsg] = useState("");
  let [loading_flag, setLoading_flag] = useState(false);
  const { user_id, refresh_token } = useParams();
  localStorage.setItem("user_id", user_id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const history = useHistory();

  useEffect(() => {
    if(refresh_token &&!refresh_token?.endsWith('_ref')){
      axios
      .post(base_url + "isb/fetchUserDetails", { user_id: user_id })
      .then((res) => {
        if (res.data.existing_user == 1) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
    }
  }, []);

  useEffect(() => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
    const {
      leadform_name,
      leadform_email,
      leadform_mobile,
      leadform_ProgramId,
      leadform_role,
      leadform_country_code,
      leadform_id,
      leadform_years_of_experience,
      leadform_location,
    } = cookies;
    if(!leadform_email){
      axios
      .post(base_url + "isb/user/get_user_details", {
      user_id: user_id
      })
      .then((res) => {
      let result = res.data.result[0];
        setUser_email(result.email); 
      }).catch((err) => {
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
    }else{
      setUser_email(leadform_email);
    }
    
  }, []);

  const onSubmit = (data) => {
    let hashedpassword = CryptoJS.AES.encrypt(
      data?.Password?.trim(),
      crypto_secret_key
    ).toString();

    let obj = {
      user_id: user_id,
      email: user_email?.trim(),
      password: hashedpassword,
    };
    if (data?.Password?.trim() == data?.ConfirmPassword?.trim()) {
      setErrorMsg("");
      axios
        .post(base_url + "isb/user/reset_password", obj)
        .then((res) => {
          localStorage.setItem("user_id", res?.data?.result[0]?._id);
          localStorage.setItem("login", "success");
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("User_Accesstoken", res?.data?.access_token);
          localStorage.setItem(
            "User_Refreshtoken",
            res?.data?.auth_refresh_token
          );
          localStorage.setItem(
            "is_verizon_student",
            res.data.is_verizon_student ?? 0
          );

          toast.success(res?.data?.message, { toastId: random_number });
          if(localStorage.getItem('free_lesson_url')){
            let url = localStorage.getItem('prev_url')
            history.push(url.split(window.location.host)[1])
            localStorage.setItem('User_Email',user_email)  
            window.location.reload();
          }else{
            localStorage.removeItem('free_lesson_url')
            localStorage.setItem('User_Email',user_email)
            history.push('/maindashboard')
            window.location.reload();
          } 
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response?.statusText == "Unauthorized" ||
            err.response?.data?.statusText == "Unauthorized"
          ) {
            localStorage.clear();
            history.push("/");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    } else {
      setErrorMsg("Passwords do not match. Please re-enter your passwords");
    }
  };

  const togglePassword = () => {
    if (passwordType === "Password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("Password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "Password") {
      setConfirmPasswordInput("text");
      return;
    }
    setConfirmPasswordInput("Password");
  };

  const navToIsblogin = () => {
    localStorage.clear();
    history.push("/");
    window.location.reload();
  };
  const validatePassword = (value) => {
    if (!/[a-z]/.test(value)) {
      return "Password must contain at least one lowercase letter";
    } else if (!/[A-Z]/.test(value)) {
      return "Password must contain at least one uppercase letter";
    } else if (!/[0-9]/.test(value)) {
      return "Password must contain at least one number";
    } else if (value.length < 8) {
      return "Password must be at least 8 characters long";
    }
    return true;
  };

  return (
    <>
      {loading_flag ? (
        <div>
          <img
            className="atrium_loader"
            src="/images/atrium_loader.gif"
            alt="Loader"
          />
        </div>
      ) : (
        <>
          {invalidURL ? (
            <>
              <div class="invalid_container">
                <span class="invalid_requiredInput fs-5 red">
                  The link is expired
                </span>
                <button
                  class="btn my_btn_cls my_btn_login"
                  onClick={navToIsblogin}
                >
                  Click here to login
                </button>
              </div>
            </>
          ) : (
            <>
              <div>
                {
                  <div style={{ width: "99%" }}>
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 col-lg-4">
                        <div className="isblogin-wrap p-4 p-md-5">
                          <div className="d-flex align-items-center justify-content-center mb-2">
                            <span className="loginPageHeading">
                              Create Password
                            </span>
                          </div>
                          {errorFlag && (
                            <div>
                              <span className="text-danger ">
                                Invalid Email or Password
                              </span>
                            </div>
                          )}
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="new-password"
                            className="login-form mt-2"
                          >
                            <div className="form-group mt-3 ">
                              <label htmlFor="Email">
                                Set Password for this Email
                              </label>
                              <br />
                              <span className="setPwd-email">{user_email}</span>
                              <br />
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="Password">
                                New Password{" "}
                                <span className="requiredInput">*</span>
                              </label>
                              <input
                                {...register("Password", {
                                  required: "New Password is required",
                                  validate: validatePassword,
                                })}
                                type={passwordType}
                                // onChange={handlePasswordChange}
                                // value={passwordInput}
                                id="Password"
                                name="Password"
                                data-testid="newPassword"
                                className="form-control rounded-left border-radius-none"
                                placeholder="New Password"
                                required
                              />
                              {passwordType === "Password" ? (
                                <span
                                  data-testid="eye_show_password"
                                  className={`password-icon  ${
                                    errors.Password?.message ? "eyeIconTop" : ""
                                  }`}
                                  role="button"
                                  onClick={togglePassword}
                                >
                                  <i className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i>{" "}
                                </span>
                              ) : (
                                <span
                                  className={`password-icon  ${
                                    errors.Password?.message ? "eyeIconTop" : ""
                                  }`}
                                  role="button"
                                  onClick={togglePassword}
                                >
                                  <i className="fa-regular fa-eye mr-2 mt-2 ml-1"></i>{" "}
                                </span>
                              )}
                              {errors.Password?.message && (
                                <span role="alert" style={{ color: "red" }}>
                                  {errors.Password?.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="ConfirmPassword">
                                Confirm Password{" "}
                                <span className="requiredInput">*</span>
                              </label>
                              <input
                                {...register("ConfirmPassword")}
                                type={confirmPasswordType}
                                // onChange={handlePasswordChange}
                                // value={passwordInput}
                                id="ConfirmPassword"
                                name="ConfirmPassword"
                                data-testid="confirmPassword"
                                className="form-control rounded-left border-radius-none"
                                placeholder="Confirm Password"
                              />
                              {confirmPasswordType === "Password" ? (
                                <span
                                  data-testid="toggle_confirm_eye"
                                  className={`password-icon  ${
                                    error_msg ? "eyeIconTop" : ""
                                  }`}
                                  role="button"
                                  onClick={toggleConfirmPassword}
                                >
                                  <i className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i>{" "}
                                </span>
                              ) : (
                                <span
                                  className={`password-icon  ${
                                    error_msg ? "eyeIconTop" : ""
                                  }`}
                                  role="button"
                                  onClick={toggleConfirmPassword}
                                >
                                  <i className="fa-regular fa-eye mr-2 mt-2 ml-1" />
                                </span>
                              )}
                              {error_msg && (
                                <span role="alert" style={{ color: "red" }}>
                                  *{error_msg}
                                </span>
                              )}
                            </div>
                            <div className="justify-content-center d-flex mt-1">
                              <button
                                className="btn my_btn_cls my_btn_login"
                                type="submit"
                                data-testid="continueBtn"
                              >
                                Continue
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {/* test cases */}
                <span
                  className="d-none"
                  data-testid="nav_isb_login"
                  onClick={navToIsblogin}
                ></span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withMaintenanceCheck(SetPassword, user_maintenance_type);
