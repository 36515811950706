import { React, Fragment } from 'react';
import useCollapse from 'react-collapsed';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import '../../homePageStyle.css'
import axios from "../../axios-interceptor"
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Collapse } from 'react-bootstrap';
import moment from 'moment';
import { badgeMessage, courseOutlineBadge, course_quiz, date_formats, quiz_name, social_learning_activity, summary_name, review_text, redo_text, quiz_max_attempts, unlimited_text, total_bonus_points } from "../../utilities";
import { Modal } from "react-bootstrap";
const C = (<i className="fa-solid fa-chevron-up text-white"></i>)
const E = (<i className="fa-solid fa-chevron-down text-white"></i>)


export function ModuleZero(props) {
  const moduleCompletion = props.data.module_completed_percentage
  let index = props.modules.findIndex(el => el.module_completed_percentage !== "100.00" && (date_formats.convert_current_date_utc_format() >= el.start_date))
  if (index == -1) {
    index = 0
  }
  const config = {
    defaultExpanded: (props.index === index) ? true : false
  };
  const topNavBar = document.getElementById("top-nav-bar");
  if (topNavBar) {
    topNavBar.style.display = "flex";
  }
  const history = useHistory()
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  var [flag, setFlag] = useState(true)
  const [lessons, setLessons] = useState([]);
  let [start_date, setStartDate] = useState(0)
  let [start_date_hours, setStartDateHours] = useState(0)
  const [hoveredDiv, setHoveredDiv] = useState();
  let [showModal, setShowModal] = useState(false);
  let [lesson_object, setLessonObject] = useState({})
  let [currentWindowWidth, setCurrentWindowWidth] = useState(0)
  let [max_attempts, setMaxAttempts] = useState('')
  let [user_attempts, setUserAttempts] = useState('')
  useEffect(() => {
    handleResize();
    localStorage.removeItem('re_attempt')
    if (props.index == index) {
      setFlag(false)
      if (!props.data.idref) {
        var list_lesson = [];
        props.data.lessons.map(i => {
          list_lesson.push(i);
        })
        setLessons(list_lesson[0]);
      }
    } else {
      if (date_formats.convert_current_date_utc_format() > props.data.start_date) {
        setStartDate(0)
        setFlag(false)
        setLesson(props.data)
      } else {
        setFlag(true)

        if (Math.abs(Math.floor((moment.duration(moment(new Date()).diff(moment(new Date(props.data.start_date))))).asHours())) > 24) {
          setStartDate(1)
          setStartDateHours(Math.abs(Math.floor((moment.duration(moment(new Date()).diff(moment(new Date(props.data.start_date))))).asDays())) + ' day(s)')
        } else {
          setStartDate(-1)
          setStartDateHours(Math.abs(Math.floor((moment.duration(moment(new Date()).diff(moment(new Date(props.data.start_date))))).asHours())) + ' hour(s)')
        }

      }
    }

  }, [])
  const hideModal = () => setShowModal(false);
  const setLesson = (item) => {
    if (!item.idref) {
      var list_lesson = [];
      item.lessons.map(i => {
        list_lesson.push(i);
      })
      setLessons(list_lesson[0]);
    }
  }
  const setPageContent = (item, data, index) => {
    let lessons_completed = false;
    let except_first_last_modules = false;
    let except_last_lesson = [...lessons]
    except_last_lesson.pop();
    let except_first_module = lessons.every(item1 => {
      return props.index == 0;
    });
    if (except_first_module == false && props.modules[props.modules.length - 1]['module_id'] != props.id && item['lesson_id'] == lessons[lessons.length - 1]['lesson_id']) {
      lessons_completed = except_last_lesson.every(item => {
        return item.is_lesson_completed == true
      })
    }
    localStorage.setItem('sla_id', item.sla_id);

    if (except_first_module == false && props.modules[props.modules.length - 1]['module_id'] != props.id) {
      except_first_last_modules = true;
    }

    if (item.title.toLowerCase().includes(quiz_name) && item.completed_percentage == "100.00") {
      setShowModal(true)
      setMaxAttempts(item.max_no_of_quiz_attempts)
      setUserAttempts(item.quiz_attempted_by_user)
      setLessonObject({ id: item, moduleId: data, courseId: props.course, lessons_completed: lessons_completed, index: index, cohort_id: props.cohort_id, lesson_id: item.lesson_id, module_end_date: props.new_module_end_date, except_first_last_modules: except_first_last_modules })
    }
    else {
      history.push('/lessonpage', { id: item, moduleId: data, courseId: props.course, lessons_completed: lessons_completed, index: index, cohort_id: props.cohort_id, lesson_id: item.lesson_id, module_end_date: props.new_module_end_date, except_first_last_modules: except_first_last_modules })
    }

  }
  const reviewLesson = () => {
    hideModal()
    enterFullScreen()
    lesson_object.review_selected = true;
    history.push('/lessonpage', lesson_object)
  }
  const handleClose = () => setShowModal(false);
  const redoLesson = () => {

    hideModal()
    enterFullScreen()
    localStorage.setItem('re_attempt', true)
    history.push('/lessonpage', lesson_object)
  }
  const handle = useFullScreenHandle();
  const setIsbAppLink = () => {
    window.open('https://app.gather.town/app/IHxXgpa4RAmC8nsk/isb-test', '_blank');
  }
  const doNothing = () => {

  }

  const isiPad = () => {
    // Check if the device has touch capabilities
    const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    // Check for specific iPad behaviors or features
    const isiPadBehavior = (
      // Check for iPad-specific touch events
      (hasTouch && typeof Touch !== 'undefined' && typeof TouchEvent !== 'undefined' && typeof TouchList !== 'undefined') ||
      // Check for iPad-specific gesture events
      (hasTouch && typeof window.orientation !== 'undefined')
      // Add more iPad-specific checks if needed
      // ...
    );

    // Check if the screen size indicates an iPad
    const isiPadScreenSize = (
      window.screen.width >= 768 && window.screen.height >= 1024
    );

    // Check if the device is running on the Windows operating system
    const isWindows = /Windows/i.test(navigator.userAgent);

    // Combine all the checks
    return isiPadBehavior && isiPadScreenSize && !isWindows;
  }
  const enterFullScreen = () => {
    if (!isiPad()) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    }
  }
  document.addEventListener('fullscreenchange', exitHandler, false);
  document.addEventListener('webkitfullscreenchange', exitHandler, false);
  document.addEventListener('mozfullscreenchange', exitHandler, false);
  document.addEventListener('MSFullscreenChange', exitHandler, false);

  function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      if (!localStorage.getItem('artcle_LinkClicked')) {
        //  history.push('/modulepage')
      }
    }
  }


  const handleResize = () => {
    setCurrentWindowWidth(window.innerWidth)
    // Perform any other actions based on the updated window width
  }
  // Add event listener for resize event
  window.addEventListener("resize", handleResize);

  return (
    <>
      <div className="progress_bar" data-testid="moduleList_collapse">

        <div className="Module0 p-2 ps-4 h3 custm_shadow_cls progress_img" disabled={flag}>

          {currentWindowWidth < 900 ?
            // mobile
            <>
              <div className='d-flex module_collapse gap-1 align-items-center module_collapse_mobile' {...getToggleProps()}>
                <div className='modulec_1d'>
                  {moduleCompletion >= 100 ? <img className='module_title_styl' src="/images/Atrium_100_New.svg" alt="" /> : moduleCompletion >= 25 && moduleCompletion <= 49 ? <img className='module_title_styl' src="./images/Atrium_25_New.svg" alt="" /> : moduleCompletion >= 50 && moduleCompletion <= 74 ? <img className='module_title_styl' src="./images/Atrium_50_New.svg" alt="" /> : moduleCompletion >= 75 && moduleCompletion <= 99 ? <img className='module_title_styl' src="./images/Atrium_75_New.svg" alt="" /> :
                    <img className='module_title_styl' src="/images/Atrium_Zero_New.svg" alt="" />}
                </div>
                <div className='modulec_2d px-2'>
                  <div className="header"  >
                    <div className='d-flex justify-content-between '>
                      <div className="text-wrap align-self-center" >
                        <h3 onClick={() => setLesson(props?.data)}>{props.data.title.length > 40 ? props.data.title.slice(0, 40).replace(/ ?- ?.*/, "") + '..' : props.data.title}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modulec_3d'>
                  <div className="module_diamond text-wrap d-flex gap-1 align-items-end justify-content-start">
                    <img src="/images/white_polygon.svg"></img>
                    {props.index !== props.modules.length - 1 && props.index !== 0 ?
                      (
                        <OverlayTrigger placement="top" overlay={
                          <Tooltip className="bonus_tooltip"
                            id={"registerTip2"}>
                            {props.data.module_badge === 1 ? (
                                    <span>Congratulations! You have earned the badge for this module.
                                      <br/>
                                      <br/>
                                    You have received {props.data.badge_bonus_points} bonus points for completing the module on time.</span>
                                  ) : (
                                    props.data.end_date ? (
                                      <span>Complete this module by {date_formats.human_date_time_format(props.data.end_date)} to receive the {props.data.badge_bonus_points} bonus points.</span>
                                    ) : (
                                      <span>You have not completed this module on time so you missed the {props.data.badge_bonus_points} bonus points</span>
                                    )
                                  )}
                          </Tooltip>} offset={[0, 20]}>
                          <p className='' style={{ color: "white" }}>{props.data.user_earned_module_score}/{props.data.module_total_score} <i>({props.data.module_score_percentage}%)</i>+
                          

                          {/* adding bonus points for responsive */}
                             <span className="">
                                <img
                                  data-testid="Module_polygones"
                                  src={props.data.module_badge === 1 ? "/images/polygon_filled_yellow.svg" : "/images/polygon_res.svg"}
                                  alt="Polygon Icon"
                                />
                                <span className=''>
                                  {props.data.module_badge === 1 ?
                                    `${props.data.badge_bonus_points}/${props.data.badge_bonus_points}` :
                                    `${props.data.module_badge}/${props.data.badge_bonus_points}`}
                                </span>
                              </span>

                          </p>
                        </OverlayTrigger>

                      ) :
                      (<p className='' style={{ color: "white" }}>
                        {props.data.user_earned_module_score}/{props.data.module_total_score}
                        <i>({props.data.module_score_percentage}%)</i>+

                        {/* adding bonus points for responsive */}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip className="bonus_tooltip" id={"bonusTip"}>
                              {props.data.module_badge === 1 ? (
                                    <span>Congratulations! You have earned the badge for this module.
                                      <br/>
                                      <br/>
                                    You have received {props.data.badge_bonus_points} bonus points for completing the module on time.</span>
                                  ) : (
                                    props.data.end_date ? (
                                      <span>Complete this module by {date_formats.human_date_time_format(props.data.end_date)} to receive the {props.data.badge_bonus_points} bonus points.</span>
                                    ) : (
                                      <span>You have not completed this module on time so you missed the {props.data.badge_bonus_points} bonus points</span>
                                    )
                                  )}
                            </Tooltip>
                          } offset={[0, 20]}
                        >
                          <span className="">
                            <img
                              data-testid="Module_polygones"
                              src={props.data.module_badge === 1 ? "/images/polygon_filled_yellow.svg" : "/images/polygon_res.svg"}
                              alt="Polygon Icon"
                            />
                            <span className=''>
                              {props.data.module_badge === 1 ?
                                `${props.data.badge_bonus_points}/${props.data.badge_bonus_points}` :
                                `${props.data.module_badge}/${props.data.badge_bonus_points}`}
                            </span>
                          </span>
                        </OverlayTrigger>

                      </p>)
                    }
                  </div>
                </div>
                <div className='modulec_4d'>
                  {isExpanded ? <i onClick={() => setLesson(props?.data)} className="fa-solid fa-chevron-up text-white fa-solid-mod-arw"></i> : <i onClick={() => setLesson(props?.data)} className="fa-solid fa-chevron-down text-white fa-solid-mod-arw"></i>}
                </div>
              </div>
            </> :
            //web
            <>
              <div className='d-flex module_collapse align-items-center' {...getToggleProps()}>
                <div className='firstm' data-testid={`Module_Atriums-${props.index}`}>
                  {moduleCompletion >= 100 ? <img className='module_title_styl' src="/images/Atrium_100_New.svg" alt="" /> : moduleCompletion >= 25 && moduleCompletion <= 49 ? <img className='module_title_styl' src="./images/Atrium_25_New.svg" alt="" /> : moduleCompletion >= 50 && moduleCompletion <= 74 ? <img className='module_title_styl' src="./images/Atrium_50_New.svg" alt="" /> : moduleCompletion >= 75 && moduleCompletion <= 99 ? <img className='module_title_styl' src="./images/Atrium_75_New.svg" alt="" /> :
                    <img className='module_title_styl' src="/images/Atrium_Zero_New.svg" alt="" />}
                </div>
                <div className='secondm'>
                  <div className="header"  >
                    <div className='d-flex justify-content-between '>
                      <div className="col-lg-8 text-wrap align-self-center" >
                        <h3 data-testid="module_Title" onClick={() => setLesson(props?.data)}>{props.data.title}</h3>
                      </div>
                      <div className="col-lg-3 d-flex gap-1 ml-5 module_diamond text-wrap align-self-center pl-4" data-testid={`module_Scores-${props.index}`}>
                        <img data-testid="Module_polygones" src="/images/white_polygon.svg"></img>
                        {props.index !== props.modules.length - 1 && props.index !== 0 ? (
                            <p className="m-3" style={{ color: "white" }} data-testid="module_ScoreTooltip_web">
                              {props.data.user_earned_module_score}/{props.data.module_total_score}{" "}
                              <i>({props.data.module_score_percentage}%)</i>{" "} + {" "}
                              {/* adding bonus points */}
                              
                              <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="bonus_tooltip" id={"registerTip2"}>
                                  {props.data.module_badge === 1 ? (
                                    <span>Congratulations! You have earned the badge for this module.
                                      <br/>
                                      <br/>
                                    You have received {props.data.badge_bonus_points} bonus points for completing the module on time.</span>
                                  ) : (
                                    props.data.end_date ? (
                                      <span>Complete this module by {date_formats.human_date_time_format(props.data.end_date)} to receive the {props.data.badge_bonus_points} bonus points.</span>
                                    ) : (
                                      <span>You have not completed this module on time so you missed the {props.data.badge_bonus_points} bonus points</span>
                                    )
                                  )}
                                </Tooltip>
                              }
                              offset={[2,8]}
                              
                            >
                              <span className="">
                                <img
                                  data-testid="Module_polygones"
                                  src={props.data.module_badge === 1 ? "/images/polygon_filled_yellow.svg" : "/images/polygon_new.svg"}
                                  alt="Polygon Icon"
                                  style={{ marginRight: '10px' ,marginLeft: '8px'}}
                                />
                                <span className=''>
                                  {props.data.module_badge === 1 ?
                                    `${props.data.badge_bonus_points}/${props.data.badge_bonus_points}` :
                                    `${props.data.module_badge}/${props.data.badge_bonus_points}`}
                                </span>
                              </span>
                            </OverlayTrigger>
                            </p>
                                                
                        ) : (
                          <p className="m-3" style={{ color: "white" }} data-testid="module_ScoreNoTooltip_web">
                            {props.data.user_earned_module_score}/{props.data.module_total_score}{" "}
                            <i>({props.data.module_score_percentage}%)</i>{" "}+ {" "}
                            {/* adding bonus points else condition */}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="bonus_tooltip" id={"registerTip2"}>
                                  {props.data.module_badge === 1 ? (
                                    <span>Congratulations! You have earned the badge for this module.
                                      <br/>
                                      <br/>
                                    You have received {props.data.badge_bonus_points} bonus points for completing the module on time.</span>
                                  ) : (
                                    props.data.end_date ? (
                                      <span>Complete this module by {date_formats.human_date_time_format(props.data.end_date)} to receive the {props.data.badge_bonus_points} bonus points.</span>
                                    ) : (
                                      <span>You have not completed this module on time so you missed the {props.data.badge_bonus_points} bonus points</span>
                                    )
                                  )}
                                </Tooltip>
                              }
                              offset={[0,8]}
                            >
                              <span className="">
                                <img
                                  data-testid="Module_polygones"
                                  src={props.data.module_badge === 1 ? "/images/polygon_filled_yellow.svg" : "/images/polygon_new.svg"}
                                  alt="Polygon Icon"
                                  style={{ marginRight: '10px' ,marginLeft: '8px'}}
                                />
                                <span className=''>
                                  {props.data.module_badge === 1 ?
                                    `${props.data.badge_bonus_points}/${props.data.badge_bonus_points}` :
                                    `${props.data.module_badge}/${props.data.badge_bonus_points}`}
                                </span>
                              </span>
                            </OverlayTrigger>
                             
                          </p>
                        )}
                      </div>
                      <div className="col  lesson_status text-center" >
                        <p className='d-none'>hellooo</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='thirdm'>
                  {isExpanded ? <i onClick={() => setLesson(props?.data)} className="fa-solid fa-chevron-up text-white fa-solid-mod-arw"></i> : <i onClick={() => setLesson(props?.data)} className="fa-solid fa-chevron-down text-white fa-solid-mod-arw"></i>}
                </div>
              </div>
            </>}


        </div>
        <div {...getCollapseProps()} data-testid={`module_lesson-${props.index}`}>
          <div className="content">
            <ul className='module_expandlist'>
              {lessons.map((item, index) => {
                return (
                  <Fragment key={item.lesson_id}>
                    <div id={`collapse_${index}`}
                      data-testid="Single_lessonWeb"
                      onMouseEnter={() => setHoveredDiv(index)} onMouseLeave={() => setHoveredDiv(null)}
                      className=
                      {`d-flex justify-content-between flex-column ${item.completed_percentage >= 100 && !item.title.toLowerCase().includes(summary_name) && !item.title.toLowerCase().includes(quiz_name) && !item.title.toLowerCase().includes(course_quiz) && !item.title.toLowerCase().includes(social_learning_activity) ? item.completed_percentage == 100 ? 'module_nav_completed_lesson_tick' : 'module_nav_blue_book' : (item.title.toLowerCase().includes(quiz_name) || item.title.toLowerCase().includes(course_quiz)) ? item.completed_percentage == 100 ? 'module_nav_completed_lesson_tick' : 'module_nav_main_pen' : item.title.toLowerCase().includes(social_learning_activity) ?
                        item.completed_percentage == 100 ? 'module_nav_completed_lesson_tick' : 'sla_icon' : item.title.toLowerCase().includes(summary_name) ? item.completed_percentage == 100 ? 'module_nav_completed_lesson_tick' : 'summary_icon' : item.completed_percentage == 100 ? 'module_nav_completed_lesson_tick' : 'module_nav_blue_book'} position-relative`} onClick={() => setPageContent(item, props.id, (index + 1))}>
                      <div className="moduleExpand_web module_navs custm_shadow_cls h_66" style={{ cursor: "pointer" }} onClick={item.title.toLowerCase().includes(quiz_name) && item.completed_percentage == "100.00" ? doNothing : enterFullScreen}>
                        <h5 className='d-flex justify-content-between row m-auto m-0'>
                          <div data-testid="lesson_nameWeb" className="col-lg-8 col-md-5 col-sm-2 text-black text-wrap me-3 align-self-center" >
                            {index + 1}. {item.title.toLowerCase().includes(social_learning_activity) ? item.title.length > 55 ? item.title.slice(0, 50).replace(/ ?- ?.*/, "") + '..' : item?.title?.replace(/ ?- ?.*/, "") : item.title.length > 55 ? item.title.slice(0, 50) + '..' : item?.title}
                          </div>
                          <div className="col-lg-1 col-md-4 d-flex gap-1" data-testid={`lesson_polygon-${index}`}>

                            {item.completed_percentage >= 100 ? <img className='float-start h_30' src="/images/BluePolygon.png" alt="" /> : item.completed_percentage >= 25 && item.completed_percentage <= 49 ? <img className='float-start h_30' src="./images/BluePolygonHalf.png" alt="" /> : item.completed_percentage >= 50 && item.completed_percentage <= 74 ? <img className='float-start h_30' src="./images/BluePolygonHalf.png" alt="" /> : item.completed_percentage >= 75 && item.completed_percentage <= 99 ? <img className='float-start h_30' src="./images/BluePolygonHalf.png" alt="" /> : <img className='float-start h_30' src="/images/BluePolygonEmpty.png" alt="" />}
                            {item.total_score > 0 && (
                              <span className="im text-muted ms-2 mt-1" data-testid={`lesson_score-${index}`}>
                                {item.earned_score}/{item.total_score}</span>
                            )}
                          </div>
                          <div className="col  lesson_status text-center" >

                            <span className="ps-4 text-left" data-testid="Lesson_status_textweb">
                              {item.title.toLowerCase().includes(quiz_name)
                                ? item.is_lesson_completed == true
                                  ? hoveredDiv === index
                                    ? <span className='lable_Resume'>Review Quiz</span>
                                    : <span>Completed</span>
                                  : item.completed_percentage <= 0
                                    ? 'Start Quiz'
                                    : 'Resume Quiz'
                                : item.is_lesson_completed == true
                                  ? hoveredDiv === index
                                    ? <span className='lable_Resume'>Review Lesson</span>
                                    : <span>Completed</span>
                                  : item.completed_percentage <= 0
                                    ? <span className='lable_start'>Start</span>
                                    : <span className='lable_Resume'>Resume Lesson</span>
                              }
                            </span>
                          </div>
                        </h5>
                      </div>
                      {/* mobile responsive */}
                      <div className='moduleExpand_mobile'>
                        <div className="module_navs_mobile  custm_shadow_cls" style={{ cursor: "pointer" }} onClick={item.title.toLowerCase().includes(quiz_name) && item.completed_percentage == "100.00" ? doNothing : enterFullScreen}>
                          <div className='d-flex align-items-center'>
                            <div className="w-45">
                              <div className='module_expand_item_one d-flex justify-content-start'>
                                <span className='ml-0 mt-0 mb-0 mr-1'>
                                  {index + 1}.
                                </span>
                                <span className='m-0'>
                                  {item.title.toLowerCase().includes(social_learning_activity) ? item.title.length > 55 ? item.title.slice(0, 50).replace(/ ?- ?.*/, "") + '..' : item?.title?.replace(/ ?- ?.*/, "") : item.title.length > 55 ? item.title.slice(0, 50) + '..' : item?.title}
                                </span>
                              </div>
                            </div>
                            <div className="w-10">
                              <div className='module_lesson_diamond text-center  ml-2'>
                                {item.completed_percentage >= 100 ? <img className='v-align-sub h_30' src="/images/BluePolygon.png" alt="" /> : item.completed_percentage >= 25 && item.completed_percentage <= 49 ? <img className='v-align-sub h_30' src="./images/BluePolygonHalf.png" alt="" /> : item.completed_percentage >= 50 && item.completed_percentage <= 74 ? <img className='v-align-sub h_30' src="./images/BluePolygonHalf.png" alt="" /> : item.completed_percentage >= 75 && item.completed_percentage <= 99 ? <img className='v-align-sub h_30' src="./images/BluePolygonHalf.png" alt="" /> : <img className='v-align-sub h_30' src="/images/BluePolygonEmpty.png" alt="" />}
                              </div>
                            </div>
                            <div className='w-18'>
                              <div>
                                {item.total_score > 0 ? (
                                  <span className="im v-align-sub text-muted m-1 diamond_text" >
                                    {item.earned_score}/{item.total_score}</span>
                                ) : (<span className="visible-hidden v-align-sub im text-muted m-1 diamond_text" >
                                  {0}/{0}</span>)}
                              </div>
                            </div>
                            <div className="w-27">
                              <div className='text-end'>
                                <span className="module_expand_item_third" >
                                  {item.title.toLowerCase().includes(quiz_name)
                                    ? item.is_lesson_completed == true
                                      ? hoveredDiv === index
                                        ? <span className='lable_Resume'>Review</span>
                                        : <span >Review Quiz</span>
                                      : item.completed_percentage <= 0
                                        ? <span className='lable_start'>Start</span>
                                        : <span className='lable_Resume'>Resume</span>
                                    : item.is_lesson_completed == true
                                      ? hoveredDiv === index
                                        ? <span className='lable_Resume'>Review</span>
                                        : <span className='custom'>Review Lesson</span>
                                      : item.completed_percentage <= 0
                                        ? <span className='lable_start'>Start</span>
                                        : <span className='lable_Resume'>Resume</span>
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </Fragment>

                )

              })}
            </ul>

          </div>
        </div>
      </div>
      {/* this line for Unit test case of redo quiz is opening or not  */}
      {showModal && (<div data-testid="Redo_quiz"></div>)}
      {showModal && (
        <Modal show={showModal} onHide={handleClose} centered ClassName="role_modal" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <div className="text-center">
              <h4 className="blue_text text-center redo_quiz">{user_attempts < max_attempts ? redo_text : review_text} Quiz?</h4>
              {<p className="modal_text">You have completed {user_attempts < max_attempts ? `${user_attempts} out of ${max_attempts == quiz_max_attempts ? unlimited_text : max_attempts}` : `all ${max_attempts == quiz_max_attempts ? unlimited_text : max_attempts}`} attempts on this quiz{user_attempts < max_attempts ? ', allowing you to either view the questions or ' + redo_text.toLowerCase() + ' the quiz.' : '.'}</p>}
            </div>
            {user_attempts < max_attempts ?
              <div className="text-center pb-3 redo_btn_container">
                <button className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  quiz_redo_button m-0 px-4" data-testid="reviewLesson" onClick={reviewLesson}>REVIEW</button>&nbsp;&nbsp;
                <button className="btn my_btn_cls submit_button m-0 px-4 fw-bold" data-testid="redoLesson" onClick={redoLesson}>REDO QUIZ</button>
              </div> :
              (user_attempts = max_attempts &&
                <div className="text-center pb-3 redo_btn_container">
                  <button className="btn review_quiz_button my_outline_button_cls submit_button fw-bold quiz_redo_button m-0 px-4" data-testid="closePopup" onClick={handleClose}>CANCEL</button>&nbsp;&nbsp;
                  <button className="btn my_btn_cls submit_button m-0 px-4 fw-bold" data-testid="reviewLesson" onClick={reviewLesson}>REVIEW</button>
                </div>)

            }

          </Modal.Body>
        </Modal>
      )}
    </>

  );
};


// ===================================Two======================

