import React from "react";
import BellCurve from "../MainPageComponents/BellCurve";
import NewGraph from "../MainPageComponents/NewGraph";

const LineChart = ({mean, stdev, curveArray, learnerPercentile, coursetotalpoints,score, apiData}) => {
  return (
    <>
      {curveArray.length != 0 ? (
        <BellCurve 
          mean={mean} 
          stdev={stdev} 
          curveArray={curveArray} 
          learnerPercentile={learnerPercentile} 
          coursetotalpoints={coursetotalpoints}
          score ={score}
          apiData = {apiData}
        />
      ):(
        <NewGraph/>
      )}
    </>
  );
};

export default LineChart;
