import React, { useState } from "react";
import { useEffect, Fragment } from "react";
import axios from "../axios-interceptor";
import { useHistory, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import ProgressCircle from "./ProgressCircle";
import LineChart from "./LineChart";
import {
  base_url,
  date_formats,
  Program_type,
  random_number,
  razorpay_key,
  api_error_message,
  land_nave_storefront_url,
  standalone_program_type,
  user_maintenance_type,
} from "../utilities";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Filler
);



// Function to calculate the standard deviation


const ProgrammeDashboard = (props) => {

  const [mean, setMean] = useState("");
  const [stdev, setStdev] = useState("");
  
  const [curveArray,setCurveArray] = useState([]);
  const [learnerPercentile,setLearnerPercentile] = useState("");
  const [coursetotalpoints,setCourseTotalPoints] = useState("");

 let [score, setScore] = useState(0);
let [learnerActivity, setLearnerActivity] = useState({});
let [onTrackMessage, setOnTrackMessage] = useState("");


  let [dynamic_badge, SetDynamicBadge] = useState({});
  let history = useHistory();
  let location = useLocation();
  let [mainLoader, setMainLoader] = useState(true);
  let [programmeinfo, setProgrammeInfo] = useState([]);

  let [learnerAccessed, setLearnerAccessed] = useState({}); //doughnut chart
  let [courselearnerperformance, setCourseLearnerPerformance] = useState({});
  let [courselp, setCourseLP] = useState({});

  let [coursedropdown, setCourseDropdown] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});

  const [apiData, setApiData] = useState({});
  let [lessonObject, setLessonObject] = useState({});
  let programme_id = location?.state?.program_id ??sessionStorage.getItem('dashboard_program_id');




  useEffect(() => {
    if(!localStorage.getItem("user_id") || !localStorage.getItem('User_Accesstoken') || !localStorage.getItem('isLoggedIn') ) {
      localStorage.clear();
      history.push("/");
      window.location.reload();
  }else{
    axios
      .post(base_url + "user/get_learner_activity", {
        user_id: localStorage.getItem("user_id"),
        program_id: programme_id,
      })
      .then((res) => {
        setMainLoader(false);
        setLearnerActivity(res.data.result);
        const resulttext = res?.data?.result;
        if(resulttext.on_track === 1){
          setOnTrackMessage("Your fantastic performance is testament to your dedication and hard work. Keep shining brightly on your educational journey!")

        }else if(resulttext.on_track === 0){
          setOnTrackMessage(
            <>
              You are lagging behind the schedule; as per the schedule you should be in <strong>{resulttext.course_title}</strong> and you need to finish {resulttext.behind_track_courses} courses to get on track again!
            </>
          );
        }
      })
      .catch((err) => {
        setMainLoader(false);
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }
  }, []);

  useEffect(() => {
    axios
      .post(base_url + "user/program_progress_data", {
        user_id: localStorage.getItem("user_id"),
        program_id: programme_id,
      })
      .then((ress) => {
       
        let programs = [ress.data.data.program_info];
        programs.map((data) => {
          data.courses.map((item, index) => {
            if (index == 0) {
              item["selected_item"] = true;
              item["background_color"] = "#057092";
              item["color"] = "white";
            } else {
              item["selected_item"] = false;
              item["color"] = "#057092";
              item["background_color"] = "white";
            }
          });
        });
        programs.map((data) => {
          data.courses.map((course) => {
            course["module_release_date"] = 0;
            for (var module of course.modules) {
              if (
                module.start_date >
                date_formats.convert_current_date_utc_format()
              ) {
                course["module_release_date"] = module.start_date;
                break;
              }
            }
          });
        });
        setProgrammeInfo(programs);
      })
      .catch((err) => {
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post(base_url + "user/get_learner_last_accessed_course", {
        user_id: localStorage.getItem("user_id"),
        program_id: programme_id,
      })
      .then((res) => {
       axios
       .post(base_url + "user/get_last_accessed_module", {
         user_id: localStorage.getItem("user_id"),
         program_id: programme_id,
         cohort_schedule_id: res?.data?.result?.cohort_schedule_id,
         course_id: res?.data?.result?.course_id,
         module_id: res?.data?.result?.module_id,
         
       })
       .then((response) => {
        let modules = response?.data?.result?.course?.modules[0][0];
        let lessons = modules?.lessons;
        let item = lessons[0].find(item => {
          return res?.data?.result?.lesson_id === item?.lesson_id;
        });
        let index = lessons[0].findIndex(item => {
          return res?.data?.result?.lesson_id === item?.lesson_id;
        });
        let lessons_completed = false;
        let except_first_last_modules = false;
        let except_last_lesson = [...lessons[0]]
         except_last_lesson.pop();
         let except_first_module = lessons.every(item1 => {
           return index == 0;
         });
         if (except_first_module == false && response?.data?.result?.course?.last_module_id != res?.data?.result?.module_id && res?.data?.result?.lesson_id == lessons[0][lessons[0].length - 1]['lesson_id']) {
           lessons_completed = except_last_lesson.every(item => {
             return item.is_lesson_completed == true
           })
         }

         if (except_first_module == false && response?.data?.result?.course?.last_module_id != res?.data?.result?.module_id) {
           except_first_last_modules = true;
         }
        setLessonObject({
          id:item,
          moduleId:res?.data?.result?.module_id,
          courseId : res?.data?.result?.course_id,
          lesson_id:res?.data?.result?.lesson_id,
          cohort_id:res?.data?.result?.cohort_schedule_id,
          module_end_date:modules.end_date,
          index: index,
          lessons_completed: lessons_completed,
          except_first_last_modules:modules.except_first_last_modules
        })
       })
       .catch((err) => {
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
        setLearnerAccessed(res.data.result);

        const scheduledProgress = res.data.result.scheduled_progress_percentage;
        const progressPercentage = res.data.result.learner_progress_percentage;

        const chartData =
          progressPercentage < 0
            ? [
                (progressPercentage / scheduledProgress) * 100,
                100 - (progressPercentage / scheduledProgress) * 100,

                scheduledProgress + progressPercentage - 100,
              ]
            : [
                progressPercentage,
                scheduledProgress - progressPercentage,

                100 -
                  (progressPercentage + scheduledProgress - progressPercentage),
              ];

        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: chartData,
            },
          ],
        }));
      })
      .catch((err) => {
        if (
          err.response?.statusText == "Unauthorized" ||
          err.response?.data?.statusText == "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);
  useEffect(() => {
    if(selectedCourse?.cohort_schedule_id)
    getCourseWiseData(selectedCourse.cohort_schedule_id)
  }, [selectedCourse]);


 const getCourseWiseData =(cohort_schedule_id)=>{
 
  axios
  .post(base_url + "user/get_course_wise_learners_performance", {
    user_id: localStorage.getItem("user_id"),
    cohort_schedule_id: cohort_schedule_id,
  })
  .then((res) => {
    setCourseLearnerPerformance(res.data.data);
    res?.data?.data?.allLearnersScoresArray?.length == 0 ? setCurveArray([]) : setCurveArray(res?.data?.data?.allLearnersScoresArray);
    setLearnerPercentile(res?.data?.data?.learner_percentile);
    setCourseTotalPoints(res?.data?.data?.course_total_points);
    // setCourseLearnerPerformances(res.data.data);
    setScore(res?.data?.data?.learner_earned_score);
    setCourseLP(res.data);
    setApiData(res.data.data);
    const learnerPercentile = res?.data?.data?.learner_percentile;
    const allLearnersScoresArray = res?.data?.data?.allLearnersScoresArray;
    const coursetotalpoints = res?.data?.data?.course_total_points;

    const data = res.data.data;


      // 
      //   data['0-10'],
      //   data['10-20'],
      //   data['20-30'],
      //   data['30-40'],
      //   data['40-50'],
      //   data['50-60'],
      //   data['60-70'],
      //   data['70-80'],
      //   data['80-90'],
      //   data['90-100']
      // ]
      // setRangeData(extractedRangeData);
      
  });
  
 }




  useEffect(() => {
    axios
      .post(base_url + "get_all_courses_for_program", {
        program_id: programme_id,
      })
      .then((res) => {
      
        setCourseDropdown(res.data.data.courses);
        //setSelectedCourse(res.data.data.courses[0]);
        let courses = res?.data?.data?.courses[0];
        if(courses?.cohort_schedule_id)
        getCourseWiseData(courses.cohort_schedule_id)
      });
  }, []);


  

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    const course = coursedropdown.find((c) => c.course_name === courseId);
    setSelectedCourse(course);
  };

 

  const [isCardVisible, setIsCardVisible] = useState(false);
  const handleMouseEnter = () => setIsCardVisible(true);
  const handleMouseLeave = () => setIsCardVisible(false);

 


  const [chartData, setChartData] = useState({
    labels: props.labels || [
      "Scheduled Progress",
      "Your Progress",
      "Overall Progress",
    ],
    datasets: [
      {
        data: props.dataLabels || [0, 0, 30],
        backgroundColor: ["#D1A13D", "#057092", "#CAD3DB"],
        hoverBackgroundColor: ["#D1A13D", "#057092", "#CAD3DB"],
      },
    ],
  });

  
 

  const totalTimeSpent = learnerActivity?.total_time_spent || "0 hr 0 mins";
  const timeValues = totalTimeSpent.match(/\d+/g).map(Number);

  const goToStoreFront = (link) => {
    window.open(
      link ? link : land_nave_storefront_url + "learning-tracks",
      "_blank"
    );
  };

  const setCourse = (program_index, index) => {
    let program = [...programmeinfo];
    program.map((data, index1) => {
      if (index1 == program_index) {
        data.courses.map((item, index2) => {
          if (index2 == index) {
            item["selected_item"] = true;
            item["background_color"] = "#057092";
            item["color"] = "white";
          } else {
            item["selected_item"] = false;
            item["color"] = "#057092";
            item["background_color"] = "white";
          }
        });
      }
    });
    setProgrammeInfo(program);
  };

  function ProgressBar(props) {
    const { completed } = props;
    const containerStyles = {
      height: 5,
      width: "100%",
      backgroundColor: "#D9D9D9",
      marginTop: 40,
      marginRight: 14,
    };
    const fillerStyles = {
      width: `${completed}%`,
      textAlign: "end",
      marginTop: -12,
      marginLeft: completed == 100 ? 4 : -2,
    };
    const labelStyles = {
      padding: 5,
      zIndex: -1,
      color: "transparent",
    };
    return (
      <div
        data-testid="dashboard_CourseProgress"
        className="dashboard_CourseProgress"
      >
        <div style={fillerStyles}>
          <img
            className="dashboard_CourseProgressimg"
            src="./images/diamond.svg"
            alt=""
          />
        </div>
      </div>
    );
  }
  const ShowDynamicBadge = (id) => {
    SetDynamicBadge({});
    SetDynamicBadge((prevFlags) => ({ ...prevFlags, [id]: true }));
  };
  const HideDynamicBadge = (id) => {
    SetDynamicBadge((prevFlags) => ({ ...prevFlags, [id]: false }));
  };

  const redirectToModulePage = (id, cohort_id,program_id) => {
    localStorage.setItem("cohort_id", cohort_id);
    localStorage.setItem("user_id", localStorage.getItem("user_id"));
    localStorage.setItem("course_id", id);
    localStorage.setItem("program_id",program_id);
    history.push("/modulepage");
  };
 const redirectToLessonPage = () =>{
    if(Object.keys(lessonObject).length != 0){
      history.push('/lessonpage',lessonObject)
    }
 };
  return (
    <>
      {mainLoader ? (
        <>
          <div>
            <img
              className="atrium_loader"
              src="images/atrium_loader.gif"
              alt="Loader"
            />
          </div>
        </>
      ) : (
        <>
          {/* Welcome Section */}
          <div className="dashboard-container">
            <div className="flex-main">
              <div className="first-div">
                <span className="span-name" data-testid="Login_first_name">
                  Welcome {learnerActivity?.first_name}{""}
                  {learnerActivity?.last_name}!
                </span>
                <p className="para-txt" data-testid="Main_Heading">
                  {/* You are lagging behind the schedule; as per the schedule you
                  should be in Managing High Performance Teams and you need to
                  finish 1 courses to get on track again */}

                  {/* {learnerActivity?.on_track}
                  {learnerActivity?.behind_track_courses} */}
                  
                  {onTrackMessage}
                </p>
              </div>

              {/* Programme Details Section   */}
              {Object.keys(learnerActivity).length != 0 && (
                <div className="programme-card">
                  <div className="programme-details">
                    
                    <h2 className="programme-title">
                      {learnerActivity.program_name}
                    </h2>
                    <p className="programme-subtitle">
                      ➔ {learnerActivity.course_title}
                    </p>
                  </div>
                  <div className="programme-dates">
                    <div className="date-column">
                      <p className="date-label">Start Date</p>
                      <p className="date-label2">Soft Deadline</p>
                      <p className="date-label">Hard Deadline</p>
                    </div>
                    <div className="date-column-column">
                      <p className="date-value-column">
                        {date_formats.human_date_format2(
                          learnerActivity.program_start_date
                        )}
                      </p>
                      <p className="date-value-column">
                        {date_formats.human_date_format2(
                          learnerActivity.program_soft_deadline
                        )}
                      </p>
                      <p className="date-value-column">
                        {date_formats.human_date_format2(
                          learnerActivity.program_hard_deadline
                        )}
                      </p>
                    </div>
                    <div className="date-column-last">
                      <p className="date-value">
                        {date_formats.human_date_format2( 
                          learnerActivity.course_start_date
                        )}
                      </p>
                      <p className="date-value">
                        {date_formats.human_date_format2(
                          learnerActivity.course_end_date
                        )}
                      </p>
                      <p className="date-value">
                        {date_formats.human_date_format2(
                          learnerActivity.course_hard_deadline
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Current Programme Section
               */}
              {Object.keys(learnerAccessed).length != 0 && (
                <>
                  <div className="current-programme-card-cps">
                    <span className="span-currentprogram">
                      {/* Current Programme */}
                    </span>
                  </div>
                  <div className="current-programme-card">
                    <div className="course-card">
                   <h4 className="course-title">{learnerAccessed?.course_progress_status === 0 ? 'Current Course' : 'Last Accessed Course'}</h4>

                      {/* <h3 className="course-title">
                        {learnerAccessed?.course_name}
                      </h3> */}

                      <div className="course-content">
                        <div className="logo-info-btn">
                          <div className="logo-info">
                            <div className="logo-container">
                              <img
                                className="eclipse-logo"
                                src="images/Ellipse.png"
                                alt="ellipse"
                              />
                              <img
                                className="book-logo"
                                src="images/book.png"
                                alt="booklogo"
                              />
                            </div>
                            <div className="course-info">
                              <h4 className="course-name">
                              {learnerAccessed?.course_name}
                              </h4>
                              <p className="course-description">
                                {learnerAccessed?.lesson_name}
                              </p>
                              <ul className="course-details">
                                <li className="lidata">
                                 <div>
                                  <img
                                    className="badge-certi"
                                    src="images/Badge icon.png"
                                    alt="Badge"
                                  />
                                  <span className="li-txt">
                                    {" "}
                                    {learnerAccessed?.learner_earned_score}/{learnerAccessed?.course_total_score}
                                    Points (
                                    {Math.round(
                                      (learnerAccessed?.learner_earned_score /
                                        learnerAccessed?.course_total_score) *
                                        100
                                    )}
                                    %)
                                  </span>
                                  </div>
                                </li>
                                <li>
                                  <img
                                    className="badge-certi"
                                    src="images/Certificatepd.png"
                                    alt="certificate"
                                  />
                                  <span className="li-txt">
                                    You will earn the Certificate
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="start-btn"
                         >
                          <button className="start-button"
                          alt="startresume"
                           data-testid = "start_resume"
                          onClick={()=>redirectToLessonPage()}
                          >
                          {learnerAccessed?.course_progress_status == 0 ? 'Start' : "Resume"} Learning ➔
                          </button>
                          </div>
                        </div>  

                        <div className="course-progress">
                          
                         
                          <div className="progress-circle">
                          <ProgressCircle
        learnerAccessed={learnerAccessed}
        setLearnerAccessed={setLearnerAccessed}
        base_url={base_url}
        programme_id={programme_id}
        history={history}
      />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Progress Card   */}
                    <div className="progress-card">
                      <div className="progress-flex">
                        <h3 className="progress-title">{courselp?.message}</h3>
                        <div className="course-select">
                          <label htmlFor="course-name" className="course-label">
                            Course Name
                          </label>
                          <select
  id="course-name"
  data-testId="CourseDropdown"
  className="course-dropdown"
  value={selectedCourse ? selectedCourse.course_name : ""}
  onChange={(event) => handleCourseChange(event)}
>
  {coursedropdown.map((course) => (
    <option
      key={course.course_id}
      value={course.course_name}
      title={course.course_name}  // Tooltip for full name
    >
      {course.course_name.length > 25
        ? `${course.course_name.substring(0, 24)}...`
        : course.course_name}
    </option>
  ))}
</select>

                        </div>
                      </div>
                      <p className="progress-info">
                        {curveArray.length == 0 ?
                        <>
                        You have earned 0/{courselearnerperformance?.course_total_points} points, you are at 0 percentile of your Course.
                        </>:
                        <>
                          You have earned{" "}
                        {courselearnerperformance?.learner_earned_score}/{courselearnerperformance?.course_total_points}{" "}
                        points ({Math.round((courselearnerperformance?.learner_earned_score / courselearnerperformance?.course_total_points) *
                          100)}%){" "}, you are at{" "}
                        {typeof courselearnerperformance?.learner_percentile == "string" && courselearnerperformance?.learner_percentile?.includes('.00')?parseInt(courselearnerperformance?.learner_percentile)?.toFixed(0) :courselearnerperformance?.learner_percentile}{" "}
                        percentile of your Course
  </>}
</p>

                      
                     
                    {<LineChart mean={mean} stdev={stdev} curveArray={curveArray} learnerPercentile={learnerPercentile} coursetotalpoints={coursetotalpoints} score={score} apiData={apiData}/>}

                      <div className="performance-container">
                        <div className="performance-block">
                          <span className="performance-label">
                            Low
                            <br />
                            Performance
                          </span>
                          <img
                            className="arrow"
                            src="images/arrow_per.svg"
                            alt="arrow"
                          />
                        </div>
                        <div className="performance-block">
                          <span className="performance-label">
                            Average
                            <br />
                            Performance
                          </span>
                          <img
                            className="arrow"
                            src="images/arrow_per.svg"
                            alt="arrow"
                          />
                        </div>
                        <div className="performance-block">
                          <span className="performance-label">
                            High
                            <br />
                            Performance
                          </span>
                        </div>
                      </div>
                      
                      <div className="progress-performance-span">
                        <span>Course wise Learners Performance</span>
                      </div>
                    </div>
                  </div>{" "}
                </>
              )}

              {/* My Activity Section   */}
              {Object.keys(learnerActivity).length != 0 && (
                <>
                  <div className="current-programme-card-aps">
                    <span className="span-currentprogram-activity">
                      My Activity
                    </span>
                  </div>
                  <div className="row activity-row">
                    <div className="col-3 d-flex flex-start ml-1">
                      <div className="activity-container">
                        <img
                          className="yellowellipse"
                          src="images/Ellipse 3.svg"
                          alt="ellipse"
                        />
                        <img
                          className="badgelogo"
                          src="images/Badgea.png"
                          alt="badge"
                        ></img>
                      </div>
                      <div className="stat-item">
                        <div className="badge-content">
                          <span className="course-count">
                            {learnerActivity.badges_earned}
                          </span>
                          <span className="badge-total">
                            {"/" + learnerActivity.total_badges}
                          </span>
                        </div>
                        <span className="label">Badges Achieved</span>
                      </div>
                    </div>
                    <div className="col-4 d-flex flex-start ml-1">
                      <div className="activity-container">
                        <img
                          className="yellowellipse"
                          src="images/EllipseDb.svg"
                          alt="ellipse"
                        />
                        <img
                          className="timelogo"
                          src="images/Timea.png"
                          alt="time"
                        ></img>
                      </div>
                      <div className="stat-item">
                      <div className="time" style={{ display: 'flex', alignItems: 'center' }}>
    <span className="badge-count">{timeValues[0]}</span>
    <span className="badge-count" style={{ marginLeft: '4px' }}>Hour</span>&nbsp;
    <span className="badge-count" style={{ marginLeft: '4px' }}>{timeValues[1]}</span>
    <span className="badge-count" style={{ marginLeft: '4px' }}>Min(s)</span>
</div>
                        <span className="label">Total Learning Time Spent</span>
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex flex-start ml-1"
                      data-testid="hovercard"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="activity-container">
                        <img
                          className="yellowellipse"
                          src="images/EllipseLb.svg"
                          alt="ellipse"
                        />
                        <img
                          className="certificatelogo"
                          src="images/CertificateA.png"
                          alt="certificate"
                        ></img>
                      </div>
                      <div className="stat-item">
                        <div className="courses">
                          <span className="course-count">
                            {learnerActivity.courses_completed}
                          </span>
                          <span className="course-total">
                            {"/" + learnerActivity.total_courses}
                          </span>
                        </div>
                        <span className="label">Course Certificates</span>
                      </div>
                      {isCardVisible && (
                        <div className="hover-card">
                          <div className="span-course-txt-div">
                          <span className="span-course-txt">
                            Course Certificates
                          </span>
                          </div>
                          
                          {learnerActivity?.courses.map((course, index) => {
                            return (
                              <>
                                {/* course completed and got certificate */}
                                {course?.is_course_completed == 1 &&
                                  course?.certificate_pass_percentage <=
                                    course?.percentage_scored_by_learner && (
                                    <div className="img-h" key={index}>
                                     
                                      <img
                                        className="course-img"
                                        src={"./images/greentick.svg"} //green tick
                                        alt={course.status}
                                      />
                                    
                                      <div className="text-container">
                                      <span className="course-name-h">
                                        {course.course_title}
                                      </span>
                                      {/* green tick message */}
                                      <div className="message">
                                      You achieved your Certificate
                                      </div>
                                      </div>
                                    </div>
                                  )}
                                {/* not met criteria */}
                                {course?.is_course_completed == 0 &&
                                  course?.percentage_scored_by_learner == 0 && course?.course_start_date < date_formats.convert_current_date_utc_format(new Date()) && (
                                    <div key={index}>
                                      <img
                                        className="course-img"
                                        src={"./images/redred.svg"} //red cross mark
                                        alt={course.status}
                                      />
                                      <span className="course-name-h">
                                        {course.course_title}
                                      </span>
                                      {/* red cross mark message */}
                                      <div className="message">
                                        You have not met the{" "}
                                        {course?.certificate_pass_percentage +
                                          "%"}{" "}
                                        criteria
                                      </div>
                                    </div>
                                  )}
                                {/* in progress case */}
                                {course?.is_course_completed == 0 &&
                                  course?.percentage_scored_by_learner != 0 &&
                                  course?.certificate_pass_percentage >
                                    course?.percentage_scored_by_learner && (
                                    <div key={index}>
                                      <img
                                        className="course-img"
                                        src={"./images/yellowicon.svg"} //yellow circle
                                        alt={course.status}
                                      />
                                      <span className="course-name-h">
                                        {course?.course_title} 
                                      </span>
                                      {/* yellow circle message */}
                                      <div className="message">
                                        You only need to complete{" "}
                                        {course
                                          ?.certificate_pass_percentage -
                                          course
                                            ?.percentage_scored_by_learner +
                                          "%"}{" "}
                                        more to be awarded your certificate
                                      </div>
                                    </div>
                                  )}
                                {/* course lock case case */}
                                {course.is_course_completed == 0 && 
                                  course?.course_start_date >
                                    date_formats.convert_current_date_utc_format(
                                      new Date()
                                    ) && (
                                    <div key={index}>
                                      <img
                                        className="course-img"
                                        src={"./images/lockicon.svg"} //lock image
                                        alt={course.status}
                                      />
                                      <span className="course-name-h">
                                        {course?.course_title}
                                      </span>
                                      {/* lock image message */}
                                      <div className="message">
                                        This course will be released on {""} {date_formats.human_date_format(course?.course_start_date)}
                                      </div>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* <ManagementEssentials /> */}
              {programmeinfo.length != 0 && (
                <>
                  <div className="container row dashboard_shadow bg-body text-left  mt-lg-5 web manaess">
                    {programmeinfo?.map((item1, index) => {
                      return (
                        <Fragment key={index}>
                          <div
                            key={index}
                            className="px-4 pb-4 pt-4 managementessentials"
                            style={{ backgroundColor: "#FFFFFF" }}
                          >
                            <div className="d-flex justify-content-between mb-3">
                              <h3 className="blue_text ml-sm-0">
                                <span
                                  data-testid="learningTrackTitle1"
                                  className="dashboard_subHead"
                                >
                                  {item1.learning_track_title}
                                </span>
                              </h3>
                              <button
                               disabled={
                                item1.user_earned_program_percentage <
                                item1.certificate_pass_percentage
                              }

                                data-testid="view-certificate"
                                onClick={() =>
                                  history.push("/userprofile", {
                                    key: "Certificates-tab",
                                  })
                                }
                                className="view_certificate"
                              >
                                View Certificate ➔
                              </button>
                            </div>

                            <div className="row ml-lg-1 completeme">
                              {item1 &&
                              item1.program_type == standalone_program_type &&
                              item1.courses.length == 1 ? (
                                <div
                                  className="col-lg-4 pr-lg-5 "
                                  data-testid="Standalone_programe"
                                >
                                  <div className="container dashboard_shadow standalone-course">
                                    <p className="standalone-font">
                                      {" "}
                                      {item1.learning_track_message
                                        ? item1.learning_track_message
                                        : `This course is part of the ${item1.learning_track_title} Learning Track`}
                                    </p>
                                    <button
                                      className="learn_more"
                                      data-testid="learnmore_web"
                                      onClick={() =>
                                        goToStoreFront(item1.learning_track_url)
                                      }
                                    >
                                      Learn More
                                      <img src="images/learn_more.svg"></img>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-lg-4 pr-lg-5 coursenamePadding firstme"
                                  data-testid="coursesList"
                                >
                                  {item1.courses.map((course, ind) => {
                                    return (
                                      <Fragment key={ind}>
                                        <div className="container mb-2">
                                          <div
                                            data-testid={`singleCourse-${ind}`}
                                            className="row p-2 dashboard_shadow"
                                            style={{
                                              backgroundColor:
                                                course.background_color,
                                            }}
                                            onClick={() =>
                                              setCourse(index, ind)
                                            }
                                          >
                                            <div className="col-lg-1 col-sm-1 col-1 pl-1 pt-2">
                                              {course.cohort_start_date >
                                              date_formats.convert_current_date_utc_format() ? (
                                                <>
                                                  {course.selected_item ? (
                                                    <img
                                                      className="dashboard-image-vertical-align"
                                                      src="images/lock_selected.svg"
                                                    ></img>
                                                  ) : (
                                                    <img
                                                      className="dashboard-image-vertical-align"
                                                      src="images/lock.svg"
                                                    ></img>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {course.modules_count ==
                                                    course.completed_modules_count &&
                                                  course.course_completed_date ? (
                                                    course.selected_item ? (
                                                      <img
                                                        className="dashboard-image-vertical-align"
                                                        src="images/completed_selected.svg"
                                                      ></img>
                                                    ) : (
                                                      <img
                                                        className="dashboard-image-vertical-align"
                                                        src="images/completed.svg"
                                                      ></img>
                                                    )
                                                  ) : course.completed_modules_count >=
                                                    0 ? (
                                                    course.selected_item ? (
                                                      <img
                                                        className="dashboard-image-vertical-align"
                                                        src="images/resume_selected.svg"
                                                      ></img>
                                                    ) : (
                                                      <img
                                                        className="dashboard-image-vertical-align"
                                                        src="images/resume.svg"
                                                      ></img>
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            <div className="col-lg-11 col-sm-11 col-11 pr-1 cursor-pointer">
                                              <span
                                                data-testid="courseTitle"
                                                className="program_span"
                                                style={{ color: course.color }}
                                              >
                                                {course.course_title}
                                              </span>
                                              <br />
                                              <span
                                                className="program_span_status"
                                                style={{ color: course.color }}
                                              >
                                                {course.cohort_start_date <
                                                date_formats.convert_current_date_utc_format() ? (
                                                  <>
                                                    {course.modules_count ==
                                                      course.completed_modules_count &&
                                                    course.course_completed_date
                                                      ? `Completed on ${date_formats.human_date_format(
                                                          course.course_completed_date
                                                        )} `
                                                      : `${
                                                          date_formats.convert_current_date_utc_format() >
                                                          course.cohort_start_date
                                                            ? "Started"
                                                            : "Starts"
                                                        } on ${date_formats.human_date_format(
                                                          course.cohort_start_date
                                                        )}`}
                                                  </>
                                                ) : (
                                                  `Starts on ${date_formats.human_date_format(
                                                    course.cohort_start_date
                                                  )}`
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                                </div>
                              )}
                              <div className="col-lg-8 pl-lg-4 secondme">
                                {item1.courses.map((item, idx) => {
                                  return item.selected_item ? (
                                    <Fragment key={idx + "innerItems1"}>
                                     
                                      <div className="container dashboard_shadow dsfirst">
                                        <div className="container">
                                          <img
                                            data-testid="courseImage"
                                            className="img-fluid"
                                            src={
                                              item.image === "" ||
                                              item.image === "null" ||
                                              item.image == null
                                                ? "images/course1.svg"
                                                : item.image
                                            }
                                            alt=""
                                          ></img>
                                        </div>
                                        <div className="p-4">
                                          {item.show_overview && (
                                            <>
                                              <div className="d-flex justify-content-end">
                                                <>
                                                  <ProgressBar
                                                    // key={idx}
                                                    completed={
                                                      (100 /
                                                        item.modules_count) *
                                                      item.completed_modules_count
                                                    }
                                                  />
                                                  <img
                                                    data-testid="dashboard_coursecertificate"
                                                    className="dashboard_coursecertificate course_certificate_img"
                                                    src="./images/course_certificate.svg"
                                                    alt=""
                                                  />
                                                </>
                                              </div>
                                              <div
                                                className="row dashboard_badgesContainer"
                                                data-testid="module_badges"
                                              >
                                                {item.modules.map(
                                                  (badges, idnx) => {
                                                    return badges.is_module_completed ==
                                                      false &&
                                                      badges.module_badge ==
                                                        0 ? (
                                                      <Fragment
                                                        key={
                                                          idnx +
                                                          "innnerLoop2" +
                                                          idx
                                                        }
                                                      >
                                                        {dynamic_badge[
                                                          "registerTip0" +
                                                            badges._id +
                                                            badges.start_date
                                                        ] ? (
                                                          <>
                                                            <div
                                                              className="d-none"
                                                              data-testid="finishbeforebadgeTooltip"
                                                            >
                                                              <span>
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </span>
                                                              <br />
                                                              <span>
                                                                Finish before{" "}
                                                                {date_formats.human_date_format(
                                                                  badges.end_date
                                                                )}
                                                              </span>
                                                            </div>
                                                            <OverlayTrigger
                                                              placement="top"
                                                              overlay={
                                                                <Tooltip
                                                                  className="dashboard_tooltip"
                                                                  id={
                                                                    "registerTip0" +
                                                                    badges._id +
                                                                    badges.start_date
                                                                  }
                                                                >
                                                                  <span>
                                                                    {
                                                                      badges?.module_title
                                                                    }
                                                                  </span>
                                                                  <br />
                                                                  <span>
                                                                    Finish
                                                                    before{" "}
                                                                    {date_formats.human_date_format(
                                                                      badges.end_date
                                                                    )}
                                                                  </span>
                                                                  <span></span>
                                                                </Tooltip>
                                                              }
                                                              offset={[0, 20]}
                                                            >
                                                              <div
                                                                onMouseEnter={() =>
                                                                  ShowDynamicBadge(
                                                                    "registerTip0" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                                className="transparent_isb_badge"
                                                                data-testid="transparent_isb_badge_mobile"
                                                                id={
                                                                  "registerTip0" +
                                                                  badges._id +
                                                                  badges.start_date
                                                                }
                                                                onMouseLeave={() =>
                                                                  HideDynamicBadge(
                                                                    "registerTip0" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                              >
                                                                <div className="isb_badge_text">
                                                                  <p className="text-center mb-2 white_color">
                                                                    {badges
                                                                      ?.module_title
                                                                      ?.length >=
                                                                    43
                                                                      ? badges?.module_title.slice(
                                                                          0,
                                                                          43
                                                                        ) +
                                                                        "..."
                                                                      : badges?.module_title}
                                                                  </p>
                                                                  {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                                                </div>
                                                              </div>
                                                            </OverlayTrigger>{" "}
                                                          </>
                                                        ) : (
                                                          <div
                                                            onMouseEnter={() =>
                                                              ShowDynamicBadge(
                                                                "registerTip0" +
                                                                  badges._id +
                                                                  badges.start_date
                                                              )
                                                            }
                                                            className="transparent_isb_badge"
                                                            id={
                                                              "registerTip0" +
                                                              badges._id +
                                                              badges.start_date
                                                            }
                                                          >
                                                            <div className="isb_badge_text">
                                                              <p className="text-center mb-2 white_color">
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </p>
                                                              {/* <p className="text-center white_color">{item.course_title}</p> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Fragment>
                                                    ) : badges.is_module_completed ==
                                                        true &&
                                                      badges.module_badge ==
                                                        0 ? (
                                                      <Fragment
                                                        key={
                                                          idnx +
                                                          "innnerLoop2" +
                                                          idx
                                                        }
                                                      >
                                                        {dynamic_badge[
                                                          "registerTip1" +
                                                            badges._id +
                                                            badges.start_date
                                                        ] ? (
                                                          <>
                                                            <div
                                                              className="d-none"
                                                              data-testid="missedbadgeTooltip"
                                                            >
                                                              <span>
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </span>
                                                              <br />
                                                              <span>
                                                                You Missed This
                                                                Badge
                                                              </span>
                                                            </div>

                                                            <OverlayTrigger
                                                              placement="top"
                                                              overlay={
                                                                <Tooltip
                                                                  className="dashboard_tooltip"
                                                                  id={
                                                                    "registerTip1" +
                                                                    badges._id +
                                                                    badges.start_date
                                                                  }
                                                                >
                                                                  <span>
                                                                    {
                                                                      badges?.module_title
                                                                    }
                                                                  </span>
                                                                  <br />
                                                                  <span>
                                                                    You Missed
                                                                    This Badge
                                                                  </span>
                                                                  <span></span>
                                                                </Tooltip>
                                                              }
                                                              offset={[0, 20]}
                                                            >
                                                              <div
                                                                onMouseEnter={() =>
                                                                  ShowDynamicBadge(
                                                                    "registerTip1" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                                className="transparent_isb_badge"
                                                                id={
                                                                  "registerTip1" +
                                                                  badges._id +
                                                                  badges.start_date
                                                                }
                                                                onMouseLeave={() =>
                                                                  HideDynamicBadge(
                                                                    "registerTip1" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                              >
                                                                <div className="isb_badge_text">
                                                                  <p className="text-center mb-2 white_color">
                                                                    {badges
                                                                      ?.module_title
                                                                      ?.length >=
                                                                    43
                                                                      ? badges?.module_title.slice(
                                                                          0,
                                                                          43
                                                                        ) +
                                                                        "..."
                                                                      : badges?.module_title}
                                                                  </p>
                                                                  {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                                                </div>
                                                              </div>
                                                            </OverlayTrigger>
                                                          </>
                                                        ) : (
                                                          <div
                                                            onMouseEnter={() =>
                                                              ShowDynamicBadge(
                                                                "registerTip1" +
                                                                  badges._id +
                                                                  badges.start_date
                                                              )
                                                            }
                                                            className="transparent_isb_badge"
                                                            id={
                                                              "registerTip1" +
                                                              badges._id +
                                                              badges.start_date
                                                            }
                                                          >
                                                            <div className="isb_badge_text">
                                                              <p className="text-center mb-2 white_color">
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </p>
                                                              {/* <p className="text-center white_color">{item.course_title}</p> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Fragment>
                                                    ) : (
                                                      <Fragment
                                                        key={
                                                          idnx +
                                                          "innnerLoop2" +
                                                          idx
                                                        }
                                                      >
                                                        {dynamic_badge[
                                                          "registerTip2" +
                                                            badges._id +
                                                            badges.start_date
                                                        ] ? (
                                                          <>
                                                            <div
                                                              className="d-none"
                                                              data-testid="wonbadgeTooltip"
                                                            >
                                                              <span>
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </span>
                                                              <br />
                                                              <span>
                                                                You Won The
                                                                Badge
                                                              </span>
                                                            </div>
                                                            <OverlayTrigger
                                                              placement="top"
                                                              overlay={
                                                                <Tooltip
                                                                  className="dashboard_tooltip"
                                                                  id={
                                                                    "registerTip2" +
                                                                    badges._id +
                                                                    badges.start_date
                                                                  }
                                                                >
                                                                  <span>
                                                                    {
                                                                      badges?.module_title
                                                                    }
                                                                  </span>
                                                                  <br />
                                                                  <span>
                                                                    You Won The
                                                                    Badge
                                                                  </span>
                                                                  <span></span>
                                                                </Tooltip>
                                                              }
                                                              offset={[0, 20]}
                                                            >
                                                              <div
                                                                onMouseEnter={() =>
                                                                  ShowDynamicBadge(
                                                                    "registerTip2" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                                className="isb_badge"
                                                                id={
                                                                  "registerTip2" +
                                                                  badges._id +
                                                                  badges.start_date
                                                                }
                                                                onMouseLeave={() =>
                                                                  HideDynamicBadge(
                                                                    "registerTip2" +
                                                                      badges._id +
                                                                      badges.start_date
                                                                  )
                                                                }
                                                              >
                                                                <div className="isb_badge_text">
                                                                  <p className="text-center mb-2 white_color">
                                                                    {badges
                                                                      ?.module_title
                                                                      ?.length >=
                                                                    43
                                                                      ? badges?.module_title.slice(
                                                                          0,
                                                                          43
                                                                        ) +
                                                                        "..."
                                                                      : badges?.module_title}
                                                                  </p>
                                                                  {/* <p className="text-center white_color">{item?.course_title?.length >= 44 ? item?.course_title.slice(0, 44) + '...' : item?.course_title }</p> */}
                                                                </div>
                                                              </div>
                                                            </OverlayTrigger>
                                                          </>
                                                        ) : (
                                                          <div
                                                            onMouseEnter={() =>
                                                              ShowDynamicBadge(
                                                                "registerTip2" +
                                                                  badges._id +
                                                                  badges.start_date
                                                              )
                                                            }
                                                            className="isb_badge"
                                                            id={
                                                              "registerTip2" +
                                                              badges._id +
                                                              badges.start_date
                                                            }
                                                          >
                                                            <div className="isb_badge_text">
                                                              <p className="text-center mb-2 white_color">
                                                                {
                                                                  badges?.module_title
                                                                }
                                                              </p>
                                                              {/* <p className="text-center white_color">{item?.course_title}</p> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </>
                                          )}
                                          <div className="text-left position-relative dashboard_moduleStatus">
                                            <div className="row">
                                              <div className="col-7 moduledate">
                                                {item.show_overview && (
                                                  <span data-testid="module_completedInfo">
                                                    You have completed{" "}
                                                    {
                                                      item.completed_modules_count
                                                    }
                                                    /{item.modules_count}{" "}
                                                    Modules!
                                                  </span>
                                                )}

                                                <p
                                                  style={{ color: "#057092" }}
                                                  className="mt-1"
                                                  data-testid="courseandmoduleExtentedInfo"
                                                >
                                                  {date_formats.convert_current_date_utc_format() <
                                                  item.cohort_end_date ? (
                                                    item.show_overview ==
                                                    false ? (
                                                      <div>
                                                        <span className="text-black mb-1">
                                                          Welcome to the{" "}
                                                          {
                                                            item1.learning_track_title
                                                          }{" "}
                                                          programme!
                                                        </span>

                                                        <br />
                                                        <br />
                                                        <span className="">
                                                          Start Date:{" "}
                                                          <b>
                                                            {date_formats.human_date_format(
                                                              item.cohort_start_date
                                                            )}
                                                          </b>{" "}
                                                        </span>
                                                        <br />
                                                        <span className="">
                                                          End Date:{" "}
                                                          <b>
                                                            {date_formats.human_date_format(
                                                              item.cohort_end_date
                                                            )}
                                                          </b>{" "}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <span>
                                                        This course will end on{" "}
                                                        <b>
                                                          {date_formats.human_date_format(
                                                            item.cohort_end_date
                                                          )}
                                                        </b>
                                                        .
                                                        <br />
                                                        {item.show_overview && (
                                                          <>
                                                            Your certificate
                                                            will be issued upon
                                                            achieving{" "}
                                                            {Math.round(
                                                              item?.certificate_pass_percentage
                                                            ) ? (
                                                              Math.round(
                                                                item?.certificate_pass_percentage
                                                              )
                                                            ) : (
                                                              <img
                                                                src="/images/loading_dots.gif"
                                                                className="dots_loader"
                                                                alt="Loader"
                                                              ></img>
                                                            )}
                                                            %
                                                          </>
                                                        )}
                                                      </span>
                                                    )
                                                  ) : date_formats.convert_current_date_utc_format() >=
                                                    item.cohort_end_date ? (
                                                    item.module_release_date !==
                                                    0 ? (
                                                      <span>
                                                        The next module(s) will
                                                        be released on{" "}
                                                        <b>
                                                          {date_formats.human_date_format(
                                                            item.module_release_date
                                                          )}
                                                        </b>
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        The course end date is
                                                        extended until{" "}
                                                        <b>
                                                          {date_formats.human_date_format(
                                                            item.cohort_expiry_date
                                                          )}
                                                        </b>
                                                        <br />
                                                        {item.show_overview && (
                                                          <>
                                                            {" "}
                                                            Your certificate
                                                            will be issued upon
                                                            achieving{" "}
                                                            {Math.round(
                                                              item?.certificate_pass_percentage
                                                            ) ? (
                                                              Math.round(
                                                                item?.certificate_pass_percentage
                                                              )
                                                            ) : (
                                                              <img
                                                                src="/images/loading_dots.gif"
                                                                className="dots_loader"
                                                                alt="Loader"
                                                              ></img>
                                                            )}
                                                            %{" "}
                                                          </>
                                                        )}
                                                      </span>
                                                    )
                                                  ) : null}
                                                </p>
                                              </div>
                                              <div className="col">
                                                <div key={item.index}>
                                                  {item.cohort_start_date <=
                                                    date_formats.convert_current_date_utc_format() &&
                                                  item.cohort_expiry_date >
                                                    date_formats.convert_current_date_utc_format() ? (
                                                    <>
                                                      {item.is_paid == 1 ? (
                                                        item1.application_status ==
                                                          2 &&
                                                        item.completed_modules_count ==
                                                          0 ? (
                                                          <button
                                                            onClick={() =>
                                                              redirectToModulePage(
                                                                item.course_id,
                                                                item.cohort_schedule_id,
                                                                item1.program_id
                                                              )
                                                            }
                                                            data-testid="courseStartorResume"
                                                            className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                          >
                                                            {item.course_started ==
                                                            0
                                                              ? "Start Course"
                                                              : "Resume Course"}
                                                          </button>
                                                        ) : (
                                                          <>
                                                            {item.completed_modules_count !=
                                                              item.modules_count &&
                                                            item.completed_modules_count >
                                                              0 &&
                                                            item.cohort_expiry_date >
                                                              date_formats.convert_current_date_utc_format() ? (
                                                              <button
                                                                onClick={() =>
                                                                  redirectToModulePage(
                                                                    item.course_id,
                                                                    item.cohort_schedule_id,
                                                                    item1.program_id

                                                                  )
                                                                }
                                                                data-testid="resumeCourse"
                                                                className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                              >
                                                                Resume Course
                                                              </button>
                                                            ) : (
                                                              <>
                                                                {item.completed_modules_count ==
                                                                  item.modules_count &&
                                                                item.cohort_expiry_date >
                                                                  date_formats.convert_current_date_utc_format() ? (
                                                                  <button
                                                                    onClick={() =>
                                                                      redirectToModulePage(
                                                                        item.course_id,
                                                                        item.cohort_schedule_id,
                                                                        item1.program_id
                                                                      )
                                                                    }
                                                                    data-testid="reviewCourse"
                                                                    className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                                  >
                                                                    Review
                                                                    Course
                                                                  </button>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item1.application_status ==
                                                        2 &&
                                                      item.module_release_date ==
                                                        0 &&
                                                      item.is_paid == 1 &&
                                                      item.cohort_start_date <
                                                        date_formats.convert_current_date_utc_format()
                                                        ? item.show_overview && (
                                                            <button
                                                              onClick={() =>
                                                                history.push(
                                                                  "/userprofile",
                                                                  {
                                                                    key: "Certificates-tab",
                                                                  }
                                                                )
                                                              }
                                                              data-testid="view&certificates"
                                                              className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                            >
                                                              View Badges &
                                                              Certificates
                                                            </button>
                                                          )
                                                        : ""}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment
                                      key={idx + "innerItems1"}
                                    ></Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </>
              )}
              {/* <div className="feedbackbtn-div">
            <button disabled={true} className="feedback-btn">
              Complete Feedback ➔
            </button>
          </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProgrammeDashboard;
