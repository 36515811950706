import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { toast } from "react-toastify";

const ProgressCircle = ({ learnerAccessed, setLearnerAccessed, base_url, programme_id, history }) => {
  
  const [chartData, setChartData] = useState({
    labels: [
      
      "Your Progress         ",
      "Scheduled Progress",
    ],
    datasets: [
      {
        
        backgroundColor: ["#057092", "#D1A13D"],
        hoverBackgroundColor: ["#057092", "#D1A13D"],
      },
    ],
  });

  const doughnutOptions = {
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: "70%",
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
          padding: 20,
          pointStyle: "circle",
          boxWidth: 20,
        },
        onClick: (e) => e.stopPropagation(),
      },
    },
  };

  const centerTextPlugin = {
    id: "centerText",
    afterDatasetsDraw(chart) {
      const { ctx, width, height } = chart;
      ctx.save();
      const text = "";
      ctx.font = "bold 24px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000";
      ctx.fillText(text, width / 2, height / 2);
    },
  };

  useEffect(() => {
    axios
      .post(base_url + "user/get_learner_last_accessed_course", {
        user_id: localStorage.getItem("user_id"),
        program_id: programme_id,
      })
      .then((res) => {
        setLearnerAccessed(res.data.result);

        const scheduledProgress = res.data.result.scheduled_progress_percentage;
        const progressPercentage = res.data.result.learner_progress_percentage;

        const chartData =
          progressPercentage < 0
            ? [
                (progressPercentage / scheduledProgress) * 100,
                100 - (progressPercentage / scheduledProgress) * 100,
                scheduledProgress + progressPercentage - 100,
              ]
            : [
                progressPercentage,
                scheduledProgress - progressPercentage,
                100 - (progressPercentage + scheduledProgress - progressPercentage),
              ];

        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: chartData,
            },
          ],
        }));
      })
      .catch((err) => {
        if (
          err.response?.statusText === "Unauthorized" ||
          err.response?.data?.statusText === "Unauthorized"
        ) {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn("API error message", { toastId: Math.random() });
        }
      });
  }, [base_url, programme_id, setLearnerAccessed, history]);

  return (
    <div className="progress-circle">
      <Doughnut
        className="doughnut"
        data={chartData}
        options={doughnutOptions}
        plugins={[centerTextPlugin]}
       
      />
      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "28px",
          fontWeight: "bold",
        }}
      >
        <span className="doughnutper">
          {learnerAccessed?.learner_progress_percentage}%
        </span>
        <span
          style={{
            position: "absolute",
            fontSize: "15px",
            top: "35px",
            left: "7%",
          }}
        >
          <span
            style={{
              position: "absolute",
              fontSize: "15px",
              left: "12%",
            }}
          >
            Complete
          </span>
        </span>
      </div>
    </div>
  );
};

export default ProgressCircle;
