import React, { Fragment,useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import { base_url,date_formats, random_number,api_error_message,standalone,cohort_based,standalone_program_type,Program_type, admin_maintenance_type, twoDaysBeforeDate,currentDate} from "../../utilities";
import { KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from "react-bootstrap";
const points_system_default_data=[
  {
    "points_type": "Priming Activity",
    "attempt": 1,
    "accuracy": 0,
    "mastery": 0
  },
  {
    "points_type": "Knowledge Check",
    "attempt": 1,
    "accuracy": 1,
    "mastery": 0
  },
  {
    "points_type": "Module Quiz",
    "attempt": 1,
    "accuracy": 2,
    "mastery": 0
  },
  {
    "points_type": "Course Quiz",
    "attempt": 1,
    "accuracy": 3,
    "mastery": 0
  }
]

const CourseList = () => {
  let [data, setData] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState();
  let [error,setError] = useState()
  let [moduleDateError,setModuleDateError] = useState(false)
  let [loading_flag,SetLoading_flag]=useState(true)
  let [button_flag,SetButton_flag]=useState(false)
  let [autoCater,setAutoCater]=useState(true);
  let [is_open_registration,setIs_open_registration]=useState(true);
  const [programmeType, setProgrammeType] = useState(cohort_based);
  const programm_id = location.state?.programme_id;
  let is_editing=location.state?.is_editing
  const savedData = localStorage.getItem('save_obj');
  const learning_track_selected =  localStorage.getItem("learning_track_selected");
  const [initialAvailableDate,setinitialAvailableDate] = useState();
  let [initialSubmissionStartDate,setinitialSubmissionStartDate] = useState();
  const [initialStartDate,setinitialStartDate] = useState();
  let [applocationError,setApplocationError] = useState(false)
  let [visibility,setVisibility] = useState([{id:1,value:"Public",is_checked:false},
    {id:2,value:"Private",is_checked:false},
    {id:3,value:"Unlisted",is_checked:false}])
    const [currentPathName,setCurrentPathName] = useState(window.location.pathname);

    const platformOptionsdata=[{value:'ISBo',label:'ISB Online'},{value:'ISBm',label:'ISB Mobile'},{value:'ISBx',label:'ISBx'}]
    const [selectedPlatformOption, setSelectedPlatformOption] = useState([]);
    const {control,formState: { errors }} = useForm({ mode: "all"});
    const [disableNextBtn, setDisableNextBtn] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
    if(savedData){
      let data =JSON.parse(savedData)
      //update programme level bufferdays and harddeadline
      data['course_buffer_time']=data['buffer_days']
      delete data.buffer_days
      data=updateBufferdayshandler(data)
      handleUpdateInitialDates(data)
      handleVisibilityUpdate(data.status)
      data.courses.filter(item=>{
        item.modules.map(module=>{
          module.initial_start_date = currentDate(date_formats.date_format())
          // module.initial_end_date = twoDaysBeforeDate(date_formats.date_format(module.end_date))
        })
      })

      handleUpdatePlatformAvailableData(data) //update selected options
      handleUpdateProgrammeType(data)
      handleUpdateSuffixAndPrefixdata(data)
      data.is_open_for_registrations = is_open_registration
      setData(data);
      SetLoading_flag(false);
    }else if(location.state?.programme_id){
      SetButton_flag(true)
      axios
      .post(base_url + "get_program_data_by_id", {
        program_id: location.state.programme_id,
      })
      .then((res) => {
        let programmeData=res.data.data
        handleUpdateInitialDates(programmeData)
        setlocalStorageItems(programmeData)
        handleUpdatePlatformAvailableData(programmeData) //update selected options

        programmeData=updateBufferdayshandler(programmeData)

        if(!programmeData['points_policy'] || programmeData['points_policy'].length == 0)
        {
          programmeData['points_policy'] = [...points_system_default_data];
        }

        programmeData.courses.forEach((item,index)=>{
          if(!item['points_policy'] || item['points_policy'].length == 0)
          {
            item['points_policy']=[...points_system_default_data]
          }
          item.modules.forEach(module=>{
            if(!module['points_policy'] || module['points_policy'].length == 0)
            {
              module['points_policy']=[...points_system_default_data]
            }
          module.initial_start_date = currentDate(date_formats.date_format())
          // module.initial_end_date = twoDaysBeforeDate(date_formats.date_format(module.end_date))
            if(module['quiz_duration'] ==0){
              module['is_checked'] =false
            }else{
              module['is_checked'] =true
            }
          })
        })
        handleUpdateProgrammeType(programmeData)
        handleUpdateSuffixAndPrefixdata(programmeData)
        handleVisibilityUpdate(1)
        setIs_open_registration(programmeData.is_open_for_registrations)
        setData(programmeData);
        SetLoading_flag(false);
        setShowModal(true)
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
    }else if(location.state?.learning_track_id){
      axios.post(base_url+'get_learning_tracks_courses_data',{
        learning_track_id:location.state.learning_track_id
      }).then(res=>{
        let data = {...res.data.data}
        setlocalStorageItems(data)
        delete data.description;
        
        data=commonDataHandler(data,"learning_Track")
        delete data.cost
        handleVisibilityUpdate(data.status)
        const firstLetters = data.learning_track_name
        .split(' ')
        .map(word => word.charAt(0))
        .join('');
        data.program_id = `${moment().format('YYYYMMDDhmmss')+'_'+firstLetters}`
        data.is_open_for_registrations = is_open_registration
        setData(data);
        handleUpdateSuffixAndPrefixdata(data)
        SetLoading_flag(false);
      }).catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
    }else if(location.state?.courses){
      axios.post(base_url+'get_courses_details_for_program',{
        courses:location.state.courses
      }).then(res=>{
        let data = {courses:[]}
        data.courses = res.data.data
        data.program_id = `${moment().format('YYYYMMDDhmmss')+'_CP'}`
        data=commonDataHandler(data,"courses") //update data
        data.program_name =""
        handleVisibilityUpdate(1)
        handleUpdateSuffixAndPrefixdata(data)
        data.is_open_for_registrations = is_open_registration
        setData(data);
        SetLoading_flag(false);
      }).catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
    }else{
      setDisableNextBtn(true)
      SetLoading_flag(false);
    }
   
  }, []);

  // update the selected options on edit
  const handleUpdatePlatformAvailableData=(data)=>{
    let platform_arr = []
    const {available_platform}=data
    if(available_platform && available_platform.length>0)
    {
      for(let i=0;i<available_platform.length;i++){
            const option=available_platform[i]
            platformOptionsdata.map(optionObj => {
              if(option===optionObj.value)
              {
                platform_arr.push(optionObj)
              }
            });
      }
      setSelectedPlatformOption(platform_arr)
    }
  }
  // update programme Type
  const handleUpdateProgrammeType=(data)=>{
    if(data?.program_type == Program_type){
      setProgrammeType(cohort_based)
    }else{
      setProgrammeType(standalone)
    }
  }
// update visiblity on edit
  const handleVisibilityUpdate=(id)=>{
    visibility.map(item=>{
      if(item.id == id)
      {
        item.is_checked = true
      }
    })
  }
// update suffix and prefix
  const handleUpdateSuffixAndPrefixdata=(data)=>{
    setPrefixValue(data?.program_id?.split('_')[0])
    setSuffixValue(data?.program_id?.split('_').slice(1).join('_'))
  }
// update initial dates
  const handleUpdateInitialDates=(data)=>{
    setinitialStartDate(twoDaysBeforeDate(date_formats.date_format(data?.start_date)))
    setinitialAvailableDate(twoDaysBeforeDate(date_formats.date_format(data?.available_date)))
    setinitialSubmissionStartDate(twoDaysBeforeDate(date_formats.date_format(data?.application_submission_start_date)))
  }

  //common data update
  const commonDataHandler=(data,eventType)=>{
    data.available_date =  date_formats.convert_current_date_utc_format()
    data.start_date =  date_formats.convert_current_date_utc_format()
    data.application_submission_start_date =  date_formats.convert_current_date_utc_format()
    data.application_submission_end_date =  date_formats.convert_current_date_utc_format()
    data.program_orientation_date =  date_formats.convert_current_date_utc_format()
    data['points_policy']=[...points_system_default_data]
    handleUpdateInitialDates(data)

    data.courses&&data.courses.length>0&&data.courses.map((item,index)=>{
      item['points_policy']=[...points_system_default_data]
      item['badge_bonus_points']=10
      item['sla_ita_attendance_points']=10
      item.modules&&item.modules.length>0&&item.modules.map(module=>{
        module['points_policy']=[...points_system_default_data]
        module['badge_bonus_points']=10
        module['sla_ita_attendance_points']=10
        module['quiz_duration'] = 0
        module['is_checked'] =false   
        module.lessons&&module.lessons.length>0&&module['lessons'].map(lesson=>{
          lesson.activities_data&&lesson.activities_data.length>0&&lesson["activities_data"].map(activity=>{
            activity['showActivtityInfo']=false
          })
        })
      })
      if(eventType==="learning_Track"){
         item.course_cost =  data.cost
       }
      item.buffer_days=0
      if(index==0){
        item.start_date= data.start_date
        for(let i=0;i<item.modules.length; i++){
          if(i==0){
            item.modules[i].start_date = data.start_date
            item.modules[i].end_date =  item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
            item.end_date= item.modules[i].end_date;
          }else{
            item.modules[i].start_date =item.modules[i-1].end_date 
            item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
            if(eventType==="learning_Track")
            {
              item.end_date= item.modules[i].end_date 
            }
          }
          item.modules[i].initial_start_date = eventType==="learning_Track"? currentDate(date_formats.date_format()):twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
        }
        item.end_date=item.modules[item.modules.length-1].end_date //updating the course end date
      }else{
        item.start_date = data.courses.at(index-1).modules.at(-1).end_date 
       data.courses.at(index).modules[0].start_date =data.courses.at(index-1).modules.at(-1).end_date 
      data.courses.at(index).modules[0].end_date = data.courses.at(index).modules[0].start_date + (data.courses.at(index).modules[0].duration * 60 * 60 * 1000);
        for(let i=1;i<item.modules.length; i++){
          item.modules[i].start_date =item.modules[i-1].end_date 
          item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
          item.modules[i].initial_start_date = eventType==="learning_Track" ?  currentDate(date_formats.date_format()) :twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
        }
        item.end_date= item.modules[item.modules.length-1].end_date
      }
    
     data.end_date =  item.modules[item.modules.length-1].end_date
     item['Course_Soft_Deadline']=item.modules[item.modules.length-1].end_date
    })
    data['program_hard_deadline']=data.end_date
    for (const course of data.courses) {
      course['courseLevelHarddeadline']=data.end_date

      if (course['Course_Soft_Deadline'] && data.end_date) {
        // Define your two dates
        const date1 = moment(course['Course_Soft_Deadline']).startOf('day').startOf('day');
        const date2 = moment(data.end_date).startOf('day').startOf('day');

        // Calculate the difference in days
        const daysDifference = date2.diff(date1, 'days');
        course['buffer_days'] = daysDifference
      }
    }

    data.status = 1
    data.internal_notes =''
    data.capacity = 10
    data.course_buffer_time=0
    localStorage.setItem('course_buffer_time',parseInt(0))
    data.badge_bonus_points=10
    data.sla_ita_attendance_points=10
    data.program_cost = 1000
    data.allowed_quiz_attempts=0


    return data
  }

  //set learning track to localstorage
  const setlocalStorageItems=(data)=>{
    localStorage.setItem("learning_track_name",data.learning_track_name)
  }

  const updateBufferdayshandler=(programmeData)=>{
    let programme_bufferDays=programmeData['course_buffer_time'];
    const momentObject = moment(programmeData['end_date']);
    const harddeadline_date = momentObject.add(parseInt(programme_bufferDays), 'days');
    programmeData['program_hard_deadline']=harddeadline_date.valueOf()


    programmeData.courses.map(course=>{
      let module_endDate=course.modules[course.modules.length-1].end_date
      course['Course_Soft_Deadline']=module_endDate

      if(course['buffer_days']!== null && course['buffer_days'] !== undefined && course['buffer_days'] !== "") //update end date sum up with buffer days
      {
        const momentObject = moment(module_endDate);
        const courseharddeadline_date = momentObject.add(parseInt(course.buffer_days), 'days');
        course['courseLevelHarddeadline']=courseharddeadline_date.valueOf()
      }
    })
    return programmeData
  }
  

  const handlePlatformSelectChange = (newValue) => {
    setSelectedPlatformOption(newValue)
    let program_data = JSON.parse(JSON.stringify(data))
    let platform_arr = []
    newValue.map(item=>{
      platform_arr.push(item.value)
    })
    program_data['available_platform']=platform_arr
    setData(program_data)
};

  const handleBufferDaysandHarddeadlinesUpdate = async(program_data,current_date) => {
    //**update programme hard deadline with buffer days**//
    if (program_data['course_buffer_time'] && parseInt(program_data['course_buffer_time']) !== 0) {
      const programme_buffer_days = parseInt(program_data['course_buffer_time'])
      const momentObject = moment(current_date); //end_date
      const programme_HardDeadline_date = momentObject.add(parseInt(programme_buffer_days), 'days');
      program_data['program_hard_deadline'] = programme_HardDeadline_date.valueOf()
    }
    else { //if course_buffer_time is 0
      const momentObject = moment(current_date); //end_date
      program_data['program_hard_deadline'] = momentObject.valueOf()
    }
    program_data.courses.map(course => { //updating course level hard dead line

      if (course['Course_Soft_Deadline'] && current_date) {
        // Define your two dates
        const date1 = moment(course['Course_Soft_Deadline']).startOf('day');
        const date2 = moment(current_date).startOf('day');

        // Calculate the difference in days
        const daysDifference = date2.diff(date1, 'days');
        course['buffer_days'] = daysDifference
      }
      course['courseLevelHarddeadline'] = program_data['program_hard_deadline']
    })

    return program_data
  }
  
  const changeRegistration=(e)=>{
    setIs_open_registration(e.target.checked)
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.is_open_for_registrations = e.target.checked
    setData(program_data)
  }

  const onChange =async(e,date)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    let current_date = parseInt((Math.round(new Date(e).getTime()/1000))+'000')
    if(date == "available_date"){
      if(current_date<=program_data.start_date){
        program_data[date]=current_date
        setData(program_data)
        setError(undefined)
      }else{

        program_data[date]=current_date
        setData(program_data)
        setError('Please select the date below the start date')
      }
    }
    else if(date == "program_orientation_date")
    {
      program_data[date]=current_date
      setData(program_data)
    }
    else if(date == "application_submission_start_date"){
      program_data[date]=current_date
      setData(program_data)
    }else if(date == "application_submission_end_date"){
      if(current_date>program_data.end_date){
        setApplocationError(true)
      }else{
        setApplocationError(false)
      }
      program_data[date]=current_date
      setData(program_data)
    }
    else{
      if(current_date>=program_data.available_date){
        setError(undefined)
        if(autoCater)
        {
          callSetModules(current_date,date)
        }
        else{
          let program_data =JSON.parse(JSON.stringify(data))
          program_data[date]=current_date
          if(date=="end_date")//if its date=="end_date"
          { 
            if(current_date<program_data.application_submission_end_date){
              setApplocationError(true)
            }else{
              setApplocationError(false)
            }
            program_data=await handleBufferDaysandHarddeadlinesUpdate(program_data,current_date)
          }
          setData(program_data)
        }
      }else{
        program_data[date]=current_date
        if(date=="end_date")//if its date=="end_date"
        {    
          if(current_date<program_data.application_submission_end_date){
            setApplocationError(true)
          }else{
            setApplocationError(false)
          }  
          program_data= await handleBufferDaysandHarddeadlinesUpdate(program_data,current_date)
        }
        setData(program_data)
        setError('Please select the date below the start date')
      }
    }
  }
  const callSetModules = async(current_date,date) =>{
    let program_data = JSON.parse(JSON.stringify(data))
    if(date == "end_date"){
      program_data[date]=current_date
      if(current_date<program_data.application_submission_end_date){
        setApplocationError(true)
      }else{
        setApplocationError(false)
      }
      //update programme hard deadline with buffer days
      program_data= await handleBufferDaysandHarddeadlinesUpdate(program_data,current_date)
      setData(program_data)
    }else{
      program_data[date]=current_date
      program_data?.courses.map((item,index)=>{
        if(index==0){
          item.start_date= current_date
          for(let i=0;i<item.modules.length; i++){
            if(i==0){
              item.modules[i].start_date = current_date
              item.modules[i].end_date =  item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
              // item.end_date= item.modules[i].end_date
            }else{
              item.modules[i].start_date =item.modules[i-1].end_date 
              item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
              // item.end_date= item.modules[i].end_date
            }
            item.modules[i].initial_start_date =  currentDate(date_formats.date_format())

          }
          item.end_date= item.modules[item.modules.length-1].end_date
        }else{
          item.start_date = program_data.courses.at(index-1).modules.at(-1).end_date 
          program_data.courses.at(index).modules[0].start_date =program_data.courses.at(index-1).modules.at(-1).end_date 
          program_data.courses.at(index).modules[0].end_date = program_data.courses.at(index).modules[0].start_date + (program_data.courses.at(index).modules[0].duration * 60 * 60 * 1000);
          for(let i=1;i<item.modules.length; i++){
            item.modules[i].start_date =item.modules[i-1].end_date 
            item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
            item.modules[i].initial_start_date =  twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
          }
          item.end_date= item.modules[item.modules.length-1].end_date
        }
      
       program_data.end_date =  item.modules[item.modules.length-1].end_date 
      })
    setData(program_data)
    }
   
  }
  const onChangeDate = async(e,id,date, course_idx,module_idx) =>{
    let program_data = JSON.parse(JSON.stringify(data))
    let current_date = parseInt((Math.round(new Date(e).getTime()/1000))+'000')
    if(autoCater)
    {
      
      program_data.courses.map((item,index)=>{
        if(index == course_idx){
          if(module_idx == 0 && date == "start_date"){
            item.start_date = current_date 
            item.end_date = item.modules[item.modules.length-1].end_date;
          }
          for(let i=module_idx;i<item.modules.length; i++){
            if(date == "start_date"){
              if(i==module_idx){
                item.modules[i].start_date =current_date 
                item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
              }
              else{
               
                item.modules[i].start_date =item.modules[i-1].end_date 
                item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
                item.end_date= item.modules[item.modules.length-1].end_date
              } 
             // item.modules[i].initial_start_date =  twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
            }
            else{
              if(i==module_idx){
                  item.modules[i].end_date = current_date
                  item.end_date= item.modules[item.modules.length-1].end_date;
              }
              else{
                  item.modules[i].start_date = item.modules[i-1].end_date
                  item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
                  item.end_date= item.modules[item.modules.length-1].end_date 
              }
              //item.modules[i].initial_start_date =  twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
            }
          }
        }
        else if(index > course_idx){
            item.start_date = program_data.courses.at(index-1).modules.at(-1).end_date
            program_data.courses.at(index).modules[0].start_date =program_data.courses.at(index-1).modules.at(-1).end_date 
            program_data.courses.at(index).modules[0].end_date = program_data.courses.at(index).modules[0].start_date + (program_data.courses.at(index).modules[0].duration * 60 * 60 * 1000);
            for(let i=1;i<item.modules.length; i++){
             {
              item.modules[i].start_date =item.modules[i-1].end_date 
              item.modules[i].end_date = item.modules[i].start_date + (item.modules[i].duration * 60 * 60 * 1000);
              //item.modules[i].initial_start_date =  twoDaysBeforeDate(date_formats.date_format(item.modules[i].start_date))
            }
            }
            item.end_date= item.modules[item.modules.length-1].end_date
        }
       program_data.end_date =  item.modules[item.modules.length-1].end_date 
      })
    }
    else{
     
      let course= program_data.courses[course_idx]
      setinitialStartDate(twoDaysBeforeDate(date_formats.date_format(program_data?.start_date)))
      setinitialAvailableDate(twoDaysBeforeDate(date_formats.date_format(program_data?.available_date)))
      setinitialSubmissionStartDate(twoDaysBeforeDate(date_formats.date_format(program_data?.application_submission_start_date)))
      course.modules[module_idx][date]=current_date //updating the module date
      course.modules.map(item=>{

        item.initial_start_date = twoDaysBeforeDate(date_formats.date_format(program_data?.start_date))
      })
          if(module_idx == 0 && date == "start_date"){
            course.start_date = current_date  //updating course start date when module index is zero 
          }
          else if((course.modules.length-1)==module_idx){ //updating the end date if module last item changes
            course.end_date = course.modules[course.modules.length-1].end_date 
          }
          //update programme end date on last course last module on change
          let lastCourseIndex=program_data.courses.length-1;
          let lastcourseLastmoduleIdx=course.modules.length-1;
          if(lastCourseIndex==course_idx&&lastcourseLastmoduleIdx==module_idx)
          {
            program_data.end_date =  course.modules[lastcourseLastmoduleIdx].end_date
          }
    }
    //update programme bufferdays and harddeadline
    program_data=await handleBufferDaysandHarddeadlinesUpdate(program_data,program_data.end_date)

    //**Updating the course level soft deadine and buffer days on last module softdeadline change **/
    for (let index = 0; index < program_data.courses.length; index++) {
      let singleCourse = program_data.courses[index];
      // if (index === course_idx) {
        singleCourse['Course_Soft_Deadline'] = singleCourse.modules[singleCourse.modules.length - 1]['end_date']
        if(singleCourse.modules.length - 1 == module_idx && date == "end_date" && !autoCater &&current_date > program_data.end_date) //update course harddeadline with softdeadline when the autocater is off
        {
          singleCourse['courseLevelHarddeadline']=singleCourse['Course_Soft_Deadline']
        }
        // singleCourse['courseLevelHarddeadline']=data.end_date
        if (singleCourse['Course_Soft_Deadline'] && singleCourse['courseLevelHarddeadline']) {
          // Define your two dates
          const date1 = moment(singleCourse['Course_Soft_Deadline']).startOf('day').startOf('day');
          const date2 = moment(singleCourse['courseLevelHarddeadline']).startOf('day').startOf('day');

          // Calculate the difference in days
          const daysDifference = date2.diff(date1, 'days');
          singleCourse['buffer_days'] = daysDifference
        }
      // }
    }   
    setData(program_data)
  }
  const changeValue =(e)=>{
    let radio_data = [...visibility]
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.status = parseInt(e.target.value)
    setData(program_data)
    radio_data.map(item=>{
      if(item.id == e.target.value)
      {
        item.is_checked = true
      }else{
        item.is_checked = false
      }
    })
    setVisibility(radio_data)
  }
  const changeCapacity =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.capacity = parseInt(e.target.value)||''
    setData(program_data)
  }
  const changeBadgeBonus =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.badge_bonus_points = parseInt(e.target.value)||''
    //update all bonus points
    if(program_data.badge_bonus_points!=='')
    {
      program_data.courses&&program_data.courses.length>0&&program_data.courses.map(course=>{
        course.badge_bonus_points=program_data.badge_bonus_points
        course.modules&&course.modules.length>0&&course.modules.map(module=>{
          module.badge_bonus_points=program_data.badge_bonus_points
        })
      })
    }
    setData(program_data)
  }
  const changeSLAITAAttendance =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.sla_ita_attendance_points = parseInt(e.target.value)||''
    //update the value into course and module level 
    if(program_data.sla_ita_attendance_points!=='')
    {
      program_data.courses&&program_data.courses.length>0&&program_data.courses.map(course=>{
        course.sla_ita_attendance_points=program_data.sla_ita_attendance_points
        course.modules&&course.modules.length>0&&course.modules.map(module=>{
          module.sla_ita_attendance_points=program_data.sla_ita_attendance_points
        })
      })
    }
    setData(program_data)
  }
  const changeProgramCost = (e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.program_cost = parseInt(e.target.value)||''
    setData(program_data)
  }
  const changecourseDuration =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.course_duration = parseInt(e.target.value)||''
    setData(program_data)
  }
  const changeAllowedQuizAttempts =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    let value=parseInt(e.target.value)||(e.target.value === '' ? '' : parseInt(e.target.value));
    program_data.allowed_quiz_attempts = value
    //update all redo attempts for modules level
    program_data.courses.map(course => {
      course.modules.map((module, mindex) => {
        if (mindex != 0) {
          module['allowed_quiz_attempts'] = isNaN(value)||value=='' ? 0 : value
        }
      })
    })
    setData(program_data)
  }
  const changeProgrammeLevelQuizDuration =(e)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    let value=parseInt(e.target.value)||''
    program_data.quiz_duration = value
    //update all redo attempts for modules level
    program_data.courses.map(course => {
      course.modules.map((module, mindex) => {
        if (mindex != 0&&module.is_checked) {
          module['quiz_duration'] = isNaN(value)||value=='' ? 0 : value
        }
      })
    })
    setData(program_data)
  }

    const progremmeBufferDaysHandler=(e)=>{
      let bufferDays=e.target.value;
      localStorage.setItem('course_buffer_time',parseInt(bufferDays)||0)
      let program_data = JSON.parse(JSON.stringify(data))
      const momentObject = moment(program_data['end_date']);
      const harddeadline_date = momentObject.add(parseInt(bufferDays), 'days');
      program_data['program_hard_deadline']=harddeadline_date.valueOf()
      program_data['course_buffer_time']= parseInt(bufferDays)
      program_data.courses.map(course=>{
        // item.buffer_days =parseInt(bufferDays)
        if (course['Course_Soft_Deadline'] && harddeadline_date) {
          // Define your two dates
          const date1 = moment(course['Course_Soft_Deadline']).startOf('day');
          const date2 = moment(harddeadline_date).startOf('day');

          // Calculate the difference in days
          const daysDifference = date2.diff(date1, 'days');
          course['buffer_days'] = daysDifference
        }
        course.courseLevelHarddeadline = harddeadline_date.valueOf()
      })
      
      setData(program_data)
    }
      const courseLevelBufferHandler=(event,courseIndex,inputName)=>{
          let courseLevelSoftdeadline= data.courses[courseIndex].modules[data.courses[courseIndex].modules.length-1].end_date
          let value=event.target.value;
          const momentObject = moment(courseLevelSoftdeadline);
          const courseLevelHarddeadline = momentObject.add(parseInt(value), 'days');
          let program_data = JSON.parse(JSON.stringify(data))
          program_data.courses[courseIndex]['courseLevelHarddeadline']=courseLevelHarddeadline.valueOf()
          program_data.courses[courseIndex]['buffer_days']=parseInt(value)
          setData(program_data)
      }
    
      const changeProgramName = (e)=>{
        let program_data = JSON.parse(JSON.stringify(data))
          program_data.program_name = (e.target.value)
        setData(program_data)
      }
  const changeNotes = (e) =>{
    let program_data = JSON.parse(JSON.stringify(data))
    program_data.internal_notes = e.target.value
    setData(program_data)
  }
  const updatePrgram = () =>{
    if(!!error||moduleDateError)
    {
      toast.error("Invalid Input: Please Resolve the Highlighted Errors", { toastId: random_number });
    }else{
    let program_data = JSON.parse(JSON.stringify(data))
 delete program_data.learning_track_name
 delete program_data.learning_track_id
 let update_flag
  program_data.courses.map((item,index)=>{
    for (let i=0;i <item.modules.length;i++) {
      if(item.modules[i]['is_checked']){
      if(item.modules[i]['quiz_duration'] >=5 && item.modules[i]['quiz_duration'] <=180 ){
        update_flag =true
      }else{
        update_flag =false
        break;
      }
    }else{
      update_flag =true
    }
    }
  })
  if(programmeType == cohort_based){
    program_data['program_type'] = Program_type
  }else{
    program_data['program_type'] = standalone_program_type
  }
  if(update_flag){
    program_data.sla_ita_attendance_points=program_data.sla_ita_attendance_points||0
    program_data.badge_bonus_points=program_data.badge_bonus_points||0
    program_data.quiz_duration=program_data.quiz_duration||0
    delete program_data.program_hard_deadline
    program_data['buffer_days']=program_data.course_buffer_time||0
    delete program_data.course_buffer_time
    if (validateProgramData(program_data)) {
      navgateToProgrammePolicy(program_data)
   }
  }else{
    toast.error("Program Details not updated. Please check the quiz duration... Duration must be greater than or equal to 5 mins and less than or equal to 180 mins",{toastId:random_number})
  }
  }
  }

  const validateProgramData = (program_data) => {
    if (program_data.capacity === '' || isNaN(program_data.capacity) || program_data.capacity === null) {
      toast.error("Please Enter Capacity", { toastId: random_number });
      return false;
    }
    else if (program_data.program_cost === '' || isNaN(program_data.program_cost) || program_data.program_cost === null) {
      toast.error("Please Enter Programme Fee (inc GST)", { toastId: random_number });
      return false;
    }
    else if (program_data.allowed_quiz_attempts === 0 || program_data.allowed_quiz_attempts === '' || isNaN(program_data.allowed_quiz_attempts) || program_data.allowed_quiz_attempts === null) {
      toast.error("Please Enter Quiz Redo Attempts", { toastId: random_number });
      return false;
    }
    else if (programmeType === standalone && (program_data.course_duration === '' || isNaN(program_data.course_duration) || program_data.course_duration === null)) {
      toast.error("Please Enter Course Duration", { toastId: random_number });
      return false;
    }
    else if (!program_data.available_platform || program_data.available_platform.length === 0) {
      toast.error("Please Select Platform Available On", { toastId: random_number });
      return false;
    }
    return true;
  };

  const navgateToProgrammePolicy = (program_data) => {
    SetLoading_flag(false);
    setDisableNextBtn(false)
    localStorage.setItem('save_obj', JSON.stringify(program_data));
    history.push('/programmpolicy', { id: programm_id });
  }

const saveProgram = () =>{
  if(!!error||moduleDateError)
  {
    toast.error("Invalid Input: Please Resolve the Highlighted Errors", { toastId: random_number });
  }else{
  if(suffixValue==""){
    toast.error("Please enter the program code",{toastId:random_number})
    setDisableNextBtn(false)
 }
 else{
  let program_data = JSON.parse(JSON.stringify(data))
  delete program_data.learning_track_name
  if(program_data.internal_notes =='' ){
     program_data.internal_notes = prefixValue+"_"+suffixValue
  }
  let save_flag 
  program_data.courses.map((item,index)=>{
      for (let i=0;i <item.modules.length;i++) {
      if(item.modules[i]['is_checked']){
        if(item.modules[i]['quiz_duration'] >=5 && item.modules[i]['quiz_duration'] <=180 ){
           save_flag =true
        }else{
          save_flag =false
          break;
        }
      }else{
        save_flag =true
      }
      }

    
  })
 
  if(save_flag){

    program_data.sla_ita_attendance_points=program_data.sla_ita_attendance_points||0
    program_data.badge_bonus_points=program_data.badge_bonus_points||0
    program_data.quiz_duration=program_data.quiz_duration||0
    delete program_data.program_hard_deadline
    program_data['buffer_days']=program_data.course_buffer_time||0
    delete program_data.course_buffer_time

    //set the points system value as 0 when we get "",bonuspoints,ITAattendance
    if(programmeType == cohort_based){
      program_data['program_type']= Program_type
    }else{
      program_data['program_type']= standalone_program_type
    }
    if (learning_track_selected === "false") {
      if (program_data.program_name === '') {
        toast.error("Please enter program name", { toastId: random_number });
      } else {
        if (validateProgramData(program_data)) {
           navgateToProgrammePolicy(program_data)
        }
      }
    } else {
      if (validateProgramData(program_data)) {
         navgateToProgrammePolicy(program_data)
      }
    }
  }else{
    setDisableNextBtn(false)
    toast.error("Program not created. Please check the quiz duration... Duration must be greater than or equal to 5 mins and less than or equal to 180 mins",{toastId:random_number})
  }
}
}
}
const checkboxChange = (e,course_index,module_index) =>{
  let program_data = JSON.parse(JSON.stringify(data))
  let duration_value=parseInt(program_data['quiz_duration'])
  program_data.courses[course_index].modules[module_index]['is_checked'] = e.target.checked
  if(e.target.checked == false){
    program_data.courses[course_index].modules[module_index]['quiz_duration'] = 0
  }else{
    program_data.courses[course_index].modules[module_index]['quiz_duration'] = isNaN(duration_value)? 5 : duration_value
  }
  setData(program_data)
}
const backtoAdmin=()=>{
  localStorage.setItem("Selected_LearningTrack",location.state.learning_track_id)
  if(!location.state?.programme_id){
    if(learning_track_selected == "false"){
      history.push('/courseprogram')
    }else{
      history.push('/admindashboard/createprogram')
    }
  }else{
    history.push("/admindashboard/programlist");
  }
  
}

const getRadioChoice = (event) =>{
  setProgrammeType(event.target.value);
}
const [prefixValue, setPrefixValue] = useState('');
const [suffixValue, setSuffixValue] = useState('');

const handleSuffixChange = (e) => {
  let newValue = e;
  setSuffixValue(newValue);
   let program_data = JSON.parse(JSON.stringify(data))
   program_data.program_id =prefixValue+"_"+ newValue
  setData(program_data)
}

const onTimeEdit =(e,course_index,module_index) =>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[course_index].modules[module_index]['quiz_duration'] = e.target.value
  setData(program_data)
 
}
const onRedoAttempt =(e,course_index,module_index) =>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[course_index].modules[module_index]['allowed_quiz_attempts'] =e.target.value
  setData(program_data)
 
}
const handleKeyDown = (event) => {
  // Get the key code of the pressed key
  const keyCode = event.keyCode || event.which;
  if (keyCode === 190 || keyCode === 187 || keyCode === 189 || // Standard keyboard
  keyCode === 110 || keyCode === 107 || keyCode === 109 || keyCode === 69) { // Number pad
  event.preventDefault(); 
  }
}
const handleInputEventBubbling=(event)=>{
  event.stopPropagation();
}
const handleExpandLessonActivity=(courseIndex,moduleIndex,lessonIndex,activityIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))

  program_data.courses[courseIndex].modules[moduleIndex].lessons[lessonIndex].activities_data[activityIndex]['showActivtityInfo']=true

  setData(program_data)
 
}
const handleExpandLessonActivityClose=(courseIndex,moduleIndex,lessonIndex,activityIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[courseIndex].modules[moduleIndex].lessons[lessonIndex].activities_data[activityIndex]['showActivtityInfo']=false
  setData(program_data)
}
  const changeProgrammeLevelPointsSystem = (e, rowIndex, rowKey, rowItem) => {
    const program_data = JSON.parse(JSON.stringify(data))
    program_data.points_policy[rowIndex][rowKey] = parseInt(e.target.value) || '';
    let point_val = program_data.points_policy[rowIndex][rowKey]
    if (point_val != "") {
      program_data.courses && program_data.courses.length > 0 && program_data.courses.forEach(course => {
        course.points_policy[rowIndex][rowKey] = point_val
        course.modules && course.modules.length > 0 && course.modules.forEach(module => {
          module.points_policy[rowIndex][rowKey] = point_val
            module.lessons && module.lessons.forEach((lesson) => {
              lesson.activities_data && lesson.activities_data.forEach((activity) => {
                if(rowItem.points_type.toLowerCase()==activity.activity_type.toLowerCase())
                {
                   activity.points_policy[rowKey]=point_val
                }
              })
            })
         })
      })
    }

    setData(program_data)
  }

const changeCourseLevelBadgeBonus =(e,courseIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  let inputVal=parseInt(e.target.value)||''
  program_data.courses[courseIndex]['badge_bonus_points'] = inputVal
  //update all module level bonus points
  if (inputVal !== '') {
    program_data.courses[courseIndex].modules && program_data.courses[courseIndex].modules.length > 0 && program_data.courses[courseIndex].modules.map(module => {
      module.badge_bonus_points = inputVal
    })
  }
  setData(program_data)
}
const changeCourseLevelSLAITAAttendance =(e,courseIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  let inputVal=parseInt(e.target.value)||''
  program_data.courses[courseIndex]['sla_ita_attendance_points'] = inputVal
  //update all module level bonus points
  if (inputVal !== '') {
    program_data.courses[courseIndex].modules && program_data.courses[courseIndex].modules.length > 0 && program_data.courses[courseIndex].modules.map(module => {
      module.sla_ita_attendance_points = inputVal
    })
  }
  setData(program_data)
}
const changeModuleLevelBadgeBonus =(e,courseIndex,moduleIndex)=>{
  let program_data =JSON.parse(JSON.stringify(data))
  program_data.courses[courseIndex]['modules'][moduleIndex]['badge_bonus_points'] = parseInt(e.target.value)||''
  setData(program_data)
}
const changeModuleLevelSLAITAAttendance =(e,courseIndex,moduleIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[courseIndex]['modules'][moduleIndex]['sla_ita_attendance_points'] = parseInt(e.target.value)||''
  setData(program_data)
}

const changeCourseLevelPointsSystem=(e,courseIndex,rowIndex,rowKey,rowItem)=>{
  let program_data = JSON.parse(JSON.stringify(data))

  program_data.courses.forEach((course,course_index)=>{
    if(courseIndex==course_index)
    {
    course['points_policy'][rowIndex][rowKey]=parseInt(e.target.value)||''

    //update module level points system and lesson level points
      let point_val = course['points_policy'][rowIndex][rowKey]
      if (point_val != "") {
        course.points_policy[rowIndex][rowKey] = point_val
        course.modules && course.modules.length > 0 && course.modules.forEach(module => {
          module.points_policy[rowIndex][rowKey] = point_val
          module.lessons && module.lessons.forEach((lesson) => {
            lesson.activities_data && lesson.activities_data.forEach((activity) => {
              if (rowItem.points_type.toLowerCase() == activity.activity_type.toLowerCase()) {
                activity.points_policy[rowKey] = point_val
              }
            })
          })
        })
      }
    }
  })
  setData(program_data)
}

const changeModuleLevelPointsSystem=(e,courseIndex,moduleIndex,rowIndex,rowKey,rowItem)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[courseIndex]['modules'][moduleIndex]['points_policy'][rowIndex][rowKey]=parseInt(e.target.value)||''
  //update lesson level points
  let point_val = program_data.courses[courseIndex]['modules'][moduleIndex]['points_policy'][rowIndex][rowKey]
  if (point_val != "") {
    program_data.courses[courseIndex]['modules'][moduleIndex].lessons && program_data.courses[courseIndex]['modules'][moduleIndex].lessons.forEach((lesson) => {
      lesson.activities_data && lesson.activities_data.forEach((activity) => {
        if (rowItem.points_type.toLowerCase() == activity.activity_type.toLowerCase()) {
          activity.points_policy[rowKey] = point_val
        }
      })
    })
  }
  setData(program_data)
}

const lessonLevelActivityInputs=(e, courseIndex,moduleIndex,lessonIndex,activityIndex, activityKey)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  program_data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy'][activityKey]=parseInt(e.target.value)||''
  setData(program_data)
}

  useEffect(() => {
    let programme_data = JSON.parse(JSON.stringify(data))
    let hasErrors=false;
    programme_data.courses && programme_data.courses.length > 0 && programme_data.courses.map((course, courseIndex) => {
      course.modules && course.modules.length > 0 && course.modules.map((module, module_idx) => {
        //Show error message when course start date greater than end date for unlock and softdeadline
        if (
          (course.start_date > course.end_date  && module_idx === 0) ||
          (module.start_date > course.end_date  && module_idx !== 0) ||
          (course.end_date < course.start_date  && course?.modules.length - 1 === module_idx) ||
          (module.end_date > course.end_date    && course?.modules.length - 1 !== module_idx)
        ) {
          hasErrors=true
        } 
      })
      if(programme_data.courses.length==courseIndex+1)
      {
        setModuleDateError(hasErrors)

        if(hasErrors)
         setModuleDatesOnAutocaterHandler()
      }
    })
  }, [autoCater])

  useEffect(() => {  //Show error message when course start date greater than end date for unlock and softdeadline
    const errorElements = document.querySelectorAll('.MuiFormHelperText-root1');
    const errorElementsPicker = document.querySelectorAll('.Mui-error');
    errorElements.length > 0 || errorElementsPicker.length > 0 ? setModuleDateError(true):  setModuleDateError(false)
  }, [data])


  const setModuleDatesOnAutocaterHandler=async()=>{
    let program_data=JSON.parse(JSON.stringify(data))
    program_data.courses&&program_data.courses.length>0&&program_data.courses.map((course,courseIndex)=>{
      course.modules&&course.modules.length>0&&course.modules.map((module,mindex)=>{
        if(courseIndex==0)
        {
          if(mindex==0)
          {
            module.end_date = module.start_date + (module.duration * 60 * 60 * 1000);
          }
          else{
            module.start_date =course.modules[mindex-1].end_date
            module.end_date = module.start_date + (module.duration * 60 * 60 * 1000);
            course.end_date= course.modules[course.modules.length-1].end_date
          }
        }
        else{
          if(mindex==0)
          {
            module.start_date =program_data.courses[courseIndex-1].modules[program_data.courses[courseIndex-1].modules.length-1].end_date
            module.end_date = module.start_date + (module.duration * 60 * 60 * 1000);
          }
          else{
            module.start_date =course.modules[mindex-1].end_date
            module.end_date = module.start_date + (module.duration * 60 * 60 * 1000);
            course.end_date= course.modules[course.modules.length-1].end_date
          }
        }
      })
     program_data.end_date =  course.modules[course.modules.length-1].end_date 
    })

    
    //**Updating the course level soft deadine and buffer days on last module softdeadline change **/
    for (let index = 0; index < program_data.courses.length; index++) {
      let singleCourse = program_data.courses[index];
        singleCourse['Course_Soft_Deadline'] = singleCourse.modules[singleCourse.modules.length - 1]['end_date']
        // singleCourse['courseLevelHarddeadline']=data.end_date
        if (singleCourse['Course_Soft_Deadline'] && singleCourse['courseLevelHarddeadline']) {
          // Define your two dates
          const date1 = moment(singleCourse['Course_Soft_Deadline']).startOf('day').startOf('day');
          const date2 = moment(singleCourse['courseLevelHarddeadline']).startOf('day').startOf('day');

          // Calculate the difference in days
          const daysDifference = date2.diff(date1, 'days');
          singleCourse['buffer_days'] = daysDifference
        }
      } 
    // update programme bufferdays and hardeadline
    program_data=await handleBufferDaysandHarddeadlinesUpdate(program_data,program_data.end_date)
    setData(program_data)
    setModuleDateError(false)
  }

  const handleBlur = () => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { course_buffer_time } = programmeData
    if (course_buffer_time === '' || course_buffer_time == undefined || isNaN(course_buffer_time)) {
      programmeData.course_buffer_time = 0
      setData(programmeData)
    }
  };
  const updateBadgeBonus = () => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { badge_bonus_points } = programmeData
    if (badge_bonus_points === '' || badge_bonus_points == undefined || isNaN(badge_bonus_points)) {
      programmeData.badge_bonus_points=0
      programmeData.courses && programmeData.courses.length > 0 && programmeData.courses.map(course => {
        course.badge_bonus_points = 0
        course.modules && course.modules.length > 0 && course.modules.map(module => {
          module.badge_bonus_points = 0
        })
      })
    }
    setData(programmeData)
  };
  const changeSLAITAAttendanceOnBlur = () => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { sla_ita_attendance_points } = programmeData
    if (sla_ita_attendance_points === '' || sla_ita_attendance_points == undefined || isNaN(sla_ita_attendance_points)) {
      programmeData.sla_ita_attendance_points=0
      programmeData.courses && programmeData.courses.length > 0 && programmeData.courses.map(course => {
        course.sla_ita_attendance_points = 0
        course.modules && course.modules.length > 0 && course.modules.map(module => {
          module.sla_ita_attendance_points = 0
        })
      })
    }
    setData(programmeData)
  };


  const changeCourseLevelBadgeBonusOnBlur = (e,courseIndex) => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { badge_bonus_points } = programmeData.courses[courseIndex]
    if (badge_bonus_points === '' || badge_bonus_points == undefined || isNaN(badge_bonus_points)) {
      programmeData.courses && programmeData.courses.length > 0 && programmeData.courses.map(course => {
        course.badge_bonus_points = 0
        course.modules && course.modules.length > 0 && course.modules.map(module => {
          module.badge_bonus_points = 0
        })
      })
    }
    setData(programmeData)
  };
  const changeCourseLevelSLAITAAttendanceOnBlur = (e,courseIndex) => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { sla_ita_attendance_points } = programmeData.courses[courseIndex]
    if (sla_ita_attendance_points === '' || sla_ita_attendance_points == undefined || isNaN(sla_ita_attendance_points)) {
      programmeData.courses && programmeData.courses.length > 0 && programmeData.courses.map(course => {
        course.sla_ita_attendance_points = 0
        course.modules && course.modules.length > 0 && course.modules.map(module => {
          module.sla_ita_attendance_points = 0
        })
      })
    }
    setData(programmeData)
  };
  const changeModuleLevelBadgeBonusOnBlur = (e,courseIndex,moduleIndex) => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { badge_bonus_points } = programmeData.courses[courseIndex].modules[moduleIndex]
    if (badge_bonus_points === '' || badge_bonus_points == undefined || isNaN(badge_bonus_points)) {
      programmeData.courses[courseIndex].modules[moduleIndex].badge_bonus_points=0
    }
    setData(programmeData)
  };
  const changeModuleLevelSLAITAAttendanceOnBlur = (e,courseIndex,moduleIndex) => {
    let programmeData = JSON.parse(JSON.stringify(data))
    let { sla_ita_attendance_points } = programmeData.courses[courseIndex].modules[moduleIndex]
    if (sla_ita_attendance_points === '' || sla_ita_attendance_points == undefined || isNaN(sla_ita_attendance_points)) {
      programmeData.courses[courseIndex].modules[moduleIndex].sla_ita_attendance_points=0
    }
    setData(programmeData)
  };

  const updateProgrammeLevelPointsSystemOnBlur=(e,rowIndex,rowKey,rowItem)=>{
    let program_data = JSON.parse(JSON.stringify(data))
    let point_val=program_data.points_policy[rowIndex][rowKey]
    if (point_val === '' || point_val == undefined || isNaN(point_val)) {
      program_data.points_policy[rowIndex][rowKey]=0
      program_data.courses && program_data.courses.length > 0 && program_data.courses.map(course => {
        course.points_policy[rowIndex][rowKey]=0
        course.modules && course.modules.length > 0 && course.modules.map(module => {
          module.points_policy[rowIndex][rowKey]=0
          module.lessons && module.lessons.map((lesson) => {
            lesson.activities_data && lesson.activities_data.map((activity) => {
              if(rowItem.points_type.toLowerCase()==activity.activity_type.toLowerCase())
              {
                 activity.points_policy[rowKey]=0
              }
            })
          })
        })
      })
    }
    setData(program_data)
  }

  const updateCourseLevelPointsSystemOnBlur = (e, courseIndex, rowIndex, rowKey,rowItem) => {
    let program_data = JSON.parse(JSON.stringify(data))
    let point_val = program_data.courses[courseIndex].points_policy[rowIndex][rowKey]
    if (point_val === '' || point_val == undefined || isNaN(point_val)) {
      program_data.courses[courseIndex].points_policy[rowIndex][rowKey] = 0
      //update the value to module and lesson level
      program_data.courses[courseIndex].modules && program_data.courses[courseIndex].modules.length > 0 && program_data.courses[courseIndex].modules.map(module => {
        module.points_policy[rowIndex][rowKey] = 0
        module.lessons && module.lessons.map((lesson) => {
          lesson.activities_data && lesson.activities_data.map((activity) => {
            if (rowItem.points_type.toLowerCase() == activity.activity_type.toLowerCase()) {
              activity.points_policy[rowKey] = 0
            }
          })
        })
      })
    }
    setData(program_data)
  }
  const updateModuleLevelPointsSystemOnBlur = (e, courseIndex,moduleIndex,rowIndex, rowKey,rowItem) => {
    let program_data = JSON.parse(JSON.stringify(data))
    let point_val = program_data.courses[courseIndex].modules[moduleIndex].points_policy[rowIndex][rowKey]||''
    if (point_val === '' || point_val == undefined || isNaN(point_val)) {
      program_data.courses[courseIndex].modules[moduleIndex].points_policy[rowIndex][rowKey] = 0

       //update the value to lesson level
       program_data.courses[courseIndex].modules[moduleIndex].lessons && program_data.courses[courseIndex].modules[moduleIndex].lessons.map((lesson) => {
          lesson.activities_data && lesson.activities_data.map((activity) => {
            if (rowItem.points_type.toLowerCase() == activity.activity_type.toLowerCase()) {
              activity.points_policy[rowKey] = 0
            }
          })
      })
    }
    setData(program_data)
  }


  const updatelessonLevelActivityInputsOnBlur = (e, courseIndex, moduleIndex, lessonIndex, activityIndex, activityKey) => {
    let program_data = JSON.parse(JSON.stringify(data))
    let point_val = program_data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy'][activityKey] || ''
    if (point_val === '' || point_val == undefined || isNaN(point_val)) {
      program_data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy'][activityKey] = 0
    }
    setData(program_data)
  }
  const handleClose = () => setShowModal(false);
  const handledialogCancel = () =>{
    setShowModal(false);
    //nav to programmes
    history.push('/admindashboard/createprogram')
  } 
  const handledialogConfirm = () =>{
    //stay in the same page
    setShowModal(false);
  } 
const handleCourseLevelHardDealine =(e, courseIndex)=>{
  let program_data = JSON.parse(JSON.stringify(data))
  let current_harddeadline_date = parseInt((Math.round(new Date(e).getTime()/1000))+'000')
  let single_course = program_data.courses[courseIndex]
  let course_soft_deadline = single_course.Course_Soft_Deadline
  if (current_harddeadline_date && course_soft_deadline) {
    // Define your two dates
    const date1 = moment(course_soft_deadline).startOf('day').startOf('day');
    const date2 = moment(current_harddeadline_date).startOf('day').startOf('day');

    // Calculate the difference in days
    const daysDifference = date2.diff(date1, 'days');
    single_course.buffer_days = daysDifference
    single_course.courseLevelHarddeadline =current_harddeadline_date
    setData(program_data)
  }

}
const handleProgrammeLevelHardDealine =(e) =>{
  let program_data = JSON.parse(JSON.stringify(data)) 
  let current_programme_harddeadline_date = parseInt((Math.round(new Date(e).getTime()/1000))+'000') 
  let programme_soft_deadline = program_data.end_date
  if (current_programme_harddeadline_date && programme_soft_deadline) {
    // Define your two dates
    const date1 = moment(programme_soft_deadline).startOf('day').startOf('day');
    const date2 = moment(current_programme_harddeadline_date).startOf('day').startOf('day');

    // Calculate the difference in days
    const daysDifference = date2.diff(date1, 'days');
    program_data.course_buffer_time = daysDifference
    localStorage.setItem('course_buffer_time',parseInt(daysDifference)||0)
    program_data.program_hard_deadline =current_programme_harddeadline_date
    program_data.courses.map(course => { //updating course level hard dead line
      if (course['Course_Soft_Deadline'] && current_programme_harddeadline_date) {
        // Define your two dates
        const date1 = moment(course['Course_Soft_Deadline']).startOf('day');
        const date2 = moment(current_programme_harddeadline_date).startOf('day');

        // Calculate the difference in days
        const daysDifference = date2.diff(date1, 'days');
        course['buffer_days'] = daysDifference
      }
      course['courseLevelHarddeadline'] = program_data['program_hard_deadline']
    })
    setData(program_data)
  }
}
  return (
    <>
      {loading_flag? 
        <>
          <div>
             <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
          </div>
        </>:<>
      <div className="bg-body container p-5 mt-5 mb-2 gap-4 course_list_container">
        <div className="row">
          <div className="col-auto me-auto">
            <h3>{localStorage.getItem('learning_track_name')}</h3>
          </div>
          <div className="col-auto">
            <div className="d-flex">
            <button className="btn my_outline_button_cls align-self-end mt-0 p-2 program_btn_back" data-testid="backToAdmin_btn" onClick={backtoAdmin}>
            Back
            </button>&nbsp;
            {button_flag ? (
              <button disabled={disableNextBtn} data-testid="updateProgramme_next_btn" onClick={updatePrgram} className="my_btn_cls p-2 mt-0 save_button program_btn courses_list">
                Next
              </button>
            ) : (
              <button disabled={disableNextBtn} data-testid="saveProgramme_next_btn" className="my_btn_cls p-2 mt-0 program_btn save_button courses_list" onClick={saveProgram}>
                Next
              </button>
            )}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-lg-5 ps-0">
          <label htmlFor="exampleInput">
            Programme Name (For Internal Reference) <span className="red-color">*</span>
          </label>
           <div>
           <div style={{ display: 'flex' }}>
                {is_editing==true ? (
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    name=""
                    disabled
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    value={data.program_id}
                  />
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control input_prefix"
                      id="prefix"
                      name="prefix"
                      disabled
                      placeholder=""
                      role="presentation"
                      autoComplete="off"
                      value={prefixValue + '_'}
                    />
                    <input
                      type="text"
                      className="form-control input_suffix"
                      id="suffix"
                      name="suffix"
                      placeholder="Enter here..."
                      autoComplete="off"
                      value={suffixValue}
                      data-testid="input_suffix"
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove non-letter characters except underscore
                        handleSuffixChange(newValue.toUpperCase());
                      }}
                    />
                  </>
                )}

    </div>
    </div>
        </div>
        <div className="col-lg-3 ps-0">
      <label htmlFor="programm_type" className="mb-3">
        Programme Type
      </label>
      <br />
      <input
        type="radio"
        id="Cohort"
        name="programmeType"
        value="Cohort Based"
        checked={programmeType == cohort_based}
        onChange={getRadioChoice}
      />
      <span style={{ marginLeft: '8px' }}>Cohort Based</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input
        type="radio"
        id="Standalone"
        name="programmeType"
        value="Standalone"
        data-testid="Standalone_radio"
        checked={programmeType == standalone}
        onChange={getRadioChoice}
      />
      <span style={{ marginLeft: '8px' }}>Standalone</span>&nbsp;&nbsp;&nbsp;
    </div>
        <div className="col-lg-1">
        {programmeType == cohort_based && 
        <>
          <label htmlFor="autocater">Auto Cater</label>
          <br />
          <input id="autocater" type="checkbox" data-testid="Auto_Cater" className="program_checkbox" checked = {autoCater} onChange={(e)=>{setAutoCater(e.target.checked)}}></input>
        </>}
          </div>
          <div className="col-lg-3">
       
        <>
          <label htmlFor="is_open_registration">Is Open For Registration</label>
           <OverlayTrigger placement="bottom" overlay={
                                <Tooltip className="programmeInfo_tooltip"
                                  id={"Registration_Tooltip"}>
                                  <span>
                                  Check to enable learner enrollment.
                                  <br></br> If unchecked, enrollment is disabled
                                  </span>
                                </Tooltip>} offset={[0, 5]}>
                                <span className="cursor-pointer info-icon"> ⓘ</span>
                              </OverlayTrigger>
          <br />
          <input id="is_open_registration" type="checkbox" data-testid="is_open_registration" className="program_checkbox" checked = {is_open_registration} onChange={(e)=>{changeRegistration(e)}}></input>
        </>
          </div>
        </div>
        {/* 1st Row */}
        <div className="d-flex flex-row gap-4">
          <div>
            <label htmlFor="exampleInput">Programme Available Date</label>

            <KeyboardDateTimePicker
              className="form-control"
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              value={data?.available_date}
              data-testid="available_date"
              onChange={(e)=>onChange(e,'available_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={initialAvailableDate}
            />
            {error && <span data-testid="available_date_error" className="requiredInput">{error}</span>}

          </div>
          <div>
            <label htmlFor="exampleInput">Programme Orientation Date</label>
            <KeyboardDateTimePicker
              className="form-control"
              // For test cases purpose added the conditonal format
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              data-testid="program_orientation_date"
              value={data?.program_orientation_date}
              onChange={(e) => onChange(e, 'program_orientation_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={data?.start_date}
            />
          </div>

          <div>
            <label htmlFor="exampleInput">Programme Start Date</label>

            <KeyboardDateTimePicker
              className="form-control"
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              data-testid="start_date"
              value={data?.start_date}
              onChange={(e)=>onChange(e,'start_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={initialStartDate}
            />
          </div>
          <div>
            <label htmlFor="exampleInput">Programme Soft Deadline</label>
            <KeyboardDateTimePicker
              className="form-control"
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              data-testid="end_date"
              value={data?.end_date}
              onChange={(e)=>onChange(e,'end_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={data?.start_date} 
            />
          </div>
          </div>
        {/* 2nd Row */}
        <div className="d-flex flex-row gap-4">
          <div className="programmerowinput">

            <label htmlFor="exampleInput">Programme Capacity <span className="requiredInput">*</span></label>
            <input
              type="number"
              className="form-control hide-spin"
              id="capacity"
              name="capacity"
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="Capacity"
              onChange={changeCapacity}
              onKeyDown={handleKeyDown}
              min="0"
              defaultValue={data.capacity}
            />
          </div>
          <div className="programmerowinput">
            <label htmlFor="ProgrammeeFee">Programme Fee (inc GST) <span className="requiredInput">*</span></label>
            <input
              type="number"
              className="form-control hide-spin"
              id="ProgrammeeFee"
              name="ProgrammeeFee"
              placeholder=" "
              min="0"
              role="presentation"
              autoComplete="off"
              data-testid="programme_fee"
              onChange={changeProgramCost}
              onKeyDown={handleKeyDown}
              defaultValue={data.program_cost}
            />
          </div>
          <div className={programmeType == cohort_based?"programmerowinput":"programmerowinput_standalone"}>
            <label htmlFor="Buffer_Days">Programme Buffer Days</label>
            <input
              type="number"
              className="form-control hide-spin"
              id="Buffer_Days"
              name="Buffer_Days"
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="Programme_buffer_days"
              onChange={progremmeBufferDaysHandler}
              value={data.course_buffer_time}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              min="0"
              defaultValue={data.course_buffer_time}
            />
          </div>
          {programmeType == standalone&&<>
          <div className="programmerowinput_courseduration_standalone">
            <label htmlFor="course_duration">Course Duration <span className="requiredInput">*</span></label>
            <input
              type="number"
              className="form-control hide-spin"
              id="course_duration"
              name="course_duration"
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="course_duration"
              onChange={changecourseDuration}
              value={data.course_duration}
              onKeyDown={handleKeyDown}
              min="0"
              defaultValue={data.course_duration}
            />
          </div>
          </>  
          }
          <div>
            <label htmlFor="exampleInput">Programme Hard Deadline</label>
            <KeyboardDatePicker
              className="form-control"
              format={currentPathName == "/courseslist" ? "MMM dd, yyyy hh:mm a" : "yyyy-MM-dd hh:mm a"}
              value={data?.program_hard_deadline}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              data-testid="programme_hard_deadline"
              minDate={data?.end_date}
              onChange={(e) => {handleProgrammeLevelHardDealine(e)}}
            />
          </div>
        </div>
        {/* 3rd Row */}
        <div className="d-flex flex-row gap-4">
        <div>
            <label htmlFor="exampleInput">Application Open Date <span className="requiredInput">*</span></label>

            <KeyboardDateTimePicker
              className="form-control"
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              // data-testid="start_date"
              value={data?.application_submission_start_date}
              onChange={(e)=>onChange(e,'application_submission_start_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={initialSubmissionStartDate}
              maxDate={data?.application_submission_end_date}
            />
          </div>
          <div>
            <label htmlFor="exampleInput">Application Close Date <span className="requiredInput">*</span></label>

            <KeyboardDateTimePicker
              className="form-control"
              format={currentPathName=="/courseslist"?"MMM dd, yyyy hh:mm a":"yyyy-MM-dd hh:mm a"}
              // data-testid="start_date"
              value={data?.application_submission_end_date}
              onChange={(e)=>onChange(e,'application_submission_end_date')}
              inputProps={{
                readOnly: true,
                style: { textAlign: 'center' }
              }}
              minDate={data?.application_submission_start_date}
              maxDate = {data?.end_date}
              error={false} // Disable default message
              helperText={null} // Disable default message
            />
             {applocationError && <span data-testid="available_date_error" className="requiredInput error-text-size">Application close date should not exceed pgm end date</span>}
          </div>
        </div>
        {/* 4th Row */}
        <div className="d-flex flex-row gap-4">
        {learning_track_selected == "false"  && <div>
             <label htmlFor="exampleInput">Programme Name <span className="requiredInput">*</span></label>
            <input type="text" className="form-control"  id="program_name" data-testid="programme_name" name="program_name"
            placeholder=" " defaultValue={data.program_name} onChange={changeProgramName}></input>
          </div>}
              <div className="programmerowinput" data-testid="Platform_available_on">
                <span className="d-none" data-testid="platform_test" onClick={(e)=>{handlePlatformSelectChange(platformOptionsdata)}}></span>
              <label htmlFor="exampleInput">Platform Available On <span className="requiredInput">*</span></label>
            <Controller
              as={Select}
              name="Platform"
              placeholder="Select Platform"
              options={platformOptionsdata}
              control={control}
              isMulti
              rules={{ required: true }}
              render={({ onChange, value, name }) => (
                <Select
                  onChange={handlePlatformSelectChange}
                  value={selectedPlatformOption}               
                  name={name}
                  options={platformOptionsdata}
                  isMulti
                  placeholder="Select Platform"
                  classNamePrefix="react-select-Student"
                />
              )}/>
              </div>
              <div className="programmerowinput">
                <label htmlFor="allowed_quiz_attempts">Quiz Redo Attempts <span className="requiredInput">*</span></label>
                <input
                  type="number"
                  className="form-control hide-spin"
                  id="allowed_quiz_attempts"
                  name="allowed_quiz_attempts"
                  placeholder=" "
                  role="presentation"
                  autoComplete="off"
                  data-testid="allowed_quiz_attempts"
                  onChange={changeAllowedQuizAttempts}
                  onKeyDown={handleKeyDown}
                  min="0"
                  defaultValue={data.allowed_quiz_attempts}
                />
              </div>
              <div className="programmerowinput">
                <label htmlFor="quiz_duration">Quiz Duration</label>
                <input
                  type="number"
                  className="form-control hide-spin"
                  id="quiz_duration"
                  name="quiz_duration"
                  placeholder=" "
                  role="presentation"
                  autoComplete="off"
                  data-testid="quiz_duration"
                  onChange={changeProgrammeLevelQuizDuration}
                  onKeyDown={handleKeyDown}
                  min="0"
                  defaultValue={data.quiz_duration}
                />
              </div>
        </div>
        {/* Right now we are not handling the features */}
        <div className="visually-hidden">Feature for this Programme</div>
        <div className="d-flex flex-row gap-3 visually-hidden">
          <div>
            <input type="checkbox" defaultChecked></input> Lessons
          </div>
          <div>
            <input type="checkbox" defaultChecked></input> Quizzes
          </div>
          <div>
            <input type="checkbox" defaultChecked></input> Social Learning
          </div>
        </div>
        
        {<><div>Visibility</div>
        <div className="d-flex flex-row gap-4">
     
            {visibility.map(item=>{
              return(
                <Fragment key={item.value}>
                  <div>
                    <input className="cursor-pointer" key={item.id} type="radio" data-testid={`visibility_radio_${item.value}`} value={item.id} checked={item.is_checked} onChange={e => changeValue(e)} name="Visibility"></input>
                    <span> {item.value}</span>
                  </div>
                </Fragment>
                
              )
            })}
         
        </div></>}

        <div className="d-flex flex-row justify-content-start">
          <div className="d-flex flex-row align-items-center">
            <label htmlFor="Badge_Bonus" className="bonus_badge_label">Badge Bonus</label>
            <input
              type="number"
              className="form-control bonus_badge_input hide-spin"
              id="Badge_Bonus"
              name="Badge_Bonus"
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="Badge_Bonus"
              value={data.badge_bonus_points}
              onChange={changeBadgeBonus}
              onKeyDown={handleKeyDown}
              onBlur={updateBadgeBonus}
              min="0"
              defaultValue={data.badge_bonus_points}
            />
          </div>
          <div className="d-flex flex-row align-items-center">
            <label htmlFor="SLA_ITA_Attendance"  className="ITA_attendance_label">SLA/ITA Attendance</label>
            <input
              type="number"
              className="form-control ITA_attendance_input hide-spin"
              id="SLA_ITA_Attendance"
              name="SLA_ITA_Attendance"
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="SLA_ITA_Attendance"
              onChange={changeSLAITAAttendance}
              onBlur={changeSLAITAAttendanceOnBlur}
              onKeyDown={handleKeyDown}
              min="0"
              value={data.sla_ita_attendance_points}
            />
          </div>
        </div>
        {/* points system */}
        <div className="points_system_main">
          <div className="points_system_inner_one">
            <div className="text-center points_system_inner_two">
              <span>Points System</span>
            </div>
            <div>
            <div className="table-responsive">
              <table className="table mb-0 programme_points_system_table">
                <thead>
                  <tr>
                    <th scope="col"><span className="visible-hidden">Descriptiontestte</span></th>
                    <th scope="col"><span className="d-flex">Attempt <span className="visible-hidden">/</span></span></th>
                    <th scope="col">Accuracy</th>
                    <th scope="col">Mastery/ Bonus</th>
                  </tr>
                </thead>
                <br />
                <tbody>
                  {data.points_policy && data.points_policy.length>0 && data.points_policy.map((rowItem,rowIndex)=>(
                      <tr key={rowIndex}>
                      <th scope="row"><div className="mb-3 mt-2">{rowItem.points_type}</div></th>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control points_system_input hide-spin"
                            id={rowItem.attempt+"_"+rowIndex}
                            name={rowItem.attempt+"_"+rowIndex}
                            placeholder=" "
                            role="presentation"
                            autoComplete="off"
                            data-testid={`ProgrammePointssystem_attempt_${rowIndex}`}
                            value={rowItem.attempt}
                            onChange={(e)=>{changeProgrammeLevelPointsSystem(e,rowIndex,"attempt",rowItem)}}
                            onBlur={(e)=>{updateProgrammeLevelPointsSystemOnBlur(e,rowIndex,"attempt",rowItem)}}
                            onKeyDown={handleKeyDown}
                            min="0"
                            defaultValue={rowItem.attempt}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                        <input
                            type="number"
                            className="form-control points_system_input hide-spin"
                            id={rowItem.accuracy+"_"+rowIndex}
                            name={rowItem.accuracy+"_"+rowIndex}
                            placeholder=" "
                            role="presentation"
                            autoComplete="off"
                            value={rowItem.accuracy}
                            data-testid={`ProgrammePointssystem_accuracy_${rowIndex}`}
                            onChange={(e)=>{changeProgrammeLevelPointsSystem(e,rowIndex,"accuracy",rowItem)}}
                            onBlur={(e)=>{updateProgrammeLevelPointsSystemOnBlur(e,rowIndex,"accuracy",rowItem)}}
                            onKeyDown={handleKeyDown}
                            min="0"
                            defaultValue={rowItem.accuracy}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                        <input
                            type="number"
                            className="form-control points_system_input hide-spin"
                            id={rowItem.mastery+"_"+rowIndex}
                            name={rowItem.mastery+"_"+rowIndex}
                            placeholder=" "
                            role="presentation"
                            autoComplete="off"
                            value={rowItem.mastery}
                            data-testid={`ProgrammePointssystem_mastery_bonus_${rowIndex}`}
                            onChange={(e)=>{changeProgrammeLevelPointsSystem(e,rowIndex,"mastery",rowItem)}}
                            onBlur={(e)=>{updateProgrammeLevelPointsSystemOnBlur(e,rowIndex,"mastery",rowItem)}}
                            onKeyDown={handleKeyDown}
                            min="0"
                            defaultValue={rowItem.mastery}
                          />
                        </div>
                      </td>
                      </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="story">Notes</label>
          <textarea
            name="internal_notes"
            id="internal_notes"
             className="textarea_class"
            rows="3"
            cols="1"
            data-testid="Notes_box"
            onChange={changeNotes}
            defaultValue={data?.internal_notes}
          ></textarea>
        </div>
      </div>
      <div className="bg-body container mt-4 mb-2 gap-4 course_list_container">
        <div>
          <Accordion className="course_level_accoridion" defaultActiveKey={['0']} alwaysOpen>
            {data.courses&&data.courses.map((course, courseIndex) =>(
              <>
                <Accordion.Item key={courseIndex} eventKey={courseIndex}>
                  <Accordion.Header>
                    <div>
                      <div>
                        <span className="course_heading">{course.course_name}</span>
                      </div>
                      <div onClick={handleInputEventBubbling}>
                        <div className="d-flex felx-row justify-content-between">
                          <div className="d-flex flex-row gap-4">
                            <div>
                              <label htmlFor="exampleInput"> Soft Deadline </label>
                              <OverlayTrigger placement="top" overlay={
                                <Tooltip className="programmeInfo_tooltip"
                                  id={"registerTip0"}>
                                  <span>The final module's soft deadline in the
                                  </span><br />
                                  <span>course is automatically populated to </span><br />
                                  <span> the course soft deadline.</span>
                                </Tooltip>} offset={[0, 10]}>
                                <span className="cursor-pointer info-icon"> ⓘ</span>
                              </OverlayTrigger>
                              <KeyboardDateTimePicker
                                className="form-control accordion_input"
                                format={currentPathName == "/courseslist" ? "MMM dd, yyyy hh:mm a" : "yyyy-MM-dd hh:mm a"}
                                data-testid={`Course_Soft_Deadline_${courseIndex}`}
                                value={course.Course_Soft_Deadline}
                                inputProps={{
                                  readOnly: true,
                                  style: { textAlign: 'center' }
                                }}
                                disabled
                              />
                            </div>
                            <div>
                              <label htmlFor=""> Buffer Days </label>
                              <OverlayTrigger placement="top" overlay={
                                <Tooltip className="programmeInfo_tooltip"
                                  id={"registerTip0"}>
                                  <span>Buffer days are extra time set aside in
                                  </span><br />
                                  <span>a course schedule to account for </span><br />
                                  <span>potential delays</span>
                                </Tooltip>} offset={[0, 10]}>
                                <span className="cursor-pointer info-icon"> ⓘ</span>
                              </OverlayTrigger>
                              <input
                                type="number"
                                className="form-control accordion_input hide-spin"
                                id={`Course_Buffer_Days_${courseIndex}`}
                                name={`Course_Buffer_Days_${courseIndex}`}
                                placeholder=" "
                                role="presentation"
                                autoComplete="off"
                                value={course.buffer_days}
                                data-testid={`Course_Buffer_Days_${courseIndex}`}
                                onChange={(e) => courseLevelBufferHandler(e, courseIndex, 'Course_Buffer_Days')}
                                onKeyDown={handleKeyDown}
                                min="0"
                                defaultValue={course.buffer_days}
                              />
                            </div>
                            <div>
                              <label htmlFor=""> Hard Deadline</label>
                              <OverlayTrigger placement="top" overlay={
                                <Tooltip className="programmeInfo_tooltip"
                                  id={"registerTip0"}>
                                  <span>A soft deadline, along with buffer days,
                                  </span><br />
                                  <span>equals a hard deadline.</span>
                                </Tooltip>} offset={[0, 10]}>
                                <span className="cursor-pointer info-icon"> ⓘ</span>
                              </OverlayTrigger>
                              <KeyboardDatePicker
                                className="form-control accordion_input"
                                format={currentPathName == "/courseslist" ? "MMM dd, yyyy hh:mm a" : "yyyy-MM-dd hh:mm a"}
                                value={course?.courseLevelHarddeadline}
                                inputProps={{
                                  readOnly: true,
                                  style: { textAlign: 'center' }
                                }}
                                data-testid={`course_hard_deadline_${courseIndex}`}
                                minDate={course.Course_Soft_Deadline}
                                onChange={(e) => handleCourseLevelHardDealine(e, courseIndex)}
                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </Accordion.Header>
                <Accordion.Body className="course_level_accordion_body">
                    <div>
                      {/* course Level points system */}
                      <div className="course_level_head">
                        <div className="mb-4">
                          <span className="course_points_sys_heading">Course Level Point System</span>
                        </div>
                        <div className="d-flex flex-row justify-content-start mb-4">
                          <div className="d-flex flex-row align-items-center">
                            <label htmlFor="Badge_Bonus" className="bonus_badge_label">Badge Bonus</label>
                            <input
                              type="number"
                              className="form-control bonus_badge_input hide-spin"
                              id="Badge_Bonus"
                              name="Badge_Bonus"
                              placeholder=" "
                              role="presentation"
                              autoComplete="off"
                              value={course.badge_bonus_points}
                              data-testid={`Course_Badge_Bonus_${courseIndex}`}
                              onChange={(e)=>{changeCourseLevelBadgeBonus(e,courseIndex)}}
                              onBlur={(e)=>{changeCourseLevelBadgeBonusOnBlur(e,courseIndex)}}
                              onKeyDown={handleKeyDown}
                              min="0"
                              defaultValue={course.badge_bonus_points}
                            />
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <label htmlFor="SLA_ITA_Attendance" className="ITA_attendance_label">SLA/ITA Attendance</label>
                            <input
                              type="number"
                              className="form-control ITA_attendance_input hide-spin"
                              id="SLA_ITA_Attendance"
                              name="SLA_ITA_Attendance"
                              placeholder=" "
                              role="presentation"
                              autoComplete="off"
                              data-testid={`Course_SLA_ITA_Attendance_${courseIndex}`}
                              onChange={(e)=>{changeCourseLevelSLAITAAttendance(e,courseIndex)}}
                              onBlur={(e)=>{changeCourseLevelSLAITAAttendanceOnBlur(e,courseIndex)}}
                              onKeyDown={handleKeyDown}
                              min="0"
                              value={course.sla_ita_attendance_points}
                            />
                          </div>
                        </div>
                        <div className="points_system_main">
                          <div className="points_system_inner_one">
                            <div className="text-center points_system_inner_two">
                              <span>Points System</span>
                            </div>
                            <div>
                              <div className="table-responsive">
                                <table className="table mb-0 programme_points_system_table">
                                  <thead>
                                    <tr>
                                      <th scope="col"><span className="visible-hidden">Descriptiontestte</span></th>
                                      <th scope="col"><span className="d-flex">Attempt <span className="visible-hidden">/</span></span></th>
                                      <th scope="col">Accuracy</th>
                                      <th scope="col">Mastery/ Bonus</th>
                                    </tr>
                                  </thead>
                                  <br />
                                  <tbody>
                                    {course.points_policy && course.points_policy.length>0 && course.points_policy.map((rowItem, rowIndex) => (
                                      <tr key={rowIndex}>
                                        <th scope="row"><div className="mb-3 mt-2">{rowItem.points_type}</div></th>
                                        <td>
                                          <div>
                                            <input
                                              type="number"
                                              className="form-control points_system_input hide-spin"
                                              id={rowItem.attempt + "_" + rowIndex}
                                              name={rowItem.attempt + "_" + rowIndex}
                                              placeholder=" "
                                              role="presentation"
                                              autoComplete="off"
                                              data-testid={`coursePointssystem_attempt_${courseIndex}_${rowIndex}`}
                                              value={rowItem.attempt}
                                              onChange={(e)=>{changeCourseLevelPointsSystem(e,courseIndex,rowIndex,"attempt",rowItem)}}
                                              onBlur={(e)=>{updateCourseLevelPointsSystemOnBlur(e,courseIndex,rowIndex,"attempt",rowItem)}}
                                              onKeyDown={handleKeyDown}
                                              min="0"
                                              defaultValue={rowItem.attempt}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            <input
                                              type="number"
                                              className="form-control points_system_input hide-spin"
                                              id={rowItem.accuracy + "_" + rowIndex}
                                              name={rowItem.accuracy + "_" + rowIndex}
                                              placeholder=" "
                                              role="presentation"
                                              autoComplete="off"
                                              data-testid={`coursePointssystem_accuracy_${courseIndex}_${rowIndex}`}
                                              onChange={(e)=>{changeCourseLevelPointsSystem(e,courseIndex,rowIndex,"accuracy",rowItem)}}
                                              onBlur={(e)=>{updateCourseLevelPointsSystemOnBlur(e,courseIndex,rowIndex,"accuracy",rowItem)}}
                                              onKeyDown={handleKeyDown}
                                              value={rowItem.accuracy}
                                              min="0"
                                              defaultValue={rowItem.accuracy}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            <input
                                              type="number"
                                              className="form-control points_system_input hide-spin"
                                              id={rowItem.mastery + "_" + rowIndex}
                                              name={rowItem.mastery + "_" + rowIndex}
                                              placeholder=" "
                                              role="presentation"
                                              autoComplete="off"
                                              data-testid={`coursePointssystem_mastery_bonus_${courseIndex}_${rowIndex}`}
                                              onChange={(e)=>{changeCourseLevelPointsSystem(e,courseIndex,rowIndex,"mastery",rowItem)}}
                                              onBlur={(e)=>{updateCourseLevelPointsSystemOnBlur(e,courseIndex,rowIndex,"mastery",rowItem)}}
                                              onKeyDown={handleKeyDown}
                                              min="0"
                                              value={rowItem.mastery}
                                              defaultValue={rowItem.mastery}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* module level accordion */}
                      <Accordion className="module_level_accoridion mt-4" defaultActiveKey={['0']} alwaysOpen>
                        {course.modules&&course.modules.map((module, moduleIndex) => (
                          <>
                            <Accordion.Item key={moduleIndex} eventKey={moduleIndex}>
                              <Accordion.Header>
                                <div>
                                  <div>
                                    <span className="module_heading"> Module {moduleIndex} - {module.module_name}</span>
                                  </div>
                                  <div onClick={handleInputEventBubbling}>
                                    <div className="d-flex flex-row gap-3 m-0">
                                      {programmeType == cohort_based &&
                                        <>
                                          <div>
                                            <label htmlFor="exampleInput"> Unlock Date</label>
                                            <KeyboardDateTimePicker
                                              className="form-control accordion_input"
                                              format={currentPathName == "/courseslist" ? "MMM dd, yyyy hh:mm a" : "yyyy-MM-dd hh:mm a"}
                                              data-testid={`module_unlockDate_${courseIndex}_${moduleIndex}`}
                                              value={module.start_date}
                                              onChange={(e) => onChangeDate(e, module.module_id, 'start_date', courseIndex, moduleIndex)}
                                              inputProps={{
                                                readOnly: true,
                                                style: { textAlign: 'center' }
                                              }}
                                              minDate={data?.start_date}
                                            />
                                            {(course.start_date > course.end_date)  && (moduleIndex == 0) && <>
                                            <p class="MuiFormHelperText-root1">Course start date should be less than end date..!</p>
                                            </>}

                                            {(module.start_date > course.end_date)  && (moduleIndex !== 0) && <>
                                            <p class="MuiFormHelperText-root1 ">Module start date should be less than Course end date..!</p>
                                            </>}
                                          
                                          </div>
                                     

                                          <div>
                                            <label htmlFor="exampleInput"> Soft Deadline</label>
                                            <KeyboardDateTimePicker
                                              className="form-control accordion_input"
                                              format={currentPathName == "/courseslist" ? "MMM dd, yyyy hh:mm a" : "yyyy-MM-dd hh:mm a"}
                                              value={module.end_date}
                                              data-testid={`module_softDeadline_${courseIndex}_${moduleIndex}`}
                                              onChange={(e) => onChangeDate(e, module.module_id, 'end_date', courseIndex, moduleIndex)}
                                              inputProps={{
                                                readOnly: true,
                                                style: { textAlign: 'center' }
                                              }}
                                              minDate={module.start_date}
                                            />
                                            {(course.end_date < course.start_date) && (course?.modules.length - 1 == moduleIndex) && <>
                                            <p class="MuiFormHelperText-root1 ">Course end date should be greater than start date..!</p>
                                            </>}

                                            {(module.end_date > course.end_date) && (course?.modules.length - 1 !== moduleIndex) && <>
                                            <p class="MuiFormHelperText-root1 ">Module end date should be less than course end date..!</p>
                                            </>}
                                          </div>
                                        </>
                                      }
                                      {moduleIndex != 0 && (<>
                                        {programmeType == cohort_based
                                          ?
                                          <div className="d-flex flex-column justify-content-start">
                                            <label htmlFor="exampleInput">Is Timed Quiz</label>
                                            <input type="checkbox" className="program_checkbox accordion_input" data-testid={`Is_Timed_Quiz_${courseIndex}_${moduleIndex}`} checked={module.is_checked} onChange={(e) => checkboxChange(e, courseIndex, moduleIndex)}></input>
                                          </div >
                                          : <div className="d-flex flex-column justify-content-start">
                                            <label htmlFor="exampleInput">Is Module Quiz Timed?</label>
                                            <input
                                              type="checkbox"
                                              className="program_checkbox accordion_input"
                                              checked={module.is_checked}
                                              onChange={(e) => checkboxChange(e, courseIndex, moduleIndex)}
                                            />
                                          </div>}


                                        {module.is_checked && <>
                                          <div className={programmeType == cohort_based?"quiz_duration":"quiz_duration_width"}>
                                            <label htmlFor="exampleInput">Quiz Duration</label>
                                            <input type="text" autoFocus min='5' max='180' data-testid={`quiz_duration_${courseIndex}_${moduleIndex}`} className="form-control quiz_duration_input accordion_input" value={module.quiz_duration} onChange={(e) => onTimeEdit(e, courseIndex, moduleIndex)}></input>
                                          </div>
                                        </>
                                        }
                                          <div>
                                            <label htmlFor="exampleInput">No of Redo Attempts</label>
                                            <input type="text" min='5' max='180' data-testid={`redo_attempts_${courseIndex}_${moduleIndex}`} className="form-control redo_attempts_input mb-0 accordion_input" value={module.allowed_quiz_attempts} onChange={(e) => onRedoAttempt(e, courseIndex, moduleIndex)}></input>
                                          </div>
                                      </>)}
                                    </div>
                                  </div>
                                </div>

                              </Accordion.Header>
                              <Accordion.Body className="module_level_accordion_body">
                                <div>
                                  {/* course Level points system */}
                                  <div className="course_level_head">
                                    <div className="mb-4">
                                      <span className="course_points_sys_heading">Module Level Point System</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start mb-4">
                                      <div className="d-flex flex-row align-items-center">
                                        <label htmlFor="Badge_Bonus" className="bonus_badge_label">Badge Bonus</label>
                                        <input
                                          type="number"
                                          className="form-control bonus_badge_input hide-spin"
                                          id="Badge_Bonus"
                                          name="Badge_Bonus"
                                          placeholder=" "
                                          role="presentation"
                                          autoComplete="off"
                                          value={module.badge_bonus_points}
                                          data-testid={`Module_Badge_Bonus_${courseIndex}_${moduleIndex}`}
                                          onChange={(e)=>{changeModuleLevelBadgeBonus(e,courseIndex,moduleIndex)}}
                                          onBlur={(e)=>{changeModuleLevelBadgeBonusOnBlur(e,courseIndex,moduleIndex)}}
                                          onKeyDown={handleKeyDown}
                                          min="0"
                                          defaultValue={module.badge_bonus_points}
                                        />
                                      </div>
                                      <div className="d-flex flex-row align-items-center">
                                        <label htmlFor="SLA_ITA_Attendance" className="ITA_attendance_label">SLA/ITA Attendance</label>
                                        <input
                                          type="number"
                                          className="form-control ITA_attendance_input hide-spin"
                                          id="SLA_ITA_Attendance"
                                          name="SLA_ITA_Attendance"
                                          placeholder=" "
                                          role="presentation"
                                          autoComplete="off"
                                          data-testid={`Module_SLA_ITA_Attendance_${courseIndex}_${moduleIndex}`}
                                          onChange={(e)=>{changeModuleLevelSLAITAAttendance(e,courseIndex,moduleIndex)}}
                                          onBlur={(e)=>{changeModuleLevelSLAITAAttendanceOnBlur(e,courseIndex,moduleIndex)}}
                                          onKeyDown={handleKeyDown}
                                          min="0"
                                          value={module.sla_ita_attendance_points}
                                        />
                                      </div>
                                    </div>
                                    <div className="points_system_main">
                                      <div className="points_system_inner_one">
                                        <div className="text-center points_system_inner_two">
                                          <span>Points System</span>
                                        </div>
                                        <div>
                                          <div className="table-responsive">
                                            <table className="table mb-0 programme_points_system_table">
                                              <thead>
                                                <tr>
                                                  <th scope="col"><span className="visible-hidden">Descriptiontestte</span></th>
                                                  <th scope="col"><span className="d-flex">Attempt <span className="visible-hidden">/</span></span></th>
                                                  <th scope="col">Accuracy</th>
                                                  <th scope="col">Mastery/ Bonus</th>
                                                </tr>
                                              </thead>
                                              <br />
                                              <tbody>
                                                {module.points_policy && module.points_policy.length>0 && module.points_policy.map((rowItem, rowIndex) => (
                                                  <tr key={rowIndex}>
                                                    <th scope="row"><div className="mb-3 mt-2">{rowItem.points_type}</div></th>
                                                    <td>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="form-control points_system_input hide-spin"
                                                          id={rowItem.attempt + "_" + rowIndex}
                                                          name={rowItem.attempt + "_" + rowIndex}
                                                          placeholder=" "
                                                          role="presentation"
                                                          autoComplete="off"
                                                          data-testid={`ModulePointssystem_attempt_${courseIndex}_${moduleIndex}_${rowIndex}`}
                                                          onChange={(e)=>{changeModuleLevelPointsSystem(e,courseIndex,moduleIndex,rowIndex,"attempt",rowItem)}}
                                                          onBlur={(e)=>{updateModuleLevelPointsSystemOnBlur(e,courseIndex,moduleIndex,rowIndex,"attempt",rowItem)}}
                                                          onKeyDown={handleKeyDown}
                                                          min="0"
                                                          value={rowItem.attempt}
                                                          defaultValue={rowItem.attempt}
                                                        />
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="form-control points_system_input hide-spin"
                                                          id={rowItem.accuracy + "_" + rowIndex}
                                                          name={rowItem.accuracy + "_" + rowIndex}
                                                          placeholder=" "
                                                          role="presentation"
                                                          autoComplete="off"
                                                          data-testid={`ModulePointssystem_accuracy_${courseIndex}_${moduleIndex}_${rowIndex}`}
                                                          onChange={(e)=>{changeModuleLevelPointsSystem(e,courseIndex,moduleIndex,rowIndex,"accuracy",rowItem)}}
                                                          onBlur={(e)=>{updateModuleLevelPointsSystemOnBlur(e,courseIndex,moduleIndex,rowIndex,"accuracy",rowItem)}}
                                                          onKeyDown={handleKeyDown}
                                                          min="0"
                                                          value={rowItem.accuracy}
                                                          defaultValue={rowItem.accuracy}
                                                        />
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <input
                                                          type="number"
                                                          className="form-control points_system_input hide-spin"
                                                          id={rowItem.mastery + "_" + rowIndex}
                                                          name={rowItem.mastery + "_" + rowIndex}
                                                          placeholder=" "
                                                          role="presentation"
                                                          autoComplete="off"
                                                          data-testid={`ModulePointssystem_mastery_bonus_${courseIndex}_${moduleIndex}_${rowIndex}`}
                                                          onChange={(e)=>{changeModuleLevelPointsSystem(e,courseIndex,moduleIndex,rowIndex,"mastery",rowItem)}}
                                                          onBlur={(e)=>{updateModuleLevelPointsSystemOnBlur(e,courseIndex,moduleIndex,rowIndex,"mastery",rowItem)}}
                                                          onKeyDown={handleKeyDown}
                                                          min="0"
                                                          value={rowItem.mastery}
                                                          defaultValue={rowItem.mastery}
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* lesson Level accordion */}
                                  <div>
                                    <Accordion className="lesson_level_accoridion mt-4" defaultActiveKey={['0']} alwaysOpen>
                                      {module.lessons&&module.lessons.map((lesson, lessonIndex) => (
                                        <>
                                          <Accordion.Item key={lessonIndex} eventKey={lessonIndex}>
                                            <Accordion.Header>
                                              <div>
                                                <div>
                                                  <span className="lesson_heading"> Lesson {lessonIndex} - {lesson.lesson_title}</span>
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="lesson_level_accordion_body">
                                              <div>
                                                {lesson.activities_data && lesson.activities_data.map((activity, activityIndex) => (
                                                  <>
                                                    <div className="Lesson_activity_parent pb-2" key={activityIndex}>
                                                      <div className="Lesson_activity_main">
                                                        <div><span className="Lesson_activity_name">{activity.activity_type}</span></div>
                                                        <div>
                                                          <img onClick={(e) => { handleExpandLessonActivity(courseIndex, moduleIndex, lessonIndex, activityIndex) }}
                                                            className="lesson_activty_img"
                                                            data-testid={`activity_expand_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                            src="./images/Table.svg"
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className={activity.showActivtityInfo ? 'Lesson_activity_content_main' : 'd-none'}>
                                                        <div className="Lesson_activity_content">
                                                          <div><span className="Lesson_activity_name">{activity.activity_type}</span></div>
                                                          <div><span className="Lesson_activity_question">{activity.activity}</span></div>
                                                        </div>
                                                        <div className="lesson_input_main">
                                                          <div className="lesson_inputs_inner">
                                                            <div>
                                                              <label htmlFor=""> Attempt </label>
                                                              <input
                                                                type="number"
                                                                className="form-control mb-0 hide-spin"
                                                                id={`Activity_Attempt_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                name={`Activity_Attempt_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                role="presentation"
                                                                autoComplete="off"
                                                                data-testid={`Activity_Attempt_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                onChange={(e) => lessonLevelActivityInputs(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'attempt')}
                                                                onBlur={(e)=>{updatelessonLevelActivityInputsOnBlur(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'attempt')}}
                                                                onKeyDown={handleKeyDown}
                                                                min="0"
                                                                value={data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy']['attempt']}
                                                                // defaultValue={activity.points_policy.attempt}
                                                              />
                                                            </div>

                                                            <div>
                                                              <label htmlFor=""> Accuracy </label>
                                                              <input
                                                                type="number"
                                                                className="form-control mb-0 hide-spin"
                                                                id={`Activity_Accuracy_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                name={`Activity_Accuracy_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                role="presentation"
                                                                autoComplete="off"
                                                                data-testid={`Activity_Accuracy_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                onChange={(e) => lessonLevelActivityInputs(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'accuracy')}
                                                                onBlur={(e)=>{updatelessonLevelActivityInputsOnBlur(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'accuracy')}}
                                                                onKeyDown={handleKeyDown}
                                                                min="0"
                                                                value={data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy']['accuracy']}
                                                                // defaultValue={activity.points_policy.accuracy}
                                                              />
                                                            </div>

                                                            <div>
                                                              <label htmlFor=""> Mastery </label>
                                                              <input
                                                                type="number"
                                                                className="form-control mb-0 hide-spin"
                                                                id={`Activity_Mastery_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                name={`Activity_Mastery_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                role="presentation"
                                                                autoComplete="off"
                                                                data-testid={`Activity_Mastery_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                                onChange={(e) => lessonLevelActivityInputs(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'mastery')}
                                                                onBlur={(e)=>{updatelessonLevelActivityInputsOnBlur(e, courseIndex,moduleIndex,lessonIndex,activityIndex, 'mastery')}}
                                                                onKeyDown={handleKeyDown}
                                                                min="0"
                                                                value={data.courses[courseIndex]['modules'][moduleIndex]['lessons'][lessonIndex]['activities_data'][activityIndex]['points_policy']['mastery']}
                                                                // defaultValue={activity.points_policy.mastery}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div>
                                                            <img onClick={(e) => { handleExpandLessonActivityClose(courseIndex, moduleIndex, lessonIndex, activityIndex) }}
                                                              data-testid={`activity_expand_close_${courseIndex}_${moduleIndex}_${lessonIndex}_${activityIndex}`}
                                                              className="lesson_activty_close_img"
                                                              src="./images/Close_circle.png"
                                                              alt=""
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>))}
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <div className="accordion_lesson_gap"><span class="visible-hidden">...</span></div>
                                        </>
                                      ))}
                                    </Accordion>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <div className="accordion_module_gap"><span class="visible-hidden">...</span></div>
                          </>
                        ))}
                      </Accordion>
                    </div>
                </Accordion.Body>
              </Accordion.Item>
              <div className="accordion_gap"><span class="visible-hidden">...</span></div>
            </>
            ))}
          </Accordion>
        </div>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={handleClose}  className="programme_setup_dialog  popup_bgcolor" backdrop="static">
          <Modal.Header >
          </Modal.Header>
          <Modal.Body className='pt-0 mt-4'>
            <div className="text-center">
              <span className="text-center popup_heading">Confirm Message!</span>
            </div>
            <div className="text-center mt-2 mb-3">
              <span>
              Are you sure you want to make changes that could potentially impact the end user's experience? Is that acceptable to you?
              </span>
            </div>
            <div className="d-flex justify-content-center gap-3 mb-1">
              <button className="btn bordered_btn" data-testid="programme_warning_cancel" onClick={handledialogCancel}>Cancel</button>
              <button className="btn my_btn_cls popup_confirm_btn" data-testid="programme_warning_confirm" onClick={handledialogConfirm}>Ok</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>}
    </>)
};

export default withMaintenanceCheck(CourseList,admin_maintenance_type) 