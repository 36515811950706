import { React, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ObjectiveComponent } from "./objectivesComponent";
import "../../homePageStyle.css";
import { ContentPage } from "./contentpage";
import axios from "../../axios-interceptor";
import {base_url,role_obj,formatSentence,setUrl,storefront_url,default_dial_code,api_error_message,random_number,user_maintenance_type,} from "../../utilities";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { useParams } from "react-router-dom";
const FreeLessonPageBD = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { course_id } = useParams();
  let [track_name, setTrackName] = useState("");
  let [progress_element, setProgressElement] = useState(0);
  const [model_array, setPage] = useState([]);
  const [lessons_data, setLessonData] = useState([]);
  const [complete_lesson, setCompleteLesson] = useState(false);
  var [show_next, setShowNext] = useState("");
  const [lesson_name, setLessonName] = useState("");
  let [role, setRole] = useState();
  const queryParams = new URLSearchParams(location.search);
  const supportEmail = queryParams.get('support_email');

  useEffect(() => {
    const topNavBar=document.getElementById("top-nav-bar");
    if(topNavBar)
    {
      topNavBar.style.display = "none";
    }
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
    if (course_id) {
      localStorage.setItem("free_course_id", course_id);
    }
    else{
      navToInvalidPage()
    }
    setUrl();
    localStorage.setItem("free_lesson_url", true);
    getFreeLessonData();
  }, []);

  const handleCallback = (data) => {
    setShowNext(data);
  };

  const getFreeLessonData = () => {
    axios
      .post(base_url + "lessons/get_free_lesson_details/course", {
        course_id: localStorage.getItem("free_course_id"),
      })
      .then((res) => {

        if(res.data.message)
        {
          // "There is no free lesson for this course"
          navToInvalidPage()
        }
        else{
          localStorage.removeItem("free_lesson_url");
          localStorage.removeItem("prev_url");
          setLessonName(res.data.result.title);
          setPage(res.data.result.content.model);
          if (
            res.data.result.content.model[0].children[
              res.data.result.content.model[0].children?.length - 1
            ]?.activity_id
          ) {
            setShowNext("");
          } else {
            setShowNext("false");
          }
          setLessonData((current) => [
            ...current,
            <>
              <ContentPage
                parentCallback={handleCallback}
                data={res.data.result.content.model[0]}
              ></ContentPage>
              <p className="mb-0">
                <strong className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
                  Section {progress_element + 1} of{" "}
                  {res.data.result.content.model.length}
                </strong>{" "}
              </p>
            </>,
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized"||err.response?.data?.statusText == "Unauthorized") {
          navToInvalidPage()
        } else{
          navToInvalidPage()
        }
      });
  };
  const navToInvalidPage=()=>
  {
    history.push("/invalidfreelessonlink");
  }
  const pauseVideoRoll = (id) => {
    var iframes = document.getElementsByClassName("panaptoVideo");
    if (iframes.length !== null) {
      Array.from(iframes).map((item) => {
        var iframeSrc = item.src;
        item.src = iframeSrc;
      });
    }
  };

  const showNextElement = (item) => {
    pauseVideoRoll();
    if (model_array[item + 1]) {
      if (
        model_array[item + 1].children[
          model_array[item + 1].children?.length - 1
        ]?.activity_id
      ) {
        setShowNext("");
      } else {
        setShowNext("false");
      }
    }


    setProgressElement(item + 1);
    if (progress_element < model_array.length - 1) {
      setLessonData((current) => [
        ...current,
        <>
          <ContentPage
            parentCallback={handleCallback}
            data={model_array[item + 1]}
          ></ContentPage>
          <p className="mb-0">
            {" "}
            <strong className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
              Section {progress_element + 2} of {model_array.length}
            </strong>
          </p>
        </>,
      ]);
    } else {
      setCompleteLesson(true);
      showNextElementScroll();
      setProgressElement(0);
    }
  };
  const showNextElementScroll = () => {
    setTimeout(() => {
      window.scrollTo({
        top: window.scrollY + window.innerHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  };

  return (
    <>
      {" "}
      {course_id == localStorage.getItem("free_course_id") && (
        <div className="lessonpage">
          <div className="">
            {lessons_data}
            {complete_lesson && (
              <div className="container myContainerCls mt-5">
                <div>
                  <div className="my_alum_container p-5 col-12 custm_shadow_cls bg-body">
                    <div className="col-12">
                      <div className="d-flex flex-column">
                        <div className="alum_msg_container">
                          <div className="row">
                            <div className="my_alum_main_head">
                                <span className="alum-head">
                                  You've reached the end of this free lesson
                                </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12 ml-3 ml-sm-0">
                              <div>
                                <span className="img-fluid d-block mx-auto alum_image"></span>
                              </div>
                            </div>
                            <div className="col-lg-9 col-sm-12 col-md-8">
                              <div className="d-flex flex-column flex-nowrap">
                                  <span className="alum_text_bd">
                                  This lesson was a short preview of the
                                  comprehensive course.
                                </span>
                                  <span className="alum_text_bd">
                                    <br />
                                    Empower your team with cutting-edge business
                                    education and drive success. Explore our
                                    platform further to know more about our
                                    learning experience.
                                  </span>
                                  <span className="alum_text_bd">
                                    <br />
                                    Contact us at 
                                    <span className="alum_mail">
                                      {supportEmail ? (
                                        <span>
                                          <a className="freelessonbdlink_contactuslink" href={`mailto:${supportEmail}`}>
                                            {supportEmail}
                                          </a>
                                        </span>
                                      ) : (
                                        <span>
                                          <a className="freelessonbdlink_contactuslink" href="mailto:customexed@isb.edu">
                                            customexed@isb.edu
                                          </a>
                                        </span>
                                      )}
                                    </span>
                                  </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br></br>
          {!complete_lesson && (
            <div className="navigation d-flex justify-content-center">
              {lessons_data.length != 0 && (
                <>
                  <button
                    className="btn btn-lg my_btn_cls shadow border border-light border-4 rounded-circle" data-testid="Show_next_btn"
                    onClick={() => showNextElement(progress_element)}
                    disabled={show_next == "" ? true : false}
                  >
                    <img
                      className="bg-transparent"
                      src="/images/downarrow.png"
                      alt=""
                    />
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default withMaintenanceCheck(FreeLessonPageBD, user_maintenance_type);
