import { useLayoutEffect, React, useState, useEffect, useRef,Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ObjectiveComponent } from "./objectivesComponent";
import "../../homePageStyle.css";
import { ContentPage } from "./contentpage";
import axios from "../../axios-interceptor";
import { base_url,date_formats,random_number,api_error_message, social_learning_activity, quiz_name, quiz_max_attempts, unlimited_text, summary_name, user_maintenance_type } from "../../utilities";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import Stepperprogress from "./progressStepper";
import Congratulationsscreeen from "./congratulations"
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
const LessonPage = () => {
  const [Count, setCount] = useState(0);
  const [height, setHeight] = useState();
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const item = location.state?.id;
  const module_id = location.state?.moduleId;
  const module_lessons_completed = location.state?.lessons_completed;
  const except_first_last_modules = location.state?.except_first_last_modules;
  let quiz_review = location.state?.review_selected;//undefined
  if(module_id != undefined || item != undefined || module_lessons_completed != undefined || location.state?.index != undefined || location.state?.courseId != undefined){
    localStorage.setItem('moduleId',location.state?.moduleId)
    localStorage.setItem('id',JSON.stringify(location.state?.id))
    localStorage.setItem('lessons_completed',location.state?.lessons_completed)
    localStorage.setItem('index',location.state?.index)
    localStorage.setItem('course_id',location.state?.courseId)
    localStorage.setItem('cohort_id',location.state?.cohort_id)
    localStorage.setItem('lessonId',location.state?.lesson_id)
  }
  const module_end_date = location.state?.module_end_date;
  const [course_title, setCourseTitle] = useState("");
  var [modules, setModules] = useState([]);
  const pageId = location.state?.page;
  const objectives = location.state?.objectives;
  let [progress_element, setProgressElement] = useState(0);
  let [total_length, setTotalLength] = useState(0);
  const [model_array, setPage] = useState([]);
  const [lessons_data, setLessonData] = useState([]);
  const [next_id, setNextId] = useState(0);
  const [next_lesson_id, setNextLessonId] = useState("");
  const [next_module_id, setNextModuleId] = useState(0);
  const [lesson_id, setLessonId] = useState("");
  const [module_Id, setModuleId] = useState(location.state?.moduleId);
  const [course_id, setCourseId] = useState(location.state?.courseId);
  const [cohort_id, setCohortId] = useState(localStorage.getItem("cohort_id"));
  const [complete_lesson, setCompleteLesson] = useState(false);
  const [module_badge, setModuleBadge] = useState(false);
  const [course_name, setCourseName] = useState("");
  const [module_name, setModuleName] = useState("");
  const [module_badge_url, setModule_badge_url] = useState("");
  const [lesson_name, setLessonName] = useState("");
  const [module_length, setModuleLength] = useState(0);
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  const [activityEndTime, setActivityEndTime] = useState(moment());
  const [activityTotalTime, setActivityTotalTime] = useState(0);
  const [time_spent_lesson, setTime_spent_lesson] = useState(0);
  const [timeToReach, setTimeToReach] = useState(0);
  const [isCourseAhead, setIsCourseAhead] = useState(false);
  const [dividedValue, setDividedValue] = useState(0);
  let [progressData, setProgressData] = useState([]);
  var [recap, setRecap] = useState({});
  var [total_score, setTotalScrore] = useState(0);
  var [max_value, setMaxValue] = useState(0);
  var [show_next, setShowNext] = useState("");
  let [quiz_duration, setQuizDuration] = useState('00');
  let [quiz_show,setQuizShow] = useState(false)
  let show_quiz = false
  let [quiz_seconds,setQuizSeconds] = useState('00')
  let [quiz_hours,setQuizHours] = useState('00')
  let [time_up,setTimeUp] = useState(false)
  var data = [];
  let sla = false;
  let [sla_time,setSlaTime] = useState(null)
  let sla_start_time
  var module_data = [];
  var [idArr, setIdArr] = useState([]);
  let [isFullscreen,setIsFullscreen] = useState(true)
  var name = "";
 let duration
 let flag =false
 let [quiz_flag,setQuizFlag] = useState(false)
  let [complete_feedback, setCompleteFeedback] = useState(false);
  const [review_loader_flag, setReviewLoaderFlag] = useState(false);
  let [max_attempts,setMaxAttempts] = useState('')
  let [user_attempts,setUserAttempts] = useState('')
  useEffect(() => {
  
    if(localStorage.getItem('re_attempt')){
      // location.state.quiz_review = false
      location.state.review_selected =false
      quiz_review = false
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreenChange = () => {
     setIsFullscreen(!!document.fullscreenElement || !!document.webkitFullscreenElement || !!document.mozFullScreenElement || !!document.msFullscreenElement);
  };
  useEffect(() => {
    //module_summary json
    axios.get(`./json/module_summary.json`).then((module_summary)=>{
    const module_summary_data=module_summary.data
   const topNavBar=document.getElementById("top-nav-bar")
   if(topNavBar)
   {
     topNavBar.style.display = "none";
   }
  
    if (
      location.state?.id == undefined &&
      location.state?.moduleId == undefined
    ) {
      history.push("/modulepage");
    } else {
      setLessonId(location.state?.lesson_id);
      setModuleId(location.state?.moduleId);
      if (location.state?.item) {
        setNextLessonId(location.state?.lesson_id);
      }

      if (
        location.state?.lesson_id == 1 &&
        JSON.parse(localStorage.getItem("audit_user")) == true
      ) {
        localStorage.setItem("lessonId", location.state?.lesson_id);
        axios.get(`./json/feedback_course.json`).then((feedback_res) => {
          setLessonName(item.title);
          axios
            .post(base_url + "feedback/get_feedback", {
              user_id: localStorage.getItem("user_id"),
              course_id: course_id,
              lesson_id:
                localStorage.getItem("lessonId") == 1
                  ? cohort_id
                  : localStorage.getItem("lessonId"),
              cohort_schedule_id: cohort_id,
              type: 1,
            })
            .then((get_res) => {
              if (get_res.data.data.length != 0) {
                feedback_res.data.content.model.map((item, index) => {
                  if (
                    item.children[0].children[0].question_id ==
                    get_res.data.data[index]?.question_id
                  ) {
                    item.is_answered = get_res.data.data[index]?.feedback
                      ? true
                      : false;
                    item.user_answer = get_res.data.data[index]?.feedback
                      ? get_res.data.data[index]?.feedback
                      : false;
                    item.children[0].children[0].is_answered = get_res.data
                      .data[index]?.feedback
                      ? true
                      : false;
                    item.children[0].children[0].user_answer = get_res.data
                      .data[index]?.feedback
                      ? get_res.data.data[index].feedback
                      : false;
                  }
                });
                callContentData(feedback_res.data.content.model);
              } else {
                callContentData(feedback_res.data.content.model);
              }
            })
            .catch((err) => {
              console.log(err);
              if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
              
            });
        });
      } else {
        axios
          .post(base_url + "lessons/get_lesson_details", {
            lesson_id: location.state?.lesson_id,
            user_id: localStorage.getItem("user_id"),
            cohort_schedule_id: cohort_id,
          })
          .then((res) => {
              let lesson_obj_id = {
                "lesson_id" : res.data.result.lesson_id,
                "module_id" : res.data.result.module_id,
                "course_id" : res.data.result.course_id,
                "cohort_sch_id" : res.data.result.cohort_scheduleId
              }
            
              
              for (let i = 0; i < res.data.result.content.model.length; i++) {
                const currentModel = res.data.result.content.model[i];
                
                if (currentModel.children && currentModel.children[0]?.children[0]) {
                  currentModel.children[0].children[0] = { ...currentModel.children[0].children[0], ...lesson_obj_id };
                }
              }
              // module_summary
              let module_summry_link=res.data.result.summary_link
              if(module_summry_link)
              {
              if(module_summary_data)
              {
                module_summary_data.children[0].children[2].children[0].href=module_summry_link
                res.data.result.content.model.push(module_summary_data)
              }
            }
            setLessonId(res.data.result.lesson_id);
            setModuleId(res.data.result.module_id);
            setCourseId(res.data.result.course_id);
            setCohortId(res.data.result.cohort_scheduleId)
            setLessonName(res.data.result.title);
            setModuleBadge(res.data.result.module_badge)
            localStorage.setItem('lesson_name',res.data.result.title)
            localStorage.setItem('sla_file_url',res.data.result.post_sla_submitted_document_url)
            localStorage.setItem("course_id", res.data.result.course_id);
            localStorage.setItem("cohort_id", res.data.result.cohort_scheduleId);
            localStorage.setItem('lessonId',res.data.result.lesson_id)
            localStorage.setItem('moduleId',res.data.result.module_id)
            localStorage.setItem('course_name',res.data.result.course_name)
            localStorage.setItem('module_name',res.data.result.module_name)
            localStorage.setItem('lesson_name',res.data.result.lesson_name)
            if(res.data.result.title.toLowerCase().includes(social_learning_activity)){
              sla = true
              sla_start_time = res.data.result.sla_start_time
              localStorage.setItem('sla_time',true)
              setSlaTime(res.data.result.sla_start_time)
            }else{
              sla = false
              localStorage.setItem('sla_time',false)
            }
            if (
              JSON.parse(localStorage.getItem("audit_user")) == true &&
              item.total_score != 0
            ) {
              axios.get(`./json/feedback.json`).then((feedback_res) => {
                axios
                  .post(base_url + "feedback/get_feedback", {
                    user_id: localStorage.getItem("user_id"),
                    course_id: course_id,
                    lesson_id: localStorage.getItem("lessonId"),
                    cohort_schedule_id: cohort_id,
                    type: 3,
                  })
                  .then((get_res) => {
                    if (get_res.data.data.length != 0) {
                      feedback_res.data.feedbacks.map((item, index) => {
                        if (
                          item.children[0].children[0].question_id ==
                          get_res.data.data[index]?.question_id
                        ) {
                          item.is_answered = get_res.data.data[index]?.feedback
                            ? true
                            : false;
                          item.user_answer = get_res.data.data[index]?.feedback
                            ? get_res.data.data[index].feedback
                            : false;
                          item.children[0].children[0].is_answered = get_res
                            .data.data[index]?.feedback
                            ? true
                            : false;
                          item.children[0].children[0].user_answer = get_res
                            .data.data[index]?.feedback
                            ? get_res.data.data[index].feedback
                            : false;
                        }
                      });
                    }
                    callContentData([
                      ...res.data.result.content.model,
                      ...feedback_res.data.feedbacks,
                    ]);
                  })
                  .catch((err) => {
                    console.log(err);
                      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                        localStorage.clear();
                        history.push('/')
                        window.location.reload();
                      }else{
                        toast.warn(api_error_message,{toastId:random_number});
                      }
                  });
              });
            } else {
              if (res.data.result.quiz_duration != 0) {
                setQuizShow(true)
                show_quiz = true
                axios.get(`./json/about_quiz.json`).then((quiz_res) => {
                  if (res.data.result.start_time == 0) {
                    localStorage.setItem('quiz_start_time',false)
                    duration = res.data.result.quiz_duration
                    var countDownTime = res.data.result.quiz_duration * 60;
                    var hours = Math.floor(countDownTime / 3600);
                    var minutes = Math.floor((countDownTime % 3600) / 60);
                    var seconds = (countDownTime  % 60);
                    setQuizHours(hours)
                    setQuizDuration(minutes);
                    setQuizSeconds(seconds)
                  } else{
                    flag =true
                    localStorage.setItem('quiz_lesson',localStorage.getItem("lessonId"))
                    localStorage.setItem('quiz_start_time',true)
                    setShowNext('quiz_false');
                     let diff_time = date_formats.convert_current_date_utc_format() - res.data.result.start_time 
                     let final_time = Math.floor(moment.duration(diff_time).asMinutes())
                     if(final_time < res.data.result.quiz_duration){
                     quizTimer(res.data.result.quiz_duration-final_time)
                     }else{
                      save_quiz_acitivity()
                      toast.error("You have reached your quiz duration!...",{toastId:random_number});
                      setTimeUp(true)
                     }
                  }
                  callContentData([ ...quiz_res.data.content,
                    ...res.data.result.content.model
                  ]);
                })
              
              }else{
                callContentData(res.data.result.content.model);
              }
              
            }
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
      }
    }
    fetchData();
  })
  }, []);



  const enterFullScreen = () => {
    setIsFullscreen(true)
    if (document.documentElement.requestFullscreen) {
      document.documentElement.webkitRequestFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }

    document.addEventListener('fullscreenchange', exitHandler,false);
    document.addEventListener('webkitfullscreenchange', exitHandler,false);
    document.addEventListener('mozfullscreenchange', exitHandler,false);
    document.addEventListener('MSFullscreenChange', exitHandler,false);
  }
  function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      if(!localStorage.getItem('artcle_LinkClicked'))
      {
      //  history.push('/modulepage')
      }
    }
}
const isiPad=()=> {
  // Check if the device has touch capabilities
  const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  // Check for specific iPad behaviors or features
  const isiPadBehavior = (
    // Check for iPad-specific touch events
    (hasTouch && typeof Touch !== 'undefined' && typeof TouchEvent !== 'undefined' && typeof TouchList !== 'undefined') ||
    // Check for iPad-specific gesture events
    (hasTouch && typeof window.orientation !== 'undefined')
    // Add more iPad-specific checks if needed
    // ...
  );

  // Check if the screen size indicates an iPad
  const isiPadScreenSize = (
    window.screen.width >= 768 && window.screen.height >= 1024
  );

  // Check if the device is running on the Windows operating system
  const isWindows = /Windows/i.test(navigator.userAgent);

  // Combine all the checks
  return isiPadBehavior && isiPadScreenSize && !isWindows;
}
  // useEffect(() => {
  //   const handleFullScreen = (event) => {
  //     if (event.target.tagName !== 'A' &&document.fullscreenElement === null  && event.target.innerText.trim() != "Course Outline") {
  //       localStorage.removeItem('artcle_LinkClicked')
  //       if (!isiPad()) {
  //          enterFullScreen();
  //       }
  //     }
  //   };
  //   document.addEventListener('click', handleFullScreen);
  //   return () => document.removeEventListener('click', handleFullScreen);
  // }, []);

  const save_quiz_acitivity = () =>{
    axios
            .post(base_url + "user/save_quiz_acitivity_tracking_data", {
              user_id: localStorage.getItem("user_id"),
              course_id: course_id,
              lesson_id: localStorage.getItem("lessonId"),
              module_id: module_id,
              cohort_schedule_id: cohort_id,
            })
            .then((res) => {
            })
            .catch((err) => {
              console.log(err);
              if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
            });
  }
  const callContentData = (response_data) => {
    let lesson_data=[]
    if(localStorage.getItem('re_attempt')){
      lesson_data =[...response_data].filter(item=>{
        item.is_answered = false;
        item.children[0].children[0].is_answered = false;
        return item
      })
    }else{
     lesson_data= [...response_data];
    }
    setModuleLength(lesson_data.length);
    let idx =-1;
    for (let index = 0; index < lesson_data.length; index++) {
      let lessonItem = lesson_data[index];
      if (lessonItem.user_answer) {
        if (lessonItem.is_answered) {
          idx=index;
        }
      }
    }
    lesson_data.some(item=>{
        if (!item.is_answered) {
          if(item.purpose=='learnmore'){
            quiz_review = true
          }else{
            location.state.review_selected =false
            quiz_review = false
          }
        }
    })
    // lesson_data.findLastIndex((item, index) => {
    //   if (item.user_answer) {
    //     if (item.is_answered) {
    //       return item;
    //     }
    //   }
    // });
    if (idx == -1) {
      idx = 0;
    }
    setPage(response_data);
    setMaxValue(response_data.length);

    if (
      response_data[0]?.children[response_data[0].children?.length - 1]
        ?.activity_id
    ) {
      setShowNext("");
    } else {
      if(sla){
        if(date_formats.convert_current_date_utc_format() >= sla_start_time - (2 * 24 * 60 * 60 * 1000) && response_data[0]){
          setShowNext("false");
        }else{
          toast.error("ISB Town will open soon",{toastId:random_number})
          setShowNext("");
        }
      }else{
        setShowNext("false");
      }
    }
    setProgressElement(idx);
    let i,
      content_data = [];
    let finalValue = 100 / response_data.length;
    setCount(finalValue * idx);

    for (i = 0; i <= idx; i++) {
      content_data.push(
        <Fragment key={i}>
          <ContentPage
            parentCallback={handleCallback}
            data={response_data[i]}
            index={i}
            quiz_review = {quiz_review}
          ></ContentPage>

          <p className="mb-0">
            <strong data-testid="Section_info" className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
              Section {i + 1} of {response_data.length}
            </strong>{" "}
          </p>
        </Fragment>
      );
    }
    setLessonData((current) => [...current, content_data]);
  };
  const pauseVideoRoll = (id) => {
    var iframes = document.getElementsByClassName("panaptoVideo");
    if (iframes.length !== null) {
      Array.from(iframes).map((item) => {
        item.src += ("&autoplay=false");
      });
    }
  };
  const handleCallback = (data) => {
  
    if(data == 'quiz_false' && !flag ){
    flag=true
      quizTimer(duration)
    }
    setShowNext(data);
  };
  const quizTimer = (time) =>{
      setQuizFlag(true)
      var countDownMinutes = time;
      var countDownTime = countDownMinutes * 60;
      var countdown = setInterval(function () { 
      countDownTime -= 5;
      var hours = Math.floor(countDownTime / 3600);
      var minutes = Math.floor((countDownTime % 3600) / 60);
      var seconds = (countDownTime  % 60);
      if (countDownTime  <= 0) {
         if(show_quiz){
          clearInterval(countdown);
          save_quiz_acitivity()
          toast.error("You have reached your quiz duration!...",{toastId:random_number});
          setTimeout(()=>{
            history.push("/modulepage");
            window.location.reload()
          },2000)
         }
         
      }else{
        setQuizHours(hours)
        setQuizDuration(minutes)
        setQuizSeconds(seconds)
      }
      }, 5000);
  }
  
  const fetchData = async () => {
    name = course_id;
    axios
      .post(base_url + "courses/get_course_details", {
        course_id: location.state?.courseId,
      })
      .then((res) => {
        setCourseName(res.data.result.course.title);
        localStorage.setItem('course_name',res.data.result.course.title)
        res.data.result.course.modules.map((item) => {
          item.map((module) => {
            module_data.push(module.module_id);

            if (module.module_id == module_id) {
              setModuleName(module.title);
              localStorage.setItem('module_name',module.title)
              module.lessons[0].map((i) => {
                data.push(i);
              });
            }
          });
        });
        module_data.filter((item, index) => {
          if (item == module_id) {
            setModuleId(module_data[index]);
            localStorage.setItem("moduleId", module_data[index]);
            setNextModuleId(module_data[index + 1]);
          }
        });
        var lesson_array = [];
        data.map((items) => {
          lesson_array.push(items.lesson_id);
          setIdArr((prev) => [...prev, items.lesson_id]);
        });
        lesson_array.filter((item1, idx) => {
          if (item1 == location.state?.lesson_id) {
            setNextId(idx);
            setLessonId(item1);
            localStorage.setItem("lessonId", item1);
            setNextLessonId(item1);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
  };
 
 

 

  const showNextElement = (item) => {
      if(date_formats.convert_current_date_utc_format() <= sla_time && model_array[item + 1].purpose == "learnmore"){
        toast.error("ISB Town will open soon",{toastId:random_number})
        setShowNext("");
       }
  else{
    if(JSON.parse(localStorage.getItem('confirmation_code'))){
      if( model_array[item + 1]?.children[model_array[item + 1].children?.length - 1]?.activity_id){
        item = item+1
       }
      }
      let x = 100 / max_value;
      setCount(Count + x);
      pauseVideoRoll();
      var activity_id = null;
      var activity_entity_type = null;
      if (model_array[item + 1]) {
        if (
          // !model_array[item+1]?.children[model_array[item+1]?.children?.length - 1]
          model_array[item + 1]?.children[model_array[item + 1].children?.length - 1]?.activity_id
        ) {
          
        setShowNext("");
        } else {
         
            setShowNext("false");
         
        }
      }
  
      let activityEndTime = moment();
      setActivityEndTime(activityEndTime);
      const totalMinutes = activityEndTime.diff(activityInitiatedTime, "minutes");
      setActivityTotalTime(totalMinutes);
      if (
        !model_array[item]?.children[model_array[item].children?.length - 1]
          ?.activity_id
      ) {
        var body = {
          user_id: localStorage.getItem("user_id"),
          course_id: course_id,
          module_id: module_Id,
          lesson_id: lesson_id,
          cohort_schedule_id: cohort_id,
          activity_id: activity_id,
          activity_entity_type: activity_entity_type,
          is_question: false,
          user_answer: "",
          is_correct: null,
          question_type: "test",
          answer_label: "Test",
          is_quiz: false,
          time_spent_on_activity: activityEndTime.diff(
            activityInitiatedTime,
            "seconds"
          ),
          activity_submitted_platform: "ISBo"
        };       
        if (
          localStorage.getItem("user_id") &&
          window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false
        ) {
          axios
            .post(base_url + "user/progress_tracking", body)
            .then((res) => {
              setActivityInitiatedTime(moment());
              localStorage.setItem("progress_tracking", res, data.result);
              enableNextElement(item)
            })
            .catch((err) => {
              console.log(err);
              setShowNext("");
              if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
            });
        }else{
          enableNextElement(item);
        }
      }else{
        enableNextElement(item)
      }
  }

  };

  const reviewLesson = () =>{
    setReviewLoaderFlag(true)
    setCompleteLesson(false)
    localStorage.removeItem('re_attempt')
    let state_data = JSON.parse(JSON.stringify(location.state))
    state_data.review_selected = true;
    history.push('/lessonpage',state_data)
    window.location.reload(); 
    enterFullScreen()
  }

  const redoLesson = () =>{
    location.state.review_selected =false
    quiz_review = false
    localStorage.setItem('re_attempt',true)
    window.location.reload(); 
    enterFullScreen()
  }
  const enableNextElement = (item) => {
    setProgressElement(item + 1);
    if (item + 1 < module_length) {
      setLessonData((current) => [
        ...current,
        <Fragment key={item + 1}>
          <ContentPage
            parentCallback={handleCallback}
            data={model_array[item + 1]}
            index={item + 1}
            quiz_review = {quiz_review}
          ></ContentPage>
          <p className="mb-0">
            {" "}
            <strong className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
              Section {item + 2} of {model_array.length}
            </strong>
          </p>
        </Fragment>,
      ]);
    } else if (item + 1 == module_length ) {
      
      if (lesson_id != 1) {
        axios
          .post(base_url + "lessons/get_lesson_recap_details", {
            // lesson_id: lesson_id,
            lesson_id: lesson_id,
            cohort_schedule_id: cohort_id,
            user_id: localStorage.getItem("user_id"),
          })
          .then((res) => {
            if(lesson_name.toLowerCase().includes(quiz_name)){
              setMaxAttempts(res.data.result.max_no_of_quiz_attempts)
              setUserAttempts(res.data.result.quiz_attempted_by_user)
            }
            if (res.data.result) {
              const {
                batch_average,
                course_current_score,
                maximum_score,
                this_lesson_score,
                time_spent,module_badge,
                module_badge_url
              } = res.data.result;
             setModuleBadge(module_badge)
             setModule_badge_url(module_badge_url??"")
              let progressData = [];
              if (batch_average < course_current_score) {
                setIsCourseAhead(true);
              }
              if (course_current_score == batch_average) {
                setIsCourseAhead(true);
                progressData = [
                  {
                    id: 101,
                    label: "Course Start",
                    mobile_label:"Start",
                    value: 0,
                    color: "#708FAB",
                  },
                  {
                    id: 102,
                    label: "Current Points & Batch Average",
                    mobile_label:"Your Score & Average",
                    value: Math.round(course_current_score),
                    color: "#66266B",
                  },
                  {
                    id: 103,
                    label: "Maximum Possible",
                    mobile_label:"Maximum Points",
                    value: Math.round(maximum_score),
                    color: "#708FAB",
                  },
                ];
                setProgressData(progressData);
              } else {
                progressData = [
                  {
                    id: 101,
                    label: "Course Start",
                    mobile_label:"Start",
                    value: 0,
                    color: "#708FAB",
                  },
                  {
                    id: 102,
                    label: "Current Points",
                    mobile_label:"Your Score",
                    value: Math.round(course_current_score),
                    color: "#057092",
                  },
                  {
                    id: 103,
                    label: "Batch Average",
                    mobile_label:"Average",
                    value: Math.round(batch_average),
                    color: "#66266B",
                  },
                  {
                    id: 104,
                    label: "Maximum Possible",
                    mobile_label:"Maximum Points",
                    value: Math.round(maximum_score),
                    color: "#708FAB",
                  },
                ];
                setProgressData(progressData);
              }

              let spent_time = 0;
              if (time_spent > 59) {
                spent_time = parseInt(time_spent / 60);
              } else {
                spent_time = 1; //(time_spent/60).toFixed(2)
              }
              let timeToreachValue = (this_lesson_score / spent_time).toFixed(
                2
              );

              let current_lesson_score = 1;
              this_lesson_score == 0
                ? (current_lesson_score = spent_time)
                : (current_lesson_score = this_lesson_score);
              let moretimeToreach = spent_time / current_lesson_score;
              let pointsDiff =
                moretimeToreach == 0
                  ? Math.abs(course_current_score - batch_average)
                  : Math.abs(course_current_score - batch_average) *
                    moretimeToreach;
              let finalValue = Math.round(pointsDiff);
              setDividedValue(isNaN(timeToreachValue) ? 0 : timeToreachValue);
              setTimeToReach(isNaN(finalValue) ? 0 : finalValue);
              setTime_spent_lesson(isNaN(spent_time) ? 0 : spent_time);
              setRecap(res?.data?.result);
              setTotalScrore(isNaN(this_lesson_score) ? 0 : this_lesson_score);
              localStorage.setItem("complete_lesson", 1);
              setCompleteLesson(true);
            } else {
              localStorage.setItem("complete_lesson", 1);
              setCompleteLesson(true);
            }
          })
          .catch((err) => {
            console.log(err);
            setShowNext("");
            if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
      } else {
        setCompleteFeedback(true);
        setCompleteLesson(true);
      }
    } else {
      localStorage.setItem("complete_lesson", 1);
      setCompleteLesson(true);
      setProgressElement(0);
    }
  }

  // const setSubmitFlag = () => {
  //   return localStorage.getItem("submitted");
  // };
  const dummySetShowNext = ()=>{
    setShowNext('false')
  }
  function exitFullscreen() {
    localStorage.removeItem('re_attempt')
    setIsFullscreen(false)
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  const fullScreenToggle=()=>{
    if(isFullscreen)
    {
      exitFullscreen()
    }
    else{
      enterFullScreen()
    }
  }
  const goToModulePage = () => {
  localStorage.setItem('lessonId',lesson_id)
  localStorage.setItem('course_id',course_id)
  localStorage.setItem('moduleId',module_Id)
  localStorage.setItem('cohort_id',cohort_id)
    exitFullscreen();
     history.push("/modulepage");
  };

  const handle = useFullScreenHandle();

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(true);
  const controlBreadCrumb = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlBreadCrumb);
      return () => {
        window.removeEventListener("scroll", controlBreadCrumb);
      };
    }
  }, [lastScrollY]);

  const handleMouseMove = (e) => {
    if (typeof window !== "undefined") {
      if (e.clientY <= 80) {
        setShow(!true);
      }
    }
  };
  useEffect(() => {
    if(show_next!==""&&(progress_element+1==model_array.length))
    {
      localStorage.setItem("complete_lesson", 1);
    }
  }, [progress_element,show_next])
  

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  return (
    <>
      <div className={`lessonpage footer_cls_position`} ref={ref}>
        <div className="">
          {/* className="border-class bg-transparent" */}
          {/* <ObjectiveComponent data={objectives}></ObjectiveComponent> */}
          {lessons_data.length == 0  || review_loader_flag ? (
            <div>
            <img data-testid="atrium_loader" className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
          </div>
          ) : (
            <div>
              <div className="row">
                {window.pageYOffset > 50 ? (
                  window.innerWidth<900?<>

                  {/* mobile */}
                    <div
                    className={`h6 activate ${show && "hidden_brdcrmb"}`}
                    style={{ color: "#708FAB" }}
                  >
                    <div>
                      {complete_lesson ? (
                        <button
                          className="btn lesson_outline_button top-0 end-0  rounded mb-2"
                          onClick={goToModulePage} //this hits lesson completion API
                        >
                          <span className="outline_btn_text m-0">
                            {" "}
                            <i className="fa-solid fa-chevron-left pe-2" />
                            Course Outline{" "}
                          </span>
                        </button>
                      ) : (
                        <button
                          className="btn lesson_outline_button top-0 end-0  rounded mb-2"
                          onClick={goToModulePage}
                        >
                          <span className="outline_btn_text m-0">
                            {" "}
                            <i className="fa-solid fa-chevron-left pe-2" />
                            Course Outline{" "}
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="d-flex justify-content-start">
                      <span className="flex-row v-align-sub lesson_Info ml-0">
                        {course_name}
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4465_21304)">
                            <path
                              d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                              fill="#708FAB"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4465_21304">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        {module_name}
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4465_21304)">
                            <path
                              d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                              fill="#708FAB"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4465_21304">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {lesson_name.toLowerCase().includes(social_learning_activity)?lesson_name?.replace(/ ?- ?.*/, ""):lesson_name}
                      </span>
                    </div>
                  </div>
                  </>:<>
                  {/* web */}
                  <div
                   data-testid="Goto_courseOutline_banner"
                    className={`d-flex h6 activate ${show && "hidden_brdcrmb"}`}
                    style={{ color: "#708FAB" }}
                  >
                    <div className={`col-lg-3 ms-3 col-md-4`}>
                      {complete_lesson ? (
                        <button
                          data-testid="goToModulepageBtn"
                          className="btn my_btn_cls lesson_page_outline mt-0 outline-button"
                          onClick={goToModulePage} //this hits lesson completion API
                        >
                          <span className="pe-1 ps-1">
                            {" "}
                            <i className="fa-solid fa-chevron-left pe-2" />
                            Course Outline{" "}
                          </span>
                        </button>
                      ) : (
                        <button
                          data-testid="goBackModulesBtn"
                          className="btn my_btn_cls lesson_page_outline mt-0 outline-button"
                          onClick={goToModulePage}
                        >
                          <span className="pe-1 ps-1">
                            {" "}
                            <i className="fa-solid fa-chevron-left pe-2" />
                            Course Outline{" "}
                          </span>
                        </button>
                      )}
                    </div>
                    
                    <div className="col-lg-7 col-md-8 position-relative d-flex justify-content-start m-auto">
                      <span className="flex-row">
                        {course_name}&nbsp;
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4465_21304)">
                            <path
                              d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                              fill="#708FAB"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4465_21304">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        {module_name}&nbsp;
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4465_21304)">
                            <path
                              d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                              fill="#708FAB"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4465_21304">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        &nbsp;{lesson_name.toLowerCase().includes(social_learning_activity)?lesson_name?.replace(/ ?- ?.*/, ""):lesson_name}
                      </span>
                    </div>
                    <div data-testid="exitfullscreen_banner" className="col-lg-2 col-md-8">
                       <button data-testid="exitFull_ScreenBtn" className="btn fullscreen_toggle_btn float-end mr-2" onClick={fullScreenToggle}>{isFullscreen?"EXIT FULL SCREEN" :"ACTIVATE FULL SCREEN"}</button>
                    </div>
                  </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div>
                <div
                  className={`flex-row h6 fw-normal text-center lesson_main_arrows_info ${show}`}
                  data-testid="Lesson_path_info"
                  style={{
                    color: "#708FAB",
                    marginTop: "25px",
                    marginBottom: "28px",
                  }}
                >
                  {course_name}{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_4465_21304)">
                      <path
                        d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                        fill="#708FAB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4465_21304">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>&nbsp;
                  {module_name}
                </div>
                <div>
                  <ul className="brdCrb_styl " data-testid="lesson_parallelogram_info">
                    <div className="brdCrb_styl_span text-center">
                  {!lesson_name.toLowerCase().includes(summary_name) && !lesson_name.toLowerCase().includes(quiz_name) && !lesson_name.toLowerCase().includes(social_learning_activity) && <>Lesson {location.state?.index}</>}
                  </div>
                    <h3 className="brdCrb_styl_txt">{lesson_name.toLowerCase().includes(social_learning_activity)?lesson_name?.replace(/ ?- ?.*/, ""):lesson_name}</h3>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {lessons_data}
          {(!complete_lesson && !time_up) &&
           (lesson_name.toLowerCase().includes(quiz_name) && quiz_show )&&(
            <div className="quiz_width">
              <div className="quiz_class">
                {quiz_flag ? <>
              <span className="timeleft_span" style={{color:"#708FAB"}}>TIME LEFT</span>&nbsp;&nbsp;<span className="time_span">{quiz_hours == '0'?'':quiz_hours+':'}{quiz_duration}:{quiz_seconds}</span>
              
                </>:<><span className="time_span">{quiz_hours == '0'?'':quiz_hours+':'}{quiz_duration}:{quiz_seconds}</span></>}
              </div>
            </div>
          )
           
          }
          {complete_lesson && (
            !complete_feedback?
                 <>
              {total_score > 0 ? <>  
              <div className="col-12  mt-2  mt-4  container myContainerCls custm_shadow_cls progress_card progress_card_Web" style={{background: '#ffffff'}} >
              <span className="recapHeadingone">Well done! You have reached the end of this {lesson_name.toLowerCase().includes('quiz')?"Quiz":"lesson"}.</span>
              <span className="progressbar_subtext">{lesson_name.toLowerCase().includes('quiz')?`In the ${user_attempts}/${max_attempts==quiz_max_attempts?unlimited_text:max_attempts} attempt`:"During this lesson"}, you earned {total_score == 1 ? total_score+' point' : total_score+ ' points'} and spent  {time_spent_lesson} {time_spent_lesson==1? 'minute':'minutes'}.</span>
              <div className="row col-lg-12 d-flex justify-content-center align-self-center pt-4 pb-4 bg-light mt-3 mb-3 lh-sm">
                      <div >
                      <Stepperprogress data={progressData}  view={"WEB"}/>
                      </div>
                    </div>
             {isCourseAhead?<span className="recapSubheadingone">You are doing great. Keep it up!</span> :<span className="recapSubheadingone">At this rate, if you spend <span className="font-weight-bold">{timeToReach} more minutes</span>, you can cross the batch average </span>} 
              <span className="recapSubheadingtwo"><span className="font-weight-bold">Learning Efficiency : </span>
              <span className="font-weight-400">
              {total_score == 1 ? total_score+' Point' : total_score+ ' Points'} / {time_spent_lesson} {time_spent_lesson == 1? 'Minute':'Minutes'}
              </span>
                = <span className="font-weight-bold ">{dividedValue}</span> <span className=" font-weight-400">Points Per Minute</span> </span>
            </div>

                  <div className="container myContainerCls mt-5 progress_card_mobile">
                  <div>
                    <div className="progress_card_moble p-5 col-12 custm_shadow_cls bg-body">
                      <div className="col-12 p-0 overflow-hidden">
                        <div className="d-flex flex-column">
                          <div>
                            <div className="recapHeadingone_mobile">
                              Well done! You have reached the end of this lesson.
                            </div>
                            <div className="progressbar_subtext_mobile">
                              During this lesson, you earned{" "}
                              <span className="Progress_text_bold">
                                {total_score == 1
                                  ? total_score + " point"
                                  : total_score + " points"}
                              </span>{" "}
                              and spent{" "}
                              <span className="Progress_text_bold">
                                {time_spent_lesson}{" "}
                                {time_spent_lesson == 1 ? "minute" : "minutes"}.
                              </span>
                            </div>
                            <div className="d-flex justify-content-center align-self-center pt-4 pb-4 bg-light mt-3 mb-3 lh-sm">
                              <div className="row col-lg-12">
                                <Stepperprogress data={progressData}  view={"MOBILE"}/>
                              </div>
                            </div>
                            {isCourseAhead ? (
                              <div className="recapSubheadingone_mobile">
                                You are doing great. Keep it up!
                              </div>
                            ) : (
                              <div className="recapSubheadingone_mobile">
                                At this rate, if you spend{" "}
                                <span>{timeToReach} more minutes</span>, you can cross the batch
                                average{" "}
                              </div>
                            )}
                            <div className="recapSubheadingtwo_moblie">
                              <div className="d-inline-block">Learning Efficiency : </div>
                              {total_score == 1
                                ? total_score + " Point"
                                : total_score + " Points"}{" "}
                              / {time_spent_lesson}{" "}
                              {time_spent_lesson == 1 ? "Minute" : "Minutes"} =
                              <span className="text-dark font-weight-bold">{dividedValue}</span> Points Per Minute{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>:""}
              { (complete_lesson  && module_badge == true && except_first_last_modules == true) ? <>
            <Congratulationsscreeen certificate_url={module_badge_url} module_name={module_name}  course_name={course_name} />
          </>:""}
            {/* <div className="text-center mt-5">
               <button className="btn my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline "  onClick={goToModulePage}>GO TO COURSE OUTLINE <i className="fa-solid fa-chevron-right pe-2 pl-1"></i></button>
            </div> */}
            {lesson_name.toLowerCase().includes('quiz')
            ? 
            
              <div className="text-center mt-5">

                {
                  user_attempts < max_attempts && 
                  (quiz_review == true   
                    ? 
                      <button className="btn my_outline_button_cls top-0 end-0 me-5 quiz_review_button"  onClick={redoLesson}>Redo</button> 
                    :  
                      <button className="btn my_outline_button_cls top-0 end-0 me-5 quiz_review_button"  onClick={reviewLesson}>Review</button>
                  )
                }
                
                <button data-testid="lesson_page_outline" className="btn my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline" onClick={goToModulePage}>GO TO COURSE OUTLINE <i className="fa-solid fa-chevron-right pe-2 pl-1"></i></button>
              </div> 
            : 
              <div className="text-center mt-5">
                <button data-testid="lesson_page_outline" className="btn my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline" onClick={goToModulePage}>GO TO COURSE OUTLINE <i className="fa-solid fa-chevron-right pe-2 pl-1"></i></button>
              </div>
            }      
            </>:<>
            <div className="col-12  mt-2 p-5 mt-4  container myContainerCls custm_shadow_cls" style={{background: '#ffffff'}} >
<span className="text-center">Well done! You have successfully completed the course. You will get certificate soon...</span>
<div className="text-center"><button className="btn my_btn_cls top-0 end-0" onClick={goToModulePage}>Go Back</button>
</div>

</div>
            </>
          )}
        </div>
        <br></br>
        {!complete_lesson && (
          <div
            className={`navigation d-flex  justify-content-center
           
            `}
          >
            {lessons_data.length != 0 && (
              <>
                {/* {(lesson_name.toLowerCase() == "quiz" && quiz_show) && (
                  <div className="quiz_width" style={{ visibility: "hidden" }}>
                    h
                  </div>
                )} */}
                <div>
                <div className="d-none" data-testid="show_next_none" onClick={dummySetShowNext}></div>
                  { !(quiz_show && time_up) &&
                  (<button
                  data-testid="downarrow_btn"
                    className="btn btn-lg my_btn_cls shadow border border-light border-4 rounded-circle next_activity_btn"
                    onClick={() => showNextElement(progress_element)}
                    disabled={show_next == "" ? true : false}
                  >
                    <img
                      className="bg-transparent"
                      src="./images/downarrow.png"
                      alt=""
                    />
                  </button>)}
                </div>
                {/* {(lesson_name.toLowerCase() == "quiz" && quiz_show )&&(
                  <div className="quiz_width">
                    <div className="quiz_class">
                    <span className="timeleft_span" style={{color:"#708FAB"}}>TIME LEFT</span>&nbsp;&nbsp;<span className="time_span">{quiz_duration+1}:00</span>&nbsp;&nbsp;<span className="timeleft_span">MIN</span>
                    </div>
                  </div>
                )} */}
              </>
            )}
          </div>
        )}
      </div>
      {lessons_data.length == 0 ? (
        ""
      ) : (
        <>
          <ProgressBar
            data-testid="lesson_progressBar"
            className="mt-5"
            style={{ height: "5px" }}
            visuallyHidden={true}
            animated={false}
            min={0}
            max={100}
            now={Count}
            label={`${Count} %`}
          />
          <div  data-testid="lesson_Footer" className="bg-dark p-5  text-white text-center position-absolute w-100 ">
            {" "}
            <p className="text-center">
              {" "}
              Copyright © Indian School of Business
            </p>
          </div>
        </>
      )}
      {/* for unit test cases */}
      <div className="d-none" data-testid="controlBreadCrumbcall" onClick={controlBreadCrumb}></div>
      <div className="d-none" data-testid="handleMouseMoveCall" onClick={()=>handleMouseMove({'clientY':40})}></div>
      <div className="d-none" data-testid="goToModulePageCall" onClick={goToModulePage}></div>
      <div className="d-none" data-testid="showNextElementCall" onClick={()=>showNextElement(5)}></div>
      <div className="d-none" data-testid="save_quiz_acitivityCall" onClick={save_quiz_acitivity}></div>
      <div className="d-none" data-testid="handleFullscreenChange " onClick={handleFullscreenChange}></div>
      <button  className="d-none" data-testid="redo_button" onClick={redoLesson}>Redo</button> 
      <button className="d-none"data-testid="review_button" onClick={reviewLesson}>Review</button>
    </>
  );
};
export default withMaintenanceCheck(LessonPage,user_maintenance_type) 
