import axios from '../axios-interceptor';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { base_url,random_number, razorpay_key,api_error_message, user_maintenance_type, emi_payment } from '../utilities';
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';
import withMaintenanceCheck from '../HigherOrderComponents/maintenancePageChecker';

const LatestEmiGateway=()=>{
    const history = useHistory();
        //payment implementation
        const handlePayment = async () => {
            try {
              let user_id = localStorage.getItem("setPassword_UserId");
              let program_id = localStorage.getItem("setPassword_programId");
              
              const orderUrl = base_url + "user_order/program_request/emi";
              const { data } = await axios.post(orderUrl, {
                user_id: user_id,
                program_id: program_id,
                payment_type:emi_payment
              });
              initPaymentWithProgramId(data.order);
            } catch (error) {
              console.log(error);
              if(error.response?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
            }
          };
        
          const initPaymentWithProgramId = (data) => {
            const options = {
              key: razorpay_key,
              amount: data.amount,
              currency: data.currency,
              name: data.notes.learning_track_name,
              description: data.notes.description,
              image: data.notes.img,
              order_id: data.id,
              handler: async (response) => {
                try {
                  const verifyUrl = base_url + "user_order_verify/program_request/emi";
                  const { data } = await axios.post(verifyUrl, response);
                //   alert(data.message);
                localStorage.removeItem("setPassword_UserId");
                localStorage.removeItem("setPassword_programId");
                if(localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn'))
                {
                  history.push('/maindashboard')
                  window.location.reload()
                }else{
                  history.push('/')
                  window.location.reload();
                }
                } catch (error) {
                  console.log(error);
                  if(error.response?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/')
                    window.location.reload();
                  }else{
                    toast.warn(api_error_message,{toastId:random_number});
                  }
                }
              },
              theme: {
                color: "#3399CC",
              },
              
              modal: {
                ondismiss: () => {
                  history.push('/maindashboard')
                }
              }
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          };

          useEffect(() => {
            handlePayment()
          }, [])
          

    return(<>
    </>)
}
export default withMaintenanceCheck(LatestEmiGateway,user_maintenance_type) 