import axios from "../../axios-interceptor";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { base_url, api_error_message, random_number, user_maintenance_type } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { crypto_secret_key } from "../../utilities";
import CryptoJS from "crypto-js";

const IsbLogin = () => {
  var [errorFlag, setErrorFlag] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [banned, setBanned] = useState(false);
  // const {email}=props
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const {
    register,
    handleSubmit, setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const history = useHistory();
  const onSubmitLogin = (data) => {
    let obj = {
      Email: data.Email.trim(),
      Password: data.Password.trim(),
    };

    let hashedpassword = CryptoJS.AES.encrypt(
      obj.Password,
      crypto_secret_key
    ).toString();

    axios
      .post(base_url + "login/isb/user", {
        email: obj.Email,
        password: hashedpassword,
      })
      .then((res) => {
        if (res.data.result.is_banned) {
          setBanned(res.data.result.is_banned)
          return false;
        } else {
          const { access_token, auth_refresh_token } = res.data
          if (res.data.result.length != 0) {
            setErrorFlag(false);
            localStorage.setItem("audit_user", false);
            localStorage.setItem("user_id", res.data.result._id);
            localStorage.setItem('User_Accesstoken', access_token)
            localStorage.setItem('User_Refreshtoken', auth_refresh_token)
            localStorage.setItem('isLoggedIn', 'true')
            localStorage.setItem('User_Name', res.data.result.first_name)
            localStorage.setItem('User_Email', res.data.result.email)
            localStorage.setItem('is_verizon_student', res.data.result.is_verizon_student ?? 0)
            // props.sessionProp.onLogin()
            userSession()
            axios
              .post(base_url + "login_tracking", {
                user_id: res.data.result._id,
                is_login: 1,
              })
              .then((res) => {
                localStorage.setItem('login_tracking', true)
                if (localStorage.getItem('free_lesson_url')) {
                  let url = localStorage.getItem('prev_url')
                  history.push(url.split(window.location.host)[1])
                }
                else {
                  history.push('/maindashboard')
                  window.location.reload();
                }

              })
              .catch((err) => {
                console.log(err);
                if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
                  history.push('/')
                } else {
                  toast.warn(api_error_message, { toastId: random_number });
                }
              });
          } else {
            setErrorFlag(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  };

  const userSession = () => {
    setInterval(() => {
      handleUserSessions()
    }, 50 * 60 * 1000);
  }
  const handleUserSessions = () => {
    axios.post(base_url + "isb/user/refreshtoken", {
      "auth_refresh_token": localStorage.getItem('User_Refreshtoken')
    })
      .then(res => {
        localStorage.setItem('User_Accesstoken', res.data?.access_token)
      })
      .catch(err => {
        if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
  }

  useEffect(() => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      const {
        leadform_name,
        leadform_email,
        leadform_mobile,
        leadform_ProgramId,
        leadform_role,
        leadform_country_code,
        leadform_id,
        leadform_years_of_experience,
        leadform_location,
      } = cookies;
    if(leadform_email)
     setValue('Email',leadform_email,{ shouldDirty: true });
  
    if (localStorage.getItem("User_Accesstoken") && localStorage.getItem('isLoggedIn') == 'true') {
      history.push("/maindashboard");
    }
    else if (localStorage.getItem("Admin_Acesstoken")) {
      if (localStorage.getItem('Admin_role') == 1) {
        history.push("/AdminDashboard");
      }
      if (localStorage.getItem('Admin_role') == 2) {
        history.push("/programmanagementdashboard");
      }
    }
  }, []);

  const navToPasswordReset = () => {
    history.push('/passwordreset')
    // window.location.reload()
  }

  return (
    <>
      <div>
        {
          <div style={{ width: "99%" }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-4">
                <div className="isblogin-wrap p-4 p-md-5">
                  <div className="my_form_heading mb-2">
                    <span data-testid="Login_heading" className="loginPageHeading">Login to ISB Online</span>
                  </div>
                  {errorFlag && (
                    <div>
                      <span data-testid="Invalid_email_msg" className="text-danger ">
                        Invalid Email or Password
                      </span>
                    </div>
                  )}
                  {banned && (<div><span data-testid="banned_user_msg" className="text-danger">Your access is blocked due to non-payment. Please reach out to online_support@isb.edu for clearing your payment.</span></div>)}
                  <form
                    onSubmit={handleSubmit(onSubmitLogin)}
                    autoComplete="new-password"
                    className="login-form mt-2"
                  >
                    <div className="form-group mt-3 ">
                      <label htmlFor="Email">Email </label>
                      <input
                        {...register("Email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        className="form-control rounded-left border-radius-none"
                        placeholder="Email"
                        type="email"
                        data-testid="Email"
                        id="Email"
                        name="Email"
                        autoFocus
                        role="presentation"
                        autoComplete="off"
                      />
                      {errors.Email?.message && (
                        <span role="alert" style={{ color: "red" }}>
                          {errors.Email?.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="Password">Password </label>
                      <input
                        {...register("Password", {
                          required: "Password is required",
                        })}
                        type={passwordType}
                        id="Password"
                        data-testid="Password"
                        name="Password"
                        className="form-control rounded-left border-radius-none"
                        placeholder="Password"
                      />
                      {passwordType === "password" ?


                        <span className={`password-icon  ${errors.Password?.message ? 'eyeIconTop' : ''}`} role="button" data-testid="eye_button_hide_toggle" onClick={togglePassword}><i data-testid="eye_button_hide" className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i> </span> : <span className={`password-icon  ${errors.Password?.message ? 'eyeIconTop' : ''}`} role="button" data-testid="eye_button_show_toggle" onClick={togglePassword}><i data-testid="eye_button_show" className="fa-regular fa-eye mr-2 mt-2 ml-1" /></span>}
                      {errors.Password?.message && (
                        <span role="alert" style={{ color: "red" }}>
                          {errors.Password?.message}
                        </span>
                      )}
                    </div>
                    <div className="my_form_heading mt-1">
                      <button data-testid="submit-button" className="btn my_btn_cls my_btn_login my_submit_btn">
                        Login
                      </button>
                    </div>
                    <br />
                    <div className="justify-content-center d-flex mt-3">
                      <span data-testid="forgotPassword_textLink" className="forgotpassword">
                        Forgot Password ?{" "}
                        <span className="text-underline cursor-pointer" data-testid="password_reset" onClick={navToPasswordReset} >
                          Send Reset Email
                        </span>
                      </span>
                    </div>
                  </form>
                  {/* for test cases */}
                  <span className="d-none" data-testid="sessionCaller" onClick={handleUserSessions}></span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default withMaintenanceCheck(IsbLogin, user_maintenance_type)
