import React, { useState } from "react";
import gaussian from "gaussian";
import { VictoryArea, VictoryChart, VictoryGroup, VictoryAxis, VictoryScatter, VictoryTooltip, VictoryLabel } from "victory";
import range from "lodash/range";

export default function App({ curveArray, learnerPercentile, coursetotalpoints, score, apiData }) {
 const scores = curveArray;
  //   const scores = [
  //     2,
  //     12,
  //     12,
  //     12,
  //     12,
  //     11,
  //     0,
  //     10,
  //     0,
  //     6,
  //     5,
  //     5,
  //     0,
  //     8,
  //     8,
  //     8,
  //     0,
  //     9,
  //     0,
  //     0,
  //     0,
  //     0,
  //     0,
  //     0,
  //     1,
  //     5,
  //     3,
  //     2,
  //     1,
  //     0,
  //     0,
  //     0
  // ]
  // let [learnerscore, setLearnerScore] = useState(score);
  // Normalize scores to percentages
  const normalizedScores = scores.map(score => (score / coursetotalpoints) * 100);

  // Calculate mean and standard deviation
  function calculateMean(scores) {
    return scores.reduce((a, b) => a + b) / scores.length;
  }

  const calculateStandardDeviation = (scores, mean) => {
    return Math.sqrt(
      scores.map(score => Math.pow(score - mean, 2)).reduce((a, b) => a + b) / scores.length
    );
  };
  const mean = calculateMean(scores);
  //  Fixing the mean and standardDeviation as 50 and 20
  const mn = 50
  const standardDeviation = 20
  const stddev = calculateStandardDeviation(scores, mean)

  const negativeThirdStddev = Math.round(mn - 3 * standardDeviation)
  const negativeSecondStddev = Math.round(mn - 2 * standardDeviation)
  const negativeFirstStddev = Math.round(mn - 1 * standardDeviation)
  const firstDeviation = Math.round(mn + standardDeviation)
  const secondDeviation = Math.round(mn + 2 * standardDeviation)
  const thirdDeviation = Math.round(mn + 3 * standardDeviation)
  let negativeSecondFilteredScores = apiData["0-10"]
  let negativeFirstFilteredScores = apiData["10-20"] + apiData["20-30"]
  let firstfilteredScores = apiData["30-40"] + apiData["40-50"] + apiData["50-60"] + apiData["60-70"]
  let secondfilteredScores = apiData["70-80"] + apiData["80-90"]
  let thirdfilteredScores = apiData["90-100"]
  let negativeSecondPercentage = (negativeSecondFilteredScores / apiData?.total_learners * 100).toFixed(1)
  let negativeFirstPercentage = (negativeFirstFilteredScores / apiData?.total_learners * 100).toFixed(1)
  let firstPercentage = (firstfilteredScores / apiData?.total_learners * 100).toFixed(1)
  let secondPercentage = (secondfilteredScores / apiData?.total_learners * 100).toFixed(1)
  let thirdPercentage = (thirdfilteredScores / apiData?.total_learners * 100).toFixed(1)

  // Gaussian distribution based on mean and standard deviation
  const normal = gaussian(mn, Math.pow(standardDeviation, 2));

  // Generate data points for the curve
  const dataPoints = range(mn - 3 * standardDeviation, mn + 3 * standardDeviation + 1).map((x) => ({
    x,
    y: normal.pdf(x),
  }));

  // Calculate dynamic X-axis range
  const minX = Math.min(...scores);
  const maxX = Math.max(...scores);

  // Calculate mean value for Y-axis positioning
  const meanY = (Math.max(...normalizedScores) - Math.min(...normalizedScores)) / 2;


  return (
    <div className="parent-graph">
      <div className="graph-container ">
        <VictoryChart
          height={400}
          width={700}
          domain={{
            x: [negativeThirdStddev, thirdDeviation],
            y: [0, gaussian(mean, Math.pow(standardDeviation, 2)).pdf(mean) + 0.001],
          }}
          scale={{ x: "linear", y: "linear" }}
        >
          <VictoryGroup style={{
            data: { strokeWidth: 6 }
          }}>
            <VictoryArea
              style={{ data: { fill: "#A9BFD7" }, labels: { fill: "white", fontSize: 12, fontWeight: "bold" } }}
              data={dataPoints.filter(
                (point) => point.x >= mn - 3 * standardDeviation && point.x <= mn - 2 * standardDeviation
              )}
              labels={({ data, index }) => index == Math.round(data.length * 3 / 4) ? negativeSecondPercentage + "%" : null}
              labelComponent={<VictoryLabel dy={18} />}
            />

            <VictoryArea
              style={{ data: { fill: "#89A7C9" }, labels: { fill: "white", fontSize: 16, fontWeight: "bold" } }}
              data={dataPoints.filter(
                (point) => point.x >= mn - 2 * standardDeviation && point.x <= mn - 1 * standardDeviation
              )}
              labels={({ data, index }) => index == Math.round(data.length / 2) ? negativeFirstPercentage + "%" : null}
              labelComponent={<VictoryLabel dy={40} />}
            />

            <VictoryArea
              style={{ data: { fill: "#8296C2" }, labels: { fill: "white", fontSize: 16, fontWeight: "bold" } }}
              data={dataPoints.filter(
                (point) => point.x >= mn - 1 * standardDeviation && point.x <= mn + 1 * standardDeviation
              )}
              labels={({ data, index }) => index == Math.round((data.length + 4) / 2) ? firstPercentage + "%" : null}

              labelComponent={<VictoryLabel dy={60} />}
            />

            <VictoryArea
              style={{ data: { fill: "#7088A2" }, labels: { fill: "white", fontSize: 16, fontWeight: "bold" } }}
              data={dataPoints.filter(
                (point) => point.x >= mn + 1 * standardDeviation && point.x <= mn + 2 * standardDeviation
              )}
              labels={({ data, index }) => index == Math.round(data.length / 2) ? secondPercentage + "%" : null}
              labelComponent={<VictoryLabel dy={40} />}
            />

            <VictoryArea
              style={{ data: { fill: "#057092" }, labels: { fill: "white", fontSize: 12, fontWeight: "bold" } }}
              data={dataPoints.filter(
                (point) => point.x >= mn + 2 * standardDeviation && point.x <= mn + 3 * standardDeviation
              )}
              labels={({ data, index }) => index == Math.round(data.length / 4) ? thirdPercentage + "%" : null}
              labelComponent={<VictoryLabel dy={15} />}
            />

            {/* Bell Curve */}
            <VictoryArea
              style={{
                data: { fill: "transparent", stroke: "#000", strokeWidth: 2 },
              }}
              data={dataPoints}
              y={(data) => normal.pdf(data.x)}
            />
          </VictoryGroup>

          <VictoryAxis
            style={{
              axis: { stroke: "#756f6a" },
              ticks: { stroke: "grey", size: 5 },
              tickLabels: { fontSize: 20, padding: 10 }
            }}
            tickFormat={x => `${x}%`}
            tickValues={[0, 10, 30, 50, 70, 90, 100]}
          // domain={[negativeThirdStddev, thirdDeviation]}
          />
          <VictoryLabel
            dy={20}
            text="Course Average"
            x={350}
            y={normal.pdf(mean)}

            textAnchor="middle"
            style={{ fontSize: 25, fontWeight: "bold", fill: "#D3A01F", paddingBottom: "20px" }}
          />
          <VictoryScatter
            data={[{
              x: parseInt(learnerPercentile),
              y: normal.pdf(parseInt(learnerPercentile)),
            }]}
            size={8} // Set a larger size for the outer circle
            style={{
              data: {
                fill: "white", // White inner circle
                stroke: "#FFCC00", // Yellow outer ring
                strokeWidth: 4 // Thickness of the yellow ring
              }
            }}
            labels={["You are here"]} // Tooltip text to show "You are here"
            labelComponent={<VictoryTooltip
              flyoutStyle={{ fill: "#057092" }} // Tooltip background color
              style={{ fontSize: 20, fill: "white" }} // Font styling for the tooltip
            />}
          />
        </VictoryChart>
      </div>
      <div>

      </div>
    </div>
  );
}
