import '../../homePageStyle.css'
import useCollapse from 'react-collapsed';
import { Container,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { useHistory, useLocation,useParams } from "react-router-dom";
import { useEffect, useState,React,Fragment, useRef } from "react";
import {ModuleZero} from "./ModuleZero";
import axios from "../../axios-interceptor";
import moment from "moment"
import {random_number, base_url,date_formats,downloadPdf,api_error_message, user_maintenance_type, badgeMessage, moduleBadgeIssuing} from '../../utilities';
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import badgeImgData from '../../BadgeImage'
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';
import ProgressBar from 'react-bootstrap/ProgressBar';
const ModulePage = () => {
  let [past_courses,setPastCourse] = useState([])
  let [course_complete,setCourseComplete] =useState(false)
  let [certificate_issued,setcertificate_issued] =useState(false)
  let [courseTakeAway,setCourseTakeAway] =useState("")
  const [learnerData, setLearnerData] = useState(null);
  const [pointsData,setPointsData] = useState(null)
  const history = useHistory();


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  // console.log(JSON.parse(localStorage.getItem('audit_user')))
    const location = useLocation();
    const params  = useParams();
    var moduleData;
    var [course_id,setCourseId]=useState('')
    var redirect_course_id
    let [timer_flag, setTimeFlag] = useState(true);
  const [course_title,setCourseTitle] = useState('');
  const [modules, setModules] = useState([]);
  const [content, setContent] = useState([]);
  let [dynamic_badge, SetDynamicBadge] = useState({});
 
   useEffect(()=>{
    if(!localStorage.getItem("user_id") || !localStorage.getItem('User_Accesstoken') || !localStorage.getItem('isLoggedIn')){
      history.push('/');
    }
    else{
      localStorage.removeItem('artcle_LinkClicked')
      localStorage.removeItem('confirmation_code')
      if(params?.id !== undefined){
        axios
        .post(base_url + "courses/get_all_courses",)
        .then((res) => {
        res.data.courses.map((item,index)=>{
         if(params.id == index+1){
          redirect_course_id = item.id
          
         }
        })
        setCourseId(redirect_course_id)
        localStorage.setItem('redirect_course_id',redirect_course_id)
        axios.post(base_url+"courses/get_course_details", {
          "course_id":redirect_course_id
        })
        
        .then((res)=>{
          setCourseTitle(res.data.result.course.title)
          setTimeFlag(false);  
         
         
        }).catch((err)=>{
          console.log(err);
          if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
          
        })
        })
        .catch((err) => {
          console.log(err);
          if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        });
      }else{
        if(localStorage.getItem('complete_lesson')){
          axios
          .post(base_url + "lesson/progress_tracking", {
            user_id: localStorage.getItem("user_id"),
            lesson_id: localStorage.getItem("lessonId"),
            course_id: localStorage.getItem("course_id"),
            module_id: localStorage.getItem("moduleId"),
            cohort_schedule_id: localStorage.getItem("cohort_id"),
          })
          .then((res) => {      
          localStorage.setItem("lesson_tracking", res.data);
          if(res.data.is_module_completed){
            axios
            .post(base_url + "module/progress_tracking", {
              user_id: localStorage.getItem("user_id"),
  
              course_id: localStorage.getItem("course_id"),
              module_id: localStorage.getItem("moduleId"),
              cohort_schedule_id: localStorage.getItem("cohort_id"),
            })
            .then((res) => {
              get_cohort_details();
            })
            .catch((err) => {
              console.log(err);
              if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
            });
            }else{
              get_cohort_details();
            }
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
        }else{
          get_cohort_details();
        }
       
      }
    }
   },[])
   const ShowDynamicBadge =  (id) => { 
    SetDynamicBadge({})
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: true }));
  }

  const HideDynamicBadge =  (id) => {
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: false }));
  }

  const goToScorePage = () =>{
    history.push('/scorecard')
  }

  const CourseCompletionConfirmation = async () => {
    try {
     const res1 = await axios.post(base_url + "notifications/course_completion_confirmation", {
    user_id: localStorage.getItem("user_id"),
    cohort_schedule_id: localStorage.getItem("cohort_id"),
  });
  if(res1){
    PastCourses()
  }
  else{
    PastCourses()
  }
}catch (error) {
  PastCourses()
}
  }
  const PastCourses = ()=>{
    axios.post(base_url+'courses/get_past_courses',{
      user_id: localStorage.getItem("user_id"),
      cohort_schedule_id: localStorage.getItem("cohort_id")
  }).then(res=>{
    if(res.data.data.length >0){
      setCourseComplete(true)
      setPointsData(res.data?.data[0])
      setCourseTakeAway(res.data?.data[0].course_takeaway)
      if(res.data?.data[0].course_is_completed ==1){
        setcertificate_issued(true)
      }
      else{
        setcertificate_issued(false)
      }
    }
     setPastCourse(res.data.data);

     setTimeFlag(false);
    
  }).catch(err=>{
      console.log(err);
      setTimeFlag(false);
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
      
  })
  }
  async function get_cohort_details() {
    try {
      setTimeFlag(true);
      const res = await axios.post(base_url + "courses/get_course_schedules_details", {
        user_id: localStorage.getItem("user_id"),
        course_id: localStorage.getItem('course_id'),
        cohort_schedule_id: localStorage.getItem("cohort_id"),
      });
  
      setCourseId(localStorage.getItem('course_id'));
      let modules = res.data.result.course.modules[0];
      localStorage.setItem("programe_id",res.data.result.course.program_id)
      let completed = [];
      modules.map(item => {
        if (item.module_completed_percentage === "100.00") {
          completed.push(item.module_completed_percentage);
        }
      });
      try{
        await CourseCompletionConfirmation();
      }catch(err){
        CourseCompletionConfirmation();
        if (err.response?.statusText == 'Unauthorized'||err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/');
          window.location.reload();
        }
      } 
      if (JSON.parse(localStorage.getItem('audit_user')) == true) {
        let res_length = res.data.result.course.modules[0].length;
        let modules = res.data.result.course.modules[0];
        let id = modules[res_length - 1].lessons[0][0].module_id;
        modules[res_length - 1].lessons[0].push({
          title: "Feedback",
          module_id: id,
          completed_percentage: "0.00",
          is_lesson_completed: false,
          total_score: 0,
          earned_score: 0,
          lesson_id: 1
        });
        setModules(modules);
      } else {
        setModules(res.data.result.course.modules[0]);
      }
      setCourseTitle(res.data.result.course.title);
      localStorage.removeItem('complete_lesson');
    } catch (err) {
      if (err.response?.statusText == 'Unauthorized'||err.response?.data?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/');
        window.location.reload();
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    }
  }
  const shareCourseCertificate=()=>{
    axios.post(base_url+'get_course_certificate_shareable_meta_data',{
      user_id: localStorage.getItem("user_id"),
      course_id: localStorage.getItem('course_id'),
      cohort_schedule_id: localStorage.getItem("cohort_id")
    })
    .then(res=>{
      const certificate_id=res.data?.badge_url
      if(certificate_id)
      {
        window.location.assign(`/backend/credentials/${certificate_id}`)
      }
      else{
        toast.warn("Failed to share", { toastId: random_number });
      }
    })
    .catch(err=>{
      console.log(err);
    })
     }


     //badge sharing 
const canvasRef = useRef(null);
const imgRef = useRef(null);

useEffect(() => {
  imgRef.current = new Image();
  imgRef.current.src = badgeImgData
  imgRef.current.onload = () => {
  //  const noValue=""
  // generatePreview("Decision Making","CRITICAL THINKING");
  };
}, []);

const generatePreview = (ModuleNameInput,CourseNameInput) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext("2d");

  const textInput = ModuleNameInput
  const courseText=CourseNameInput

  canvas.width = imgRef.current.width;
  canvas.height = imgRef.current.height;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(imgRef.current, 0, 0);

  context.font = "36px Arial";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.textBaseline = "middle";

  const maxWidth = 350; // Maximum width of the text
  const lineHeight = 42; // Line height for the text
  const x = canvas.width / 2; // Horizontal center position
  let y = ModuleNameInput.length >= 33?215:canvas.height / 2 - lineHeight; // Vertical center position

  // Split text into lines based on the maximum width
  const words = textInput.split(' ');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = context.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
        lines.push(currentLine);
        currentLine = word;
    }
  }
    lines.push(currentLine);
  // Draw each line of text
  for (let j = 0; j < lines.length; j++) {
    context.fillText(lines[j], x, y + j * lineHeight);
  }

  if(ModuleNameInput!=""){
    generateBadge()
  }
  };

const generateBadge = () => {
  const canvas = canvasRef.current;
  canvas.toBlob((blob) => {
    if (blob) {
      const fd = new FormData();
      let user_id= localStorage.getItem("user_id")
      let course_id= selectedModuleInfo.course_id
      let module_id= selectedModuleInfo.module_id
      let cohort_schedule_id= selectedModuleInfo.cohort_schedule_id
       if(user_id&&course_id&&module_id&&cohort_schedule_id)
       {
         fd.append("user_id",user_id)
         fd.append("course_id",course_id)
         fd.append("module_id",module_id)
         fd.append("cohort_schedule_id",cohort_schedule_id)
         fd.append("file",blob,`${selectedModuleInfo.module_name}-${selectedModuleInfo.course_name}.jpg`)  
         axios.post(base_url+'badges/generate_badge_url',fd)
         .then(res=>{
           const certificate_id=res.data?.badge_url
           if(certificate_id)
           {
            window.location.assign(`/backend/credentials/${certificate_id}`)
           }
           else{
           //  setLoadingFlag(false)
             toast.warn("Failed to share", { toastId: random_number });
           }
         })
         .catch(err=>{
         //  setLoadingFlag(false)
           console.log(err);
         })
       }
       else{
        // setLoadingFlag(false)
         toast.warning("Something went wrong")
       }
    }
  }, "image/jpeg");
};

let [selectedModuleName,setSelectedModuleName]=useState('')
let [selectedCourseName,setSelectedCourseName]=useState('')
let [selectedModuleInfo,setSelectedModuleInfo]=useState({})
const shareHandler=(module,item)=>{
   let stateObject={
     cohort_schedule_id:item.cohort_schedule_id,
     course_id:item.course_id,
     course_name:item.course_title,
     module_id:module._id,
     module_name:module.module_title
   }
      setSelectedModuleInfo(stateObject)
      setSelectedModuleName(module.module_title)
      setSelectedCourseName(item.course_title)
   }

useEffect(() => {
 let modulename=selectedModuleName.toUpperCase()
 let courseName=selectedCourseName.toUpperCase()
 if(modulename&&courseName)
 {
   generatePreview(modulename,courseName)
 }
}, [selectedModuleName,selectedCourseName])


useEffect(() => {
  axios.post(base_url+'user/get_learner_course_outline', {
    user_id: localStorage.getItem("user_id"),
    program_id:localStorage.getItem("program_id"),
    cohort_schedule_id: localStorage.getItem("cohort_id"),
    course_id: localStorage.getItem("course_id"),
  })
  .then(response => {
    if(response.data.status === 200) {
      setLearnerData(response.data.result);
    }
  })
  .catch(err=>{
    console.log(err);
    if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
      localStorage.clear();
      history.push('/')
      window.location.reload();
    }else{
      toast.warn(api_error_message,{toastId:random_number});
    }
  })
}, []);

const actualPercentage = (pointsData?.total_earned_score / pointsData?.total_course_score) * 100;
const requiredPointsForCertificate = (pointsData?.certificate_pass_percentage * pointsData?.total_course_score) /100
const requiredPointsForLearner = (requiredPointsForCertificate - (pointsData?.total_earned_score))
const actualPoints =(pointsData?.total_course_score - pointsData?.badge_bonus_points)

const handleBackClick = () => {
  history.push('/programmedashboard'); 
};

    return (
      <>

        {/* badge sharing */}
        <canvas className="hidden_badge_sharing" ref={canvasRef}></canvas>
        <div className="hidden_badge_sharing" id="previewContainer"></div>

        {timer_flag ? (
            <div>
              <img data-testid="atrium_loader" className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
            </div>
          ) :

            <>

        <Container className='bg-transparent outlinemobile mt-5 pb-0'>
          <p className='heading_effective_negotiation' data-testid="course_name">
          <img 
          src="/images/back_arrow_course.svg" 
          alt="Back to Dashboard" 
          onClick={handleBackClick} 
          style={{ cursor: 'pointer' }} 
        />
            {learnerData?.course_name}</p>
        </Container>


          <div className='d-none' data-testid="dummyfunctionCall" onClick={generateBadge}></div>
        
              {
                // window.innerWidth>993?
                (course_complete) &&
                past_courses.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {past_courses[0]?.show_overview && <div className="p-4 bg-body container shadow-sm mt-3" key={index}>

                        <div className="row mt-2">

                          <div className="d-flex justify-content-between w-100">
                            <p className='text_keepgoing'>Keep going! You can catch up with your cohort soon!</p>
                            <div className="align-self-end">

                              {past_courses[0]?.show_overview && <div className="align-self-end">
                                <p data-testid="Progress_card" className="text_keepgoing score_text" onClick={goToScorePage}>Score Card</p>
                              </div>}
                            </div>
                          </div>

                          <div className="mt-4">

                            <ProgressBar id="originProgress" style={{ backgroundColor: '#e0e0e0 !important', position: 'relative', height: '30px !important' }}>
                              {/* Scheduled Progress */}
                              <ProgressBar
                                className="custom-scheduled-progress"
                                now={learnerData?.scheduled_progress_percentage}
                                key={1}
                              />
                              {/* Actual Progress */}
                              <ProgressBar
                                className="custom-actual-progress"
                                now={learnerData?.learner_progress_percentage}
                                key={2}
                              />
                            </ProgressBar>

                            <div className="progress_details">
                              <p className="progress_percentage_text mr-5"><span className="progress_percentage">{learnerData?.learner_progress_percentage}%</span> Your Progress</p>
                              <p className="scheduled_progress_text"><span className="scheduled_progress">{learnerData?.scheduled_progress_percentage}%</span> Scheduled Progress</p>
                            </div>

                          </div>

                          <div className="row mt-4">
                            <div
                              className={`col-1 col-sm-1 score_box d-flex flex-row justify-content-center align-items-center ${pointsData?.course_score_percentage >= 65 ? 'score-box-green' : ''
                                }`}
                            >
                              <p className="pt-3">{Math.round(actualPercentage)}%</p>
                            </div>
                            <div className="col-10 col-sm-10">
                              <p className="score_card_text_bold">
                                You have {pointsData?.total_earned_score}/{pointsData?.total_course_score} Points ({Math.round(actualPercentage)}%)
                              </p>
                              {pointsData?.course_score_percentage >= 65 ? (
                                <>
                                  <p className="score_card_text">You have achieved the certificate</p>
                                  <p className="score_card_text">
                                    You can achieve {actualPoints} Points + {pointsData?.badge_bonus_points} Bonus Points (for a total of {pointsData?.total_course_score} points) for completing the modules on time.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="score_card_text">
                                    You need {Math.round(requiredPointsForCertificate)} Points ({pointsData?.certificate_pass_percentage}%) to be awarded a certificate
                                  </p>
                                  <p className="score_card_text">
                                    You can achieve {actualPoints} Points + {pointsData?.badge_bonus_points} Bonus Points (for a total of {pointsData?.total_course_score} points) for completing the modules on time.
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-lg-6">
                            <div className="row">
                              <p className="blue_text badges-class">Badges</p>
                              <div className="d-flex flex-wrap gap-1">
                                {item.modules.map((module, moduleINdex) => {
                                  const isTransparentBadge = module.module_badge === 0;
                                  return (
                                    <Fragment key={moduleINdex}>
                                      <div className="d-flex flex-column align-items-center">
                                        {isTransparentBadge ? (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip className="module_tooltip" id={"registerTip0" + module._id + module.start_date}>
                                                {moduleBadgeIssuing(
                                                  module.user_earned_module_score,
                                                  module.module_total_score,
                                                  module.module_score_percentage,
                                                  module.badge_percentage,
                                                  module.points_needed_for_badge,
                                                  module.end_date
                                                )}
                                              </Tooltip>
                                            }
                                            offset={[0, 20]}
                                          >
                                            <div
                                              onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id + module.start_date)}
                                              className="transparent_isb_badge_courseoutline"
                                              data-testid="transparent_badge_web"
                                              id={"registerTip0" + module._id + module.start_date}
                                              onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id + module.start_date)}
                                            >
                                              <div className="isb_badge_text_outline">
                                                <p className="text-center mb-2 white_color">
                                                  {module?.module_title?.length >= 25 ? module?.module_title.slice(0, 25) + '...' : module?.module_title}
                                                </p>
                                              </div>
                                            </div>
                                          </OverlayTrigger>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip className="module_tooltip" id={"registerTip2" + module._id + module.start_date}>
                                                <span>{badgeMessage}</span>
                                              </Tooltip>
                                            }
                                            offset={[0, 20]}
                                          >
                                            <div
                                              onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id + module.start_date)}
                                              data-testid="isb_badge_web"
                                              className="isb_badge_courseoutline"
                                              id={"registerTip2" + module._id + module.start_date}
                                              onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id + module.start_date)}
                                            >
                                              <div className="isb_badge_text_outline">
                                                <p className="text-center mb-2 white_color">
                                                  {module?.module_title?.length >= 25 ? module?.module_title.slice(0, 25) + '...' : module?.module_title}
                                                </p>
                                              </div>
                                            </div>
                                          </OverlayTrigger>
                                        )}

                                        {/* Dynamically styled Share Button */}
                                        <button
                                          className={`btn mt-0 my_share_btn_course_outline course_outline_share_button ${isTransparentBadge ? 'transparent_share_btn' : 'course_outline_share_button mt-0'
                                            }`}
                                          onClick={() => shareHandler(module, item)}
                                        >
                                          Share
                                        </button>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="row"></div>
                          </div>

                          <div className="col-lg-4">
                            {courseTakeAway && (courseTakeAway != 'null') ?
                              <button
                                className={`course_outline_download_takeaway courseoutline_download_takeway_text mb-3 mt-3 d-none d-md-block  ${date_formats.convert_current_date_utc_format() > item.cohort_end_date ? '' : 'disabled'}`}
                                onClick={() => { window.open(courseTakeAway, '_blank') }}
                                download
                              >

                                Download Takeaway
                              </button>
                              : ''}
                            <p
                              data-testid="certificate_heading_web"
                              className="blue_text certificate-class certificate_text_res mb-4 mt-4 certificate_text"
                            >
                              Certificate
                            </p>

                            {item.certificate_url ? (
                              <img
                                data-testid="certificate_thumbnail_web"
                                className={`course_complete_margin_forimage_new ${item.certificate_thumbnail_url ? "" : "opacity-25"}`}
                                src={item.certificate_thumbnail_url || "/images/course_certificate.svg"}
                                alt="Certificate Thumbnail"
                              />
                            ) : item.course_score_percentage >= item.certificate_pass_percentage ? (
                              <>
                                <div className="overlay-text-courseoutline-new mb-5">
                                  <img
                                    className="course_complete_margin_forimage_new"
                                    src="/images/course_certificate.svg"
                                    alt="Course Certificate"
                                    style={{ opacity: 0.2 }}
                                  />
                                  <p
                                    className="certificate_text_upon_image-new"
                                  >
                                    Congratulations! Just one step left for your certificate.
                                    <br />
                                    <br />
                                    Share feedback to access it.
                                  </p>
                                </div>
                                <div className=''>
                                  <a
                                    href="/feedback-form"  // Replace this with the actual feedback link
                                    className="complete-feedback-link mt-5 mb-3 d-block text-center justify-content-center align-items-center"
                                  >
                                    Complete Feedback
                                    <img src="/images/CompleteFeedbackarrow.svg" alt="Complete Feedback Icon" className="feedback-icon" />
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="module_certificate_class_new"
                                  data-testid="courseCompletetionInfo_web"
                                >
                                  <img
                                    className={`course_complete_margin_forimage_new ${0.2 === 0.2 ? '' : 'clear'}`}
                                    src="/images/course_certificate.svg"
                                    alt="Course Certificate"
                                    style={{ opacity: 0.2 }}
                                  />
                                  <p data-testid="certificateNotissued_web pt-3" className='certificate_text_upon_image'>
                                    This course will end on {date_formats.convert_current_date_utc_format() < item.cohort_end_date
                                      ? date_formats.human_date_format(item.cohort_end_date)
                                      : date_formats.human_date_format(item.cohort_expiry_date)}.
                                    Your certificate will be issued after achieving {Math.round(item.certificate_pass_percentage) ? Math.round(item.certificate_pass_percentage)
                                      : <img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" />}%
                                    by {date_formats.convert_current_date_utc_format() < item.cohort_end_date
                                      ? date_formats.human_date_format(item.cohort_end_date)
                                      : date_formats.human_date_format(item.cohort_expiry_date)}.
                                  </p>
                                  <br />
                                  <br />
                                  <p className='certificate_text_upon_image mt-0'>You only need to complete {Math.round(item.score_percentage_required_for_certificate)}% more to be awarded your certificate.</p>
                                </div>
                                <div className='d-flex flex-row justify-content-center align-items-center' style={{ marginRight: "58px" }}>
                                </div>
                              </>
                            )}

                            {item.certificate_url && (
                              <div className="d-flex gap-3 mt-3 download_share_button" >
                                <button
                                  data-testid="certificate_DownloadBtn_web"
                                  className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4 "
                                  onClick={() => downloadPdf(item.certificate_url)}
                                  download
                                >
                                  Download
                                </button>
                                <button
                                  data-testid="certificate_ShareBtn_web_badge"
                                  className="btn my_btn_cls mt-0 my_share_btn"
                                  onClick={shareCourseCertificate}
                                >
                                  Share
                                </button>
                              </div>
                            )}

                            {courseTakeAway && (courseTakeAway != 'null') ?
                              <button
                                className={`course_outline_download_takeaway courseoutline_download_takeway_text mt-5 mb-3 mr-2 d-block d-md-none ${date_formats.convert_current_date_utc_format() > item.cohort_end_date ? '' : 'disabled'}`}
                                data-testid="download_takeaway_button"
                                onClick={() => { window.open(courseTakeAway, '_blank') }}
                                download
                              >

                                Download Takeaway
                              </button>
                              : ''}
                          </div>
                        </div>

                      </div>}
                    </Fragment>
                  )
                })
              }
               <Container className='bg-transparent outlinemobile'>

              <div >
                <div>
                  {modules.map((item, index) => (
                    <Fragment key={item.module_id}>
                      <div >
                        <ModuleZero data={item} id={item.module_id} course={course_id} index={index} cohort_id={localStorage.getItem("cohort_id")} new_module_id={modules[0]['module_id']} modules={modules} new_module_end_date={modules[0]['end_date']} />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
              </Container>
            </>
          }
      </> 
    );
};

export default withMaintenanceCheck(ModulePage,user_maintenance_type)
